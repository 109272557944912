<div class="application-bar-menu">
  <!--<span class="application-bar-logo" >
    Oncoscape
  </span>-->
  <span (click)="showBuildVersion()" class="application-bar-version"
    style="letter-spacing: 1px;color: #EEE;">
    Oncoscape (v3x)</span>
  <span class="application-bar-buttons">
    <span class="btn-underline application-bar-button"  (click)="takeTour()"  id="takeTourBtn">
      Take a Tour
    </span>
    <span class="btn-underline application-bar-button" >
      <button mat-icon-button  (click)='undoFromMenu()'>
        <mat-icon>undo</mat-icon>
      </button>
    </span>
    <span class="btn-underline application-bar-button" >
      <button mat-icon-button  (click)='redoFromMenu()'>
        <mat-icon>redo</mat-icon>
      </button>
    </span>
    <span class="btn-underline application-bar-button" *ngIf="datasetSelected" (click)="onSplitScreenChange()">
      <span class="copy">Split Screen</span>
    </span>
    <span class="btn-underline application-bar-button" *ngIf="datasetSelected" (click)="viewPanel(getPanelEnumVal('SETTINGS'))">
      Settings
    </span>
    <!-- <span class="btn-underline application-bar-button" (click)="viewPanel(getPanelEnumVal('UPLOAD'))"> Sign In </span> -->
    <span class="btn-underline application-bar-button" (click)="viewPanel(getPanelEnumVal('CITATION'))"> Citations </span>
    <span class="btn-underline application-bar-button" (click)="viewPanel(getPanelEnumVal('ABOUT'))"> Credits </span> &nbsp;

    <span class="btn-underline application-bar-button" (click)="viewPanel(getPanelEnumVal('DATA'))"> Data </span> &nbsp;
    <span class="btn-underline application-bar-button" >
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <!--<button *ngIf='datasetSelected' mat-menu-item (click)='reload()'>Data Sets</button>-->
      <button mat-menu-item (click)='initGui()'>3D GUI Controls</button>
      <button mat-menu-item (click)='viewPanel(getPanelEnumVal("DATA"))'>Pick Data Set</button>
      <button mat-menu-item (click)='viewPanel(getPanelEnumVal("ABOUT"))'>The Team</button>
      <button mat-menu-item (click)='viewPanel(getPanelEnumVal("CITATIONS"))'>Citations</button>
      <a mat-menu-item href='mailto:contact@oncoscape.org'>Feedback</a>
<!--
      // These items are specific to charts. Should either test that we are in a chart,
      // or move them to a separate menu.

      <button mat-menu-item (click)='toggleBackgroundColor()'>Invert Color</button>
-->
      <button mat-menu-item (click)='exportImageAsJpg()'>Save As JPG</button>
      <button mat-menu-item (click)='exportImageAsSvg()'>EXPERIMENTAL: Save As SVG</button>
      <button mat-menu-item (click)='deleteAllCaches()'>Reset Oncoscape</button>
    </mat-menu>
  </span>

</span>
</div>

<div id="svgForExport" style="display: none; " >
  <!-- svg rendering element gets inserted here -->
</div>
