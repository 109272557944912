<div class="panel">
  <div>
    <h1 class="panel-header">Data Sets</h1>
    <!--
      <input
        matInput
        type="text"
        placeholder="Filter Data Sets"
        [(ngModel)]="filter"
        style="float: right;
      line-height: 40px;
      padding: 0px 10px;
      margin-left: 50px;
      margin-right: 50px;
      border-radius: 20px;
      border: solid 1px #DDD;
      transform: translateY(7px);
      width: 200px;
      text-align: center;"
      />
    -->
  </div>
  <mat-tab-group animationDuration="0ms"  class="filePanel" (selectedTabChange)="onSelectedTabChange($event)">
    <mat-tab label="Genomic Data Commons">
      The following datasets were downloaded from the Genomic Data Commons (GDC). (verified by the Oncoscape team)
    </mat-tab>
    <!-- OLDER
      <mat-tab label="GDC">
        The following datasets were downloaded from the Genomic Data Commons (GDC).
        <a href="http://www.cbioportal.org/" target="_blank">GDC</a>
      </mat-tab>
    -->


    <!-- Hide for now?  MJ 20191021. These were hidden when I started. -->
    <!--
    <mat-tab label="Memorial Sloan Kettering">
      The following datasets were downloaded from the Memorial Sloan Kettering CBio portal and are undergoing testing.
      <a href="http://www.cbioportal.org/" target="_blank">CBio Portal</a>
    </mat-tab>

    <mat-tab label="Children's Hospital of Philadelphia">
      The following datasets were downloaded from the Children's Hospital of Philidelphia (CHOP).
      <a href="https://pedcbioportal.org/login.jsp" target="_blank">Chop Portal</a>
    </mat-tab>
  -->

    <!-- EVEN OLDER
      <mat-tab label="GLASS">
        The following dataset were downloaded from the Children's Hospital of Philidelphia (CHOP).
        <a href="https://pedcbioportal.org/login.jsp" target="_blank">Chop Portal</a>
      </mat-tab>
    -->

    <mat-tab label="Private Datasets">
      <app-workspace-user-panel (showPanel)="this.showPanel.emit($event)" (loadPrivate)="this.loadPrivate.emit($event)">
      </app-workspace-user-panel>
    </mat-tab>
  </mat-tab-group>

  <div fxLayout.xs="column">
    <!-- <mat-form-field> -->
    <!--
      <div style='text-align: center'>
        <input matInput type="text" placeholder="Search Data Sets" [(ngModel)]="filter" style='border:none;border-radius: 40px; line-height: 40px; width:30%; text-align: center; font-size: 20px; background: #EEE;margin-bottom: 50px;'>
      </div>
    -->
    <!--
      <button mat-button *ngIf="filter" matSuffix mat-icon-button aria-label="Filter Datasets"
          (click)="filter=''">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    -->
    <!--
      <div fxFlex.sm='1 1 40%' fxFlex.md='1 1 30%' fxFlex.lg='1 1 25%' fxFlex.xl='1 1 15%'>
        <h2 class='panel-column-title'>My Datasets</h2>
        <app-workspace-user-panel (showPanel)='this.showPanel.emit($event)' (loadPrivate)='this.loadPrivate.emit($event)'></app-workspace-user-panel>
      </div>
      <div fxFlex.sm='1 1 60%' fxFlex.md='1 1 70%' fxFlex.lg='1 1 75%' fxFlex.xl='1 1 85%'>
    -->
    <div>
      <!-- <h2 class='panel-column-title '>Data Sets</h2> -->
      <div>
        <div class="panel-button-link" *ngFor="let dataset of datasets; let i = index">
          <span class="panel-button-image">
            <span (click)="this.loadPublic.emit(dataset)" class="clickable-area">
              <div class="disease-img disease-img{{dataset.img}}"></div>
              <span id="{{ 'dataset_link_'+dataset.name }}">{{ dataset.name == "Glioma" ? "Glioma (MDS w/ SNV + CNV)." : dataset.name}}</span>
            </span>
            <!-- <mat-icon *ngIf='dataset.isTested'>done</mat-icon> -->
          </span>
        </div>
      </div>
    </div>
  </div>
<br><br>
  <div>
    <a  (click)="this.loadTinySet()"><span style="color:white" id="TinySet">.      .</span>
      <!-- <mat-icon *ngIf='dataset.isTested'>done</mat-icon> -->
    </a>
    <h1>Featured Data Set</h1>
    <table border="0">
      <tr>
        <td><a (click)="goToFeaturedDataset()"><img src="/assets/icons/brainumap3.jpg" /></a></td>
        <td>&nbsp;&nbsp;&nbsp;</td>
        <td>RNA seq dataset of normal and neoplastic brain using UMAP. <a href="javascript:void(0)" (click)="goToFeaturedDataset()">Explore it</a></td>
      </tr>
    </table>
  </div>
</div>
