import { Khonsole } from '../../app/khonsole';
/**
 * Represents a visual legend
 */
//import { ChartScene } from './../../app/component/workspace/chart/chart.scene';
import * as THREE from 'three';

import { DataDecorator, DataDecoratorTypeEnum } from "./data-map.model";
import { GraphEnum, VisualizationEnum, EntityTypeEnum } from './enum.model';
// import { AbstractScatterVisualization } from  'app/component/visualization/visualization.abstract.scatter.component';
// import { entityNameType } from 'aws-sdk/clients/iam';
// import { ChartScene } from 'app/component/workspace/chart/chart.scene';
// import { ScatterGraph } from 'app/component/visualization/scatter/scatter.graph';

export class Legend {
  result: any; // data results from compute call, but might be null.
  name: string;
  type: 'COLOR' | 'SHAPE' | 'SIZE' | 'INTERSECT' | 'IMAGE';
  display: 'CONTINUOUS' | 'DISCRETE';
  labels: Array<string>;

  values: Array<any>;
  counts: Array<number>; // count of all items matching the legend value
  visibility: Array<number>;
  entity: EntityTypeEnum = EntityTypeEnum.SAMPLE;
  selectionCounts?: Array<number>;   // count of all *selected* items matching the legend value

  get items(): Array<{ label: string, value: string }> {
    return this.labels.map((lbl, i) => ({
      label: lbl,
      value: this.values[i]
    }));
  }

  // public get formattedCountStrings(): Array<string> {
  //   if(this.counts && this.counts.length > 0) {
  //     Khonsole.log("TBD===> write selectionCounts in formattedCountStrings")
  //     return this.counts.map(c => '&nbsp;&nbsp;&nbsp;(' + "{blxnt}" + ')');
  //   } else {
  //     return new Array(200).fill(' xyzMJ'); 
  //   }
  // }

  static ColorizeValueIfColorDataDecorator(decorator: DataDecorator,value:any){
    let valToUse = value.toString();
    if (decorator.type == DataDecoratorTypeEnum.COLOR){
      let hexColor:string = valToUse;
      if (hexColor.startsWith('#') == false){
        hexColor = "#"+("00000"+parseInt(value).toString(16)).slice(-6).toLowerCase();
      }
      valToUse= hexColor;
    }
    return valToUse;
  }

  // public static CountTotalsAndCollectPidsForDataDecorator(decorator: DataDecorator ){
  //   Khonsole.log("CountTotalsAndCollectPidsForDataDecorator")

  //   // Total up the counts for each label.
  //   let valueCounts = {};
  //   let valueSelectionCounts = {};
  //   let valuePids = {}; // Each label will have an array of patient IDs

  //   let scatterViz:AbstractScatterVisualization = null;
  //   let lastSelectedPoints= []; // strings
  //   let noPointsSelected = true;
  //   // let selectionCountsArray: Array<number> = [];

  //   if (decorator.config && decorator.config.isScatterVisualization){
  //     let view_id:number = 0;
  //     let ge_b = GraphEnum.GRAPH_B;
  //     Khonsole.log(`aaaaa ${ge_b}. `)
  //     let ge_a = GraphEnum.GRAPH_A;
  //     Khonsole.log(`aaaaa ${ge_a}. `)
  //     if (decorator.config.graph == 2 ){  // GraphEnum.GRAPH_B){
  //       view_id = 1
  //     }

  //     scatterViz = ChartScene.instance.views[view_id].chart as AbstractScatterVisualization;
  //     Khonsole.log(`Selected point count: ${scatterViz.getLastSelectionPatientIds().length}.`)
  //     lastSelectedPoints = scatterViz.getLastSelectionPatientIds()
  //     noPointsSelected = lastSelectedPoints.length == 0;
  //   }

  //   decorator.values.forEach(v => {
  //     let pointIsSelected = false;
  //     if (scatterViz){
  //       pointIsSelected = lastSelectedPoints.includes(v.pid);
  //     }

  //     let valToUse = Legend.ColorizeValueIfColorDataDecorator(decorator, v.value);
  //     v.value = valToUse;

  //     // Count all points
  //     if(valueCounts[valToUse] == null) {
  //       valueCounts[valToUse] = 0;
  //       valueSelectionCounts[valToUse] = 0;
  //       valuePids[valToUse] = []; 
  //     }
  //     // use valToUse instead of v.label, because it handles color change 
  //     // but is this right in all cases?  TBD. 2022-09-27 MJ 
  //     valueCounts[valToUse]++;  // valueCounts[v.label]++;
  //     valuePids[valToUse].push(v.pid); //  valuePids[v.label].push(v.pid); 

  //     if(noPointsSelected || pointIsSelected){
  //       if (pointIsSelected){
  //         valueSelectionCounts[valToUse]++; 
  //       }
  //     }

  //   });
  //   let valueCountsArray: Array<number> = [];
  //   let valueSelectionCountsArray: Array<number> = [];
    
  //   // Now total everyone up by legend item and put totals in legend
  //   let iLegendVal = 0;
  //   decorator.legend.values.forEach(v  => {
  //     // let pointIsSelectedOrNoneAre = true;
  //     // if (scatterViz){
  //     //   pointIsSelectedOrNoneAre = scatterViz.getLastSelectionPatientIds().length == 0 || scatterViz.getLastSelectionPatientIds().includes(v.pid);
  //     // }
  //     // if(pointIsSelectedOrNoneAre){
  //       let valToUse = Legend.ColorizeValueIfColorDataDecorator(decorator, v);
  //       decorator.legend.values[iLegendVal] = valToUse; // ?? doesthiswork?
  //       valueCountsArray.push(valueCounts[valToUse]);
  //       valueSelectionCountsArray.push(valueSelectionCounts[valToUse]);
        
  //       iLegendVal++;
  //     // }
  //   });
  //   decorator.legend.counts = valueCountsArray;
  //   decorator.legend.selectionCounts = valueSelectionCountsArray; // selectionCountsArray;

  //   //   pidsByLabel: Array<[string, Array<string>]>;
  //   decorator.pidsByLabel = [];//valuePids;
  //   decorator.legend.items.map(item => {
  //     let row = {label: item.value, pids: valuePids[item.value]};
  //     decorator.pidsByLabel.push(row);
  //   }
  //   );
  //   // Object.keys(valuePids).map(key => {
  //   //   let row = {label: key, pids: valuePids[key]};
  //   //   decorator.pidsByLabel.push(row);
  //   // });    
  // }

  public static create(result: any, name: string, labels: Array<string>,
      values: Array<string>, 
      type: 'COLOR' | 'SHAPE' | 'SIZE' | 'INTERSECT' | 'IMAGE', 
      display: 'CONTINUOUS' | 'DISCRETE' ): Legend {
    const l = new Legend();
    Khonsole.log("----create legend: " + name +" -----");
    l.result = null;  // TBD: What should result be?
    l.display = display;
    l.name = name;
    l.type = type;
    l.labels = labels;
    l.values = values;
    l.counts = []; // fill in later
    l.selectionCounts = [];
    l.visibility = new Array(labels.length).fill(1) // fully visible, to start.
    return l;
  }

  constructor () {
    Khonsole.log("----constructor legend: " + this.name +" -----");
    return this;
  }


}

