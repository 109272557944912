<div class="color-by-form">

  <!-- Color by Metadata -->

    <div id="color-by-metadata" class="option-container">
      <div class="arrow-container">
        <mat-icon
          class="arrow"
          [class.selected]="selectedOption == 'metadata'"
          >arrow_forward</mat-icon
        >
      </div>

      <div class="form-field-container">
        <mat-form-field class="form-field" id="color-by-metadata">
          Color by Metadata
          <mat-select
            id="colorMetadataText"
            (selectionChange)="onMetadataValueChange($event)"
            [(value)]="metadataValue"
            [compareWith]="byKey"
          >
            <mat-option
              *ngFor="let option of featureColorOptions"
              [value]="option"
            >
              {{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>



  <!-- Color by Table Data -->

    <div *ngIf="data.tableName != unset_table_placeholder" id="color-by-table" class="option-container">
      <div class="arrow-container">
        <mat-icon
          id="color-by-table-arrow"
          class="arrow"
          [class.selected]="selectedOption == 'table'"
          >arrow_forward</mat-icon
        >
      </div>

      <div class="form-field-container">
        Color by
        <select
          id="color-by-table-select"
          [(ngModel)]="data.tableName"
          (change)="onTableChange($event)"
        >
          <option *ngFor="let tbl of tablesWithIds" [value]="tbl">
            {{ formatDropdownTableName(tbl).toUpperCase() }}
          </option></select
        >:
        <mat-form-field [style.HIDDENwidth.px]="440" id="color-by-table">
          <input
            type="text"
            id="colorbyTextControl"
            (keyup.enter)="onTablePressEnter($event)"
            placeholder="Enter Identifier Here..."
            [formControl]="colorbyControl"
            matInput
            [matAutocomplete]="auto"
          />

          <mat-autocomplete
            id="colorby_autocomplete"
            cdkFocusInitial
            panelWidth="460px"
            #auto="matAutocomplete"
            (optionSelected)="onTableValueChange($event)"
          >
            <mat-option *ngFor="let id of filteredOptions   | async" [value]="id"
              >{{id}}</mat-option
            >
            <cdk-virtual-scroll-viewport
              class="autocomplete-test-viewport"
              [ngStyle]="{'height': 100}"
              itemSize="20"
            >
              <mat-option
                *cdkVirtualFor="let option of filteredOptions; index as i; first as isFirst"
                [value]="option"
                class="autocomplete-item"
              >
                {{option}} -
              </mat-option>
            </cdk-virtual-scroll-viewport>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>

</div>
