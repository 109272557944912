<!-- <div *ngIf="false"  #plotlyPlot></div> -->

<div style="height: auto !important;">
  <div *ngFor='let legendFilter of _legendFilters' style='border-bottom-width: 1px;'>
    <div class='legendHeader'>Hidden: {{countAttempt(legendFilter)+legendFilter.excludedValues.length}}</div>
  </div>

  <mat-expansion-panel *ngFor="let legend of allLegendsExceptDataPointsPlaceholder() index as i; trackBy: trackLegendByFn"  [expanded]="true" >
    <mat-expansion-panel-header *ngIf='isntDataPointsPlaceholder(legend)'  >
      <mat-panel-title class='legendHeader' [ngClass]="{'color-legend-header': isColorLegend(legend)}" style="padding-top: 4px !important;padding-bottom: 4px !important;">
        <span
        >
          {{smarterName(legend.name)}}
          <i
          matTooltip="{{smarterName(legend.name)}}"
          matTooltipPosition="right"
          [matTooltipDisabled]="true"
          #legendTooltip="matTooltip"
          matTooltipClass="custom-tooltip"
          class="fa fa-info-circle"
          (mouseenter)="displayTooltip(legendTooltip)"
          (mouseleave)="hideTooltip(legendTooltip)"
        ></i>
        </span>
      </mat-panel-title>
    </mat-expansion-panel-header>
      <!-- Shape -->
      <div *ngIf='legend.type === "SHAPE" && isntDataPointsPlaceholder(legend)'>
        <div *ngFor='let label of legend.labels; let i = index; ' class='legendItem'>
          <img class='swatch' src='{{legend.values[i]}}'>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;{{label}}</span>
          <!-- <div class='swatchButtons'>
                  <a href='#' class='os-link' (click)='select()'>Select</a> |
                  <a href='#' class='os-link' (click)='deselect()'>Deselect</a>
              </div> -->
        </div>
      </div>
      <!-- Color -->
      <div *ngIf='legend.type === "COLOR"'>
        <div *ngFor='let label of legend.labels; let i = index; ' class='legendItem'>
          <table><tr>
            <td><app-workspace-legend-item *ngIf='config.isScatterVisualization'
              (eyeClickItemEvent)="eyeClickItem($event)"
              [legend]="legend"
              [i]="i"
            ></app-workspace-legend-item>
            </td>
            <td><span class='swatch2' (click)="customizeColor(legend, i)"   [style.background-color]='legend.values[i]'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></td>
            <td><span class='{{legendItemHasSelectedPoints(legend,i)?"legendItem_with_selection":""}}' id="ocLegendItem_{{label}}" (click)="legendItemClick(legend, i)" style="{cursor:pointer; }" >{{formattedLegendItemText(legend, i)}}</span></td>
          </tr></table>

        </div>
      </div>


  </mat-expansion-panel>
