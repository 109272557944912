<div
  class="panel"
  style="position:absolute;top:0px;z-index:1;padding-bottom:0px;    font-size: 14px;
    line-height: 20px;"
>
  <div>
    <a href="#" class="modalClose" (click)="closeClick()"> </a>
    <h1 class="panel-header" style="margin-top: 40px;margin-bottom: 25px;">Settings</h1>
    <section>
      <p><mat-checkbox>Enable Tips</mat-checkbox></p>
      <p><mat-checkbox>Invert Color</mat-checkbox></p>
      <p><mat-checkbox>Invert Color</mat-checkbox></p>
      <p style="position:relative;"><span>Point Size</span><mat-slider></mat-slider></p>
      <button mat-raised-button>Reset Oncoscape</button>
    </section>
  </div>
</div>
