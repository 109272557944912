<div class="panel">
  <div>
    <a href="#" class="modalClose" (click)="closeClick()"> </a>
    <h1 class="panel-header">Data Upload</h1>
    <h2 class="panel-header-desc">Complete the steps below to visualize your own data.</h2>
    Oncoscape imports data in <a target="_blank" href="https://github.com/cBioPortal/cbioportal/blob/master/docs/Data-Loading.md">the formats of the cBioPortal</a> project. After preparing individual text files, put them all into a .zip file (no nesting inside folders, just flat). The .zip file is what you will upload to Oncoscape.
  </div>

  <!-- Create Dataset -->

  <mat-vertical-stepper #stepper linear="true" style="transform: translateX(-24px);width:80%;display:inline-block;">
    <mat-step label="Step 1: Accept Our Terms of Use" completed="false">
      <p>
        Oncoscape requires all Protected Health Information (PHI) be removed from datasets prior to upload. PHI is
        defined and regulated under United States Public Law 104-191 under the Health Insurance Portability and
        Accountability Act of 1996 (HIPPA). For a detailed description of what constitutes PHI please visit the
        <a
          class="os-link"
          target="_blank"
          href="https://www.hhs.gov/hipaa/for-professionals/privacy/laws-regulations/index.html"
        >
          United States Health and Human Services (HHS)</a
        >
        website.
      </p>
      <p>
        By clicking I Agree, you certify that your dataset is free of PHI and conforms with all United States laws and
        regulations as well as the Fred Hutchinson Cancer Research Center's
        <a class="os-link" target="_blank" href="/assets/terms.html">Terms and Conditions</a>.
      </p>
      <button mat-raised-button (click)="processIAccept()" color="primary">I Accept</button>
    </mat-step>

    <mat-step label="Step 2: Select Your .zip Data File" class="fileupload-step2" completed="false">
      <p>
        <input style="display:none;" #fileInput class="oncoscape-file-choice" type="file" multiple accept=".zip" (change)="fileChangeEvent($event)" placeholder="Upload file..."/>

        Oncoscape expects your files to be in the <a href="https://github.com/cBioPortal/cbioportal/blob/master/docs/File-Formats.md" class="os-link" target="_blank">cBioPortal format</a>, and placed into a .zip file. See <a href="javascript:{alert('[Add _target.] Documentation not ready yet. Please contact Matt Jensen at mnjensen@fredhutch.org')}" class="os-link" >these tips</a> about file organization and content formatting.<br />
        <br />
        Select the .zip file to upload to Oncoscape.<br />
        

        <!--
          The easiest way to view your data in Oncoscape is to upload it using our Excel template. For detailed instructions or to
          download the template click the links below. If you dataset is too large for excel or you would you have a number
          of datasets you would like to ingest in bulk, please contact us at
          <a href='mailto:contact-sttr@fredhutch.org' class='os-link'>contact-sttr@fredhutch.org</a>
        -->
        <!--
          </p>
          <p>
            <a href='/assets/oncoscape-upload-instructions.pdf' class='os-link' target='_blank'>Download XLSX Instructions</a>
          </p>
          <p>
            <a href='/assets/oncoscape-upload-template.xltx' class='os-link' target='_blank'>Download XLTX Template</a>
          </p>
        -->
      </p>

      <p>
        <a mat-raised-button color="primary" (click)="uploadFile()" style="margin-right:20px;width:120px;" href="#"
          >Select File</a
        >
        <!-- <button mat-raised-button matStepperNext color="primary">Select Files</button> -->
      </p>
    </mat-step>

    <mat-step label="Step 3: Annotate Your Dataset" MatStepperNext completed="false">
      <button id='populate' *ngIf='isMJ("step3Annotate")' (click)='testPopulate()'>MJ TEST: Populate</button>
      <form [formGroup]="formGroup">
        <div class="forms-container">
          <div class="form-container">
            <mat-form-field>
              <input
                matInput
                placeholder="Dataset Name"
                formControlName="name"
                autocomplete="off"
                [errorStateMatcher]="matcher"
              />
              <!-- <mat-error *ngIf='formGroup.controls["name"].hasError("required")'>Required</mat-error> -->
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                placeholder="Dataset Description"
                formControlName="description"
                autocomplete="off"
                [errorStateMatcher]="matcher"
              />
              <!-- <mat-error *ngIf='formGroup.controls["description"].hasError("required")'>Required</mat-error> -->
            </mat-form-field>

            <mat-form-field>
              <mat-select placeholder="Institutional Review" formControlName="reviewType">
                <mat-option *ngFor="let reviewType of reviewTypes" [value]="reviewType"> {{ reviewType }} </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="this.showReviewNumber">
              <input matInput placeholder="Institutional Review Id" formControlName="reviewNumber" autocomplete="off" />
            </mat-form-field>
          </div>
          <div class="form-container">
            <mat-form-field>
              <mat-select placeholder="Disease Site" formControlName="site">
                <mat-option *ngFor="let siteType of siteTypes" [value]="siteType"> {{ siteType }} </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-select placeholder="Species" formControlName="species">
                <mat-option *ngFor="let speciesType of speciesTypes" [value]="speciesType">
                  {{ speciesType }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <input matInput placeholder="Lab" formControlName="lab" autocomplete="off" />
            </mat-form-field>

            <mat-form-field>
              <input matInput placeholder="Institution" formControlName="institution" autocomplete="off" />
            </mat-form-field>
          </div>
          <div class="form-container">
            <mat-form-field>
              <input matInput placeholder="Contact Name" formControlName="contactName" autocomplete="off" />
            </mat-form-field>

            <mat-form-field>
              <input matInput placeholder="Contact Email" formControlName="contactEmail" autocomplete="off" />
            </mat-form-field>

            <mat-form-field>
              <input matInput placeholder="Contact Phone" formControlName="contactPhone" autocomplete="off" />
            </mat-form-field>

            <!-- <mat-checkbox formControlName='contactAllow'>Permit Contact</mat-checkbox> -->
          </div>
        </div>

        <button (click)="formValidate()" style="width:120px;" mat-raised-button color="primary">Next</button>
      </form>
    </mat-step>

    <mat-step label="Step 4: Upload" completed="false">
      <div style="display:inline-block">
        You are ready to upload your data. The terms accepted above apply. Click the button below to upload.
        <p >&nbsp;</p>
        <p id='feedbackAfterError'></p>
        <p>
          <button (click)="formSubmit()" [disabled]="disableUploadButton === true" style="width:120px;margin-top:10px;" mat-raised-button color="primary">
            Upload
          </button>
        </p>
      </div>
      <div id='progressArea'>
          <table id="stateTable"  class="table" data-toggle="table" data-show-refresh="false">
            <thead>
              <tr>
                <th scope="col">Uploading</th><th></th>
                <th scope="col">Validating</th><th></th>
                <th scope="col">Converting</th><th></th>
                <th scope="col">Finalizing</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                  <td>
                    <i id="stageUploading"  class="fa fa-upload" style="font-size:48px;color:lightgray"></i><br/>
                    <i id="stageUploadingProgress"  class="fa fa-ellipsis-h" style="font-size:48px;color:lightgray"></i>
                  </td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                  <td>
                    <i id="stageValidating"  class="fa fa-gears" style="font-size:48px;color:lightgray"></i><br/>
                    <i id="stageValidatingProgress"  class="fa fa-ellipsis-h" style="font-size:48px;color:lightgray"></i>
                  </td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                  <td>
                    <i id="stageConverting"  class="fa fa-gears" style="font-size:48px;color:lightgray"></i><br/>
                    <i id="stageConvertingProgress"  class="fa fa-ellipsis-h" style="font-size:48px;color:lightgray"></i>
                  </td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                  <td>
                    <i id="stageFinalizing"  class="fa fa-edit" style="font-size:48px;color:lightgray"></i><br/>
                    <i id="stageFinalizingProgress"  class="fa fa-ellipsis-h" style="font-size:48px;color:lightgray"></i>
                  </td>
              </tr>
            </tbody>
          </table>
      </div>
    </mat-step>
  </mat-vertical-stepper>
  <div id="file-choice-details" ></div>
  <div id="dataset-name-display" ></div>

  
  <app-workspace-upload-feedback-panel
  [hidden]="!uploadFeedbackVisible" 
  (hide)="hideUploadFeedback()"
  ></app-workspace-upload-feedback-panel>


  <div style="display:inline-block;width:30%;margin-left:5%;vertical-align:top;text-align: center;">
    <!-- 
    <div class="video-responsive">
      <iframe
        width="420"
        height="315"
        src="https://www.youtube.com/embed/VfKFbri7XfQ?loop=1&modestbranding=1&playsinline=1&rel=0&showinfo=0&color=white"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  -->
  </div>
</div>
