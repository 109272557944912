import { DimensionEnum } from './../../../model/enum.model';
import { VisualizationAbstractScatterConfigModel } from "../visualization.abstract.scatter.model";
export var PlsDisplayEnum;
(function (PlsDisplayEnum) {
    PlsDisplayEnum[PlsDisplayEnum["WEIGHT"] = 1] = "WEIGHT";
    PlsDisplayEnum[PlsDisplayEnum["SCORE"] = 2] = "SCORE";
    PlsDisplayEnum[PlsDisplayEnum["LOADING"] = 4] = "LOADING";
    PlsDisplayEnum[PlsDisplayEnum["NONE"] = 0] = "NONE";
})(PlsDisplayEnum || (PlsDisplayEnum = {}));
export class PlsConfigModel extends VisualizationAbstractScatterConfigModel {
    constructor() {
        super(...arguments);
        this.dimension = DimensionEnum.THREE_D;
        this.domain = [-500, 500];
        this.showVectors = false;
        this.latientVectors = 10;
        this.tolerance = 1e-4;
    }
}
