import { Khronos } from "ngx-bootstrap/chronos/test/chain";
import { environment } from '../environments/environment';

export class KhonsoleAction {
  public action:string;
  public data:any;

  constructor(action: string, data: any) {
    this.action = action;
    this.data = data;
  }
}

export class Khonsole {
  public static history: KhonsoleAction[] = [];
  public static _echo:boolean = environment.production ? false : true;
  public static instance: Khonsole;

  public foo = 'bar';

  public static log(...args: any[]) {
    if (this._echo) {
      // Make an error so we can extract caller's location in the stack trace.
      let error = new Error();
      let stack = error.stack || '';
      let match = stack.split('\n')[2].match(/(http.+):(\d+):(\d+)/) || [];
      let link = match[0];

      console.log.apply(console, [...args, `at ${link}`]);
    }

    args.forEach(arg => {
      this.history.push(new KhonsoleAction("log", arg ));
    });
  }

  public static warn(...args: any[]) {
    if (this._echo) {
      // Make an error so we can extract caller's location in the stack trace.
      let error = new Error();
      let stack = error.stack || '';
      let match = stack.split('\n')[2].match(/(http.+):(\d+):(\d+)/) || [];
      let link = match[0];

      console.warn.apply(console, [...args, `at ${link}`]);
    }

    args.forEach(arg => {
      this.history.push(new KhonsoleAction("warn", arg));
    });
  }

  public static error(...args: any[]) {
    if (this._echo) {
      // Make an error so we can extract caller's location in the stack trace.
      let error = new Error();
      let stack = error.stack || '';
      let match = stack.split('\n')[2].match(/(http.+):(\d+):(\d+)/) || [];
      let link = match[0];

      console.error.apply(console, [...args, `at ${link}`]);
    }

    args.forEach(arg => {
      this.history.push(new KhonsoleAction("error", arg));
    });
  }

  public static dir(o: any) {
    if (this._echo) console.dir(o);
    this.history.push(new KhonsoleAction("dir", o));
  }

  public static time(o: any) {
    console.time(o);
    this.history.push(new KhonsoleAction("time", o));
  }

  public static timeEnd(o: any) {
    console.timeEnd(o);
    this.history.push(new KhonsoleAction("timeEnd", o));
  }

  /*
  public static warn(o: any) {
    let s = o.toString();
    if (this._echo) console.warn(s);
    this.history.push(new KhonsoleAction("warn", s));
  }

  public static error(o: any) {
    let s = o.toString();
    if (this._echo) console.error(s);
    this.history.push(new KhonsoleAction("error", s));
  }

  public static dir(o: any) {
    if (this._echo) console.dir(o);
    this.history.push(o);
    this.history.push(new KhonsoleAction("dir", o));
  }
*/

  public static echo(flag: boolean) {
    this._echo = flag;
  }

  constructor() {
    window['khonsole'] = Khonsole;
  }  
}
