
<form [formGroup]='form' novalidate>
  <mat-form-field class='form-field'>
    <mat-select placeholder='Data' formControlName='table'>
      <mat-option *ngFor='let option of dataOptions' [value]='option'>
          {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class='form-group'>
    <div class='switch'>
      <label>
        <input type='checkbox' formControlName='showCytobands'>
        <span class='lever'></span>
        Cytobands
      </label>
    </div>
    <div class='switch'>
      <label>
        <input type='checkbox' formControlName='showCytobands'>
        <span class='lever'></span>
        Rotation
      </label>
    </div>
  </div>
</form>
  