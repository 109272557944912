import { HttpClient } from '../../app/service/http.client';
import * as i0 from "@angular/core";
import * as i1 from "./http.client";
export class VersionService {
    constructor(http) {
        this.http = http;
    }
    getVersion() {
        let version_location = '/assets/version.txt';
        return this.http.get(version_location); // If it were standard http client,  { responseType: 'text' });
    }
}
VersionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VersionService_Factory() { return new VersionService(i0.ɵɵinject(i1.HttpClient)); }, token: VersionService, providedIn: "root" });
