/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./legend-item.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/extended";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "./legend-item.component";
var styles_LegendItemComponent = [i0.styles];
var RenderType_LegendItemComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LegendItemComponent, data: {} });
export { RenderType_LegendItemComponent as RenderType_LegendItemComponent };
export function View_LegendItemComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.legendItemEyeClick($event, _co.legend, _co.i) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "i", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(4, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.checkVisibleEyeFlag() ? "eye2 fa fa-eye" : "eye2 fa fa-eye-slash"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.checkVisibleEyeFlag() ? "eye2 fa fa-eye" : "eye2 fa fa-eye-slash"); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_LegendItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workspace-legend-item", [], null, null, null, View_LegendItemComponent_0, RenderType_LegendItemComponent)), i1.ɵdid(1, 4440064, null, 0, i5.LegendItemComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LegendItemComponentNgFactory = i1.ɵccf("app-workspace-legend-item", i5.LegendItemComponent, View_LegendItemComponent_Host_0, { legend: "legend", i: "i" }, { eyeClickItemEvent: "eyeClickItemEvent" }, []);
export { LegendItemComponentNgFactory as LegendItemComponentNgFactory };
