<form [formGroup]='form' novalidate>
    <mat-form-field class='form-field'>
        <mat-select placeholder='Data' formControlName='table' [compareWith]='byTbl'>
            <mat-option *ngFor='let option of dataOptions' [value]='option'>
                {{ option.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class='form-field-1-3'>
        <mat-select placeholder='X Axis' formControlName='pcx'>
            <mat-option *ngFor='let option of PcOptions' [value]='option'>
                PC {{ option }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class='form-field-1-3'>
        <mat-select placeholder='Y Axis' formControlName='pcy'>
            <mat-option *ngFor='let option of PcOptions' [value]='option'>
                PC {{ option }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class='form-field-1-3'>
        <mat-select placeholder='Z Axis' formControlName='pcz'>
            <mat-option *ngFor='let option of PcOptions' [value]='option'>
                PC {{ option }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class='form-field'>
        <mat-select placeholder='Metric' formControlName='metric'>
            <mat-option *ngFor='let option of TsneMetricOptions' [value]='option'>
                {{ option }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class='form-field'>
        <mat-select placeholder='Method' formControlName='sk_method'>
            <mat-option *ngFor='let option of TsneMethodOptions' [value]='option'>
                {{ option }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-label>Perplexity</mat-label>
    <br />
    <mat-slider min='1' max='50' formControlName='perplexity' thumbLabel='true'></mat-slider>
    <br />
    <mat-label>Early Exaggeration</mat-label>
    <br />
    <mat-slider min='3' max='24' formControlName='early_exaggeration' thumbLabel='true'></mat-slider>
    <br />
    <mat-label>Learning Rate</mat-label>
    <br />
    <mat-slider min='1' max='1000' formControlName='learning_rate' thumbLabel='true'></mat-slider>
    <br />
    <mat-label>Max Iterations</mat-label>
    <br />
    <mat-slider min='1' max='1000' formControlName='n_iter' thumbLabel='true'></mat-slider>
    <br />
</form>