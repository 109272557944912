<div class='loader'>
    <div class='loader-background'></div>
    <div class='loader-copy-container'>

        <div class='loader-copy'>
            <div class='loader-quote'>&lsquo;{{quote.q}}&rsquo;
                <div class='loader-author'>{{quote.a}}
                    <div *ngIf='showEllipsis' class="lds-ellipsis" style='height:15px;top:-20px;left:10px;'>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
            <div style="text-align: center;  font-size:9px;"><a style="color:gray;" id="loader-cancel" href="javascript:setTimeout(function(){document.querySelector('.loader').style.visibility = 'hidden';}, 200);">cancel</a></div>
        </div>
    </div>
</div>