<form [formGroup]='form' novalidate>
  <mat-form-field class='form-field'>
    <mat-select placeholder='Data Table' formControlName='table' [compareWith]='byTbl'>
      <mat-option *ngFor='let option of dataOptions' [value]='option'>
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class='form-field'>
    <mat-select placeholder='Data Element' formControlName='entity'>
      <mat-option *ngFor='let option of displayOptions' [value]='option'>
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class='form-field-1-3'>
    <mat-select placeholder='X Axis' formControlName='pcx'>
      <mat-option *ngFor='let option of PcOptions' [value]='option'>
        PC {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class='form-field-1-3'>
    <mat-select placeholder='Y Axis' formControlName='pcy'>
      <mat-option *ngFor='let option of PcOptions' [value]='option'>
        PC {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class='form-field-1-3'>
    <mat-select placeholder='Z Axis' formControlName='pcz'>
      <mat-option *ngFor='let option of PcOptions' [value]='option'>
        PC {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-label>Tol</mat-label>
  <mat-slider min="1" max="100" step="1" formControlName='tol' thumbLabel='true'></mat-slider>
  <br />
  <br />
  <mat-slide-toggle formControlName='scale'>Scale</mat-slide-toggle>
</form>
