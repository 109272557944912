import { Injectable } from '@angular/core';
import { HttpClient } from '../../app/service/http.client';
 
//# '@angular/common/http';
import { Observable } from 'rxjs';
 
@Injectable({
    providedIn: 'root'
})
export class VersionService {

    constructor(private http: HttpClient) { }

    getVersion(): Observable<any> {
        let version_location = '/assets/version.txt';
        return this.http.get(version_location); // If it were standard http client,  { responseType: 'text' });
    }
}
