<form [formGroup]='form' novalidate>
  <mat-form-field class='form-field'>
    <mat-select placeholder='Align By' formControlName='align' [compareWith]='byLabel'>
      <mat-optgroup *ngFor='let group of alignOptions' [label]='group.label'>
        <mat-option *ngFor='let evt of group.items' [value]='evt'>
          {{ evt.label }}
        </mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>
  <mat-form-field class='form-field'>
    <mat-select placeholder='Sort By' formControlName='sort' [compareWith]='byLabel'>
      <mat-optgroup *ngFor='let group of sortOptions' [label]='group.label'>
        <mat-option *ngFor='let item of group.items' [value]='item'>
          {{ item.label }}
        </mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>
  <mat-form-field [ngStyle]="{'margin-left': 15}" class='form-field'>
    <mat-select placeholder='Sort Comparison (for events)' formControlName='sortComparison' [compareWith]='byLabel'>
      Comparison:&nbsp;<mat-option *ngFor='let item of sortComparisonOptions' [value]='item'>
        {{ item }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class='form-field'>
    <mat-select placeholder='Group By' formControlName='group' [compareWith]='byLabel'>
      <mat-optgroup *ngFor='let group of groupOptions' [label]='group.label'>
        <mat-option *ngFor='let item of group.items' [value]='item'>
          {{ item.label }}
        </mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>
  <mat-form-field class='form-field'>
    <mat-select multiple placeholder='Heat Map' formControlName='attrs'>
      <mat-option *ngFor='let item of patientAttributes' [value]='item.label'>
        {{ item.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <button mat-button style="color:#1e88e5;width:100%;" (click)="createCohortFromButton($event)">
    <mat-icon class="material-icons md-18" style="transform:translate(0px, 2px);margin-right:0px;"
      >settings</mat-icon
    >Create Range Cohort
  </button>

  <div formArrayName='bars'>
    <div *ngFor='let bar of ctrls; let i=index'>
      <div [formGroupName]='i'>
        <span class='form-label' style='
                text-align: left;
                width: 100%;
                padding: 10px 0px 10px 0px;
                font-size: 12px;
                font-weight: 400;
                display: inline-block'>
          {{ctrls[i].get('label').value}} Events</span>
        <div class='form-group'>
          <mat-form-field class='form-field'>
            <mat-select placeholder='Visualize' formControlName='style'>
              <mat-option *ngFor='let item of styleOptions' [value]='item'>
                {{ item }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class='form-group'>
          <mat-form-field class='form-field'>
            <mat-select multiple placeholder='Visible' formControlName='events' multipile>
              <mat-option *ngFor='let item of eventTypes[ctrls[i].get("label").value]' [value]='item.subtype'>
                {{ item.subtype }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>



</form>
<!--
      <div>
        <span class='form-label' style='text-align:left;width:100%;font-weight:700;padding:10px 0px 0px 0px;font-size:.9rem;'>
        Visible Day Range
        </span>
        <div style='padding:0px 6px;'>
          <nouislider [connect]='true' [min]='0' [max]='100' [step]='1' [(ngModel)]='this.rv'
            (change)='rangeChange()'></nouislider>
        </div>
      </div>
      -->
