<div style='width:100%; height: 100vh; background:rgba(0,0, 0, .3);position:absolute;left:0px;top:0px;
position: absolute;
left: 0px;
top: 0px;
z-index: 99999;'>
  <mat-card style='max-width:400px;margin-left:auto;margin-right: auto;margin-top:10vh;'>
    <mat-card-header>
      <mat-card-title> {{tip.title}} Quick Tips</mat-card-title>
      <!-- <mat-card-subtitle>PCA Incremental Default View</mat-card-subtitle> -->
      <span style='flex: 1 1 auto;'></span>{{tipIndex}}/{{tipCount}}
    </mat-card-header>
    <img mat-card-image src="./assets/tips/{{tip.src}}" alt="tips" style='border: 1px solid #ddd;
    border-left: 0px;
    border-right: 0px;'>
    <mat-card-content>
      <p>
        {{tip.desc}}
      </p>
    </mat-card-content>
    <mat-card-actions style='text-align:center;padding-top: 0px;padding-bottom: 10px;'>
      <button mat-raised-button color="primary" (click)='nextClick();'>Next Tip</button>
      <button mat-raised-button color="primary" (click)='hideClick();'>Close</button>
    </mat-card-actions>
  </mat-card>
  <!-- <div style='background:#FFF;position:absolute;left:5%;top:5%;right:5%;bottom:5%;border:5px solid #1e88e5;'>
    <button (click)='hideClick()'></button>

    {{jsonContent}}
    <div class="button-row">
      <button mat-icon-button>
        <mat-icon aria-label="Example icon-button with a heart icon">favorite</mat-icon>
      </button>
      <button mat-icon-button color="primary">
        <mat-icon aria-label="Example icon-button with a heart icon">favorite</mat-icon>
      </button>
      <button mat-icon-button color="accent">
        <mat-icon aria-label="Example icon-button with a heart icon">favorite</mat-icon>
      </button>
      <button mat-icon-button color="warn">
        <mat-icon aria-label="Example icon-button with a heart icon">favorite</mat-icon>
      </button>
      <button mat-icon-button disabled>
        <mat-icon aria-label="Example icon-button with a heart icon">favorite</mat-icon>
      </button>
    </div>
  </div> -->
</div>
