<form [formGroup]='form' novalidate>
  <mat-form-field class='form-field'>
    <mat-select placeholder='Data Table' formControlName='table' [compareWith]='byTbl'>
      <mat-option *ngFor='let option of dataOptions' [value]='option'>
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class='form-field'>
    <mat-select placeholder='Data Element' formControlName='entity'>
      <mat-option *ngFor='let option of displayOptions' [value]='option'>
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class='form-field-1-3'>
    <mat-select placeholder='X Axis' formControlName='pcx'>
      <mat-option *ngFor='let option of PcOptions' [value]='option'>
        PC {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class='form-field-1-3'>
    <mat-select placeholder='Y Axis' formControlName='pcy'>
      <mat-option *ngFor='let option of PcOptions' [value]='option'>
        PC {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class='form-field-1-3'>
    <mat-select placeholder='Z Axis' formControlName='pcz'>
      <mat-option *ngFor='let option of PcOptions' [value]='option'>
        PC {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class='form-field'>
    <mat-select placeholder='Nu SVR Kernal' formControlName='Nu_SVR_Kernal'>
        <mat-option *ngFor='let option of NuSVCKernalOptions' [value]='option'>
            {{ option }}
        </mat-option>
    </mat-select>
</mat-form-field>
<!-- <mat-form-field class='form-field'>
  <mat-select placeholder='Nu SVR Decision Function Shape' formControlName='Nu_SVR_Decision_Function_Shape'>
      <mat-option *ngFor='let option of NuSVRDecisionFunctionShapeOptions' [value]='option'>
          {{ option }}
      </mat-option>
  </mat-select>
</mat-form-field> -->
<!-- <mat-form-field class='form-field'>
  <mat-select placeholder='Linear SVD Loss' formControlName='Linear_SVC_Loss'>
      <mat-option *ngFor='let option of LinearSVCLossOptions' [value]='option'>
          {{ option }}
      </mat-option>
  </mat-select>
</mat-form-field> -->
<!-- <mat-form-field class='form-field'>
  <mat-select placeholder='Linear SVD Random State' formControlName='Linear_SVC_Random_State'>
      <mat-option *ngFor='let option of LinearSVCRandomStateOptions' [value]='option'>
          {{ option }}
      </mat-option>
  </mat-select>
</mat-form-field> -->
  <!-- <mat-slide-toggle formControlName='fit_intercept'>Fit Intercept</mat-slide-toggle> -->
  <!-- <mat-slide-toggle formControlName='duel'>Duel</mat-slide-toggle> -->
</form>
