import { ErrorHandler } from '@angular/core';
export class GlobalErrorHandler {
    handleError(error) {
        console.error('Global Error', error);
    }
}
const ɵ0 = {}, ɵ1 = {};
export class AppModule {
}
export { ɵ0, ɵ1 };
