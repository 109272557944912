var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Khonsole } from 'app/khonsole';
import Dexie from 'dexie';
import { Subject } from 'rxjs';
import { DataFieldFactory } from './../model/data-field.model';
import { ChartFactory } from 'app/component/workspace/chart/chart.factory';
import { AccessS3 } from "../../accessS3";
import { environment } from 'environments/environment';
import { WorkspaceComponent } from 'app/component/workspace/workspace.component';
import { SavedPointsWrapper } from 'app/component/visualization/savedpoints/savedpoints.model';
import { DataService } from './data.service';
import { SavedPointsFormComponent } from 'app/component/visualization/savedpoints/savedpoints.form.component';
import { DatasetUpdates } from 'app/model/dataset-updates.model';
import { DatasetTableInfo } from 'app/model/dataset-table-info.model';
export class DatasetService {
    constructor() {
        this.loader$ = new Subject();
        this.loaderStatusUpdate = new Subject();
    }
    deleteAllDataSets() {
        return new Promise((resolve, reject) => {
            Dexie.getDatabaseNames().then((dbNames) => {
                Promise.all(dbNames.map(dbName => Dexie.delete(dbName))).then(resolve);
            });
        });
    }
    getDataset(database) {
        var w = window;
        Khonsole.log(`dataset.service.getDataset, looking for notitia-[${database}]`);
        return new Promise((resolve, reject) => {
            Dexie.exists('notitia-' + database).then(exists => {
                if (exists) {
                    Khonsole.log(`dataset.service.getDataset, found notitia-[${database}]`);
                    DatasetService.db = new Dexie('notitia-' + database);
                    DatasetService.db.open().then(v => {
                        w['oncoDatasetServiceDb'] = DatasetService.db;
                        DatasetService.db
                            .table('dataset')
                            .where({ name: database })
                            .first()
                            .then(result => {
                            resolve(result);
                        });
                    });
                }
                else {
                    Khonsole.log(`dataset.service.getDataset, did not find notitia-[${database}]`);
                    reject();
                }
            });
        });
    }
    updateDatasetTable(dbName, changes) {
        let promise = this.getDataset(dbName).then(dataset_result => {
            let dataset = dataset_result;
            Khonsole.log("==updateDatasetTable, dataset... ");
            Khonsole.dir(dataset);
            //return this.getDataset(dbName); // !!!! make this a WRITE
            if (dataset == null) {
                dataset = new DatasetTableInfo();
            }
            ;
            dataset.updateVersion = changes.version;
            dataset.defaultVizConfig = changes.defaultVizConfig;
            dataset.decorators =
                changes.decorators ? changes.decorators : [];
            dataset.tooltips =
                changes.tooltips ? changes.tooltips : [];
            dataset.tour =
                changes.tour ? changes.tour : null;
            dataset.table_ids =
                changes.table_ids ? changes.table_ids : null;
            dataset.navItems =
                changes.navItems ? changes.navItems : null;
            dataset.ux =
                changes.ux ? changes.ux : null;
            DatasetService.db = new Dexie('notitia-' + dbName);
            return DatasetService.db.open().then(v => {
                return DatasetService.db
                    .table('dataset')
                    .clear()
                    .then(sdfd => {
                    Khonsole.log("==dataset table cleared, now saving version " + changes.version);
                    return DatasetService.db
                        .table('dataset')
                        .add(dataset);
                });
            });
        });
        return promise;
    }
    // getMyUserId() {
    //   let email = window['storedAuthBits'].email; 
    //   return email;
    // }
    load(args, fromPrivate, grantee) {
        DataFieldFactory.setMutationFields(null);
        Khonsole.log('>>>>> IN datasetService.load, args = ...');
        Khonsole.dir(args);
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept-Encoding', 'gzip');
        if (args.hasOwnProperty('token')) {
            headers.append('zager', args.token);
        }
        const requestInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
            cache: 'default'
        };
        this.loaderStatusUpdate.next('Creating Local Database');
        // args.bucket = <owneremail>/<uid>
        let dbNameToSave = args.uid;
        Khonsole.log('>> dbNameToSave is [' + dbNameToSave + ']. We do NOT save the true owner name as part of the name.');
        // if (args.grantee) {
        //   let uidParts = args.bucket.split('/');
        //   let owner:string = uidParts[0];
        //   Khonsole.log('owner is ['+ owner+']')
        //   dbNameToSave = owner +'/'+uidParts[1];
        //   Khonsole.log('dbNameToSave is ['+ dbNameToSave+']')
        // }
        // Get updates first, if exist.
        Khonsole.log('About to do loadDatasetUpdates');
        this.loadDatasetUpdates(args.baseUrl + "updates.txt")
            .then(updates => {
            Khonsole.log('updates...');
            Khonsole.dir(updates);
            // Get manifest file, then go retrieve all the files listed inside it.
            let manifestRequest = args.manifest;
            if (fromPrivate == false) {
                // manifestRequest is string
                Khonsole.warn('Moving sttrcancer.org ref to AWS.');
                manifestRequest = manifestRequest.replace("oncoscape.v3.sttrcancer.org", "oncoscape-data.s3-us-west-2.amazonaws.com");
            }
            AccessS3.fetchSupportingPresigned(environment.envName, manifestRequest, requestInit, fromPrivate, args['grantee'], null, args.owner).then(response => {
                return response.json();
            })
                .then(response => {
                // Ensure all matrices are in schema
                response.files
                    .filter(v => !v.name.toLowerCase().indexOf('matrix'))
                    .map(v => {
                    return v.name
                        .replace('matrix ', '')
                        .replace('matrix_', '')
                        .toLowerCase()
                        .replace(/_/gi, '');
                })
                    .forEach(matrix => {
                    response.schema[matrix] = 'm';
                    response.schema[matrix + 'Map'] = 's';
                });
                // Ensure Patient Keys Are LCase
                if (response.schema.hasOwnProperty('patient')) {
                    response.schema.patient = response.schema.patient.toLowerCase();
                }
                if (response.schema.hasOwnProperty('sample')) {
                    response.schema.sample = response.schema.sample.toLowerCase();
                }
                Khonsole.log('Dexie exists test - pre');
                Dexie.exists('notitia-' + dbNameToSave).then(function (temp_exists_check) {
                    if (temp_exists_check)
                        Khonsole.log("Database exists");
                    else
                        Khonsole.log("Database doesn't exist");
                }).catch(function (error) {
                    Khonsole.error("Oops, an error occurred when trying to check database existance");
                });
                Khonsole.log('Dexie exists test - post');
                Dexie.exists('notitia-' + dbNameToSave).then(exists => {
                    // Add Table Defs
                    response.schema.pathways = '++, n';
                    response.schema.cohorts = '++, n';
                    response.schema.genesets = '++, n';
                    response.schema.preprocessing = '++, n';
                    // TODO: Temp Patch For Sample Meta Data
                    if (!response.schema.hasOwnProperty('sampleMeta')) {
                        response.schema.sampleMeta = 'key';
                    }
                    Khonsole.log('Load: about to create db ' + dbNameToSave + '...');
                    DatasetService.db = new Dexie('notitia-' + dbNameToSave);
                    Khonsole.log('db created for ' + dbNameToSave);
                    DatasetService.db.on('versionchange', function (event) { });
                    DatasetService.db.on('blocked', () => { });
                    let db = DatasetService.db;
                    db.version(1).stores(response.schema);
                    // TEMPNOTE: For anyone who tried the production V3, we need to signal changes.
                    // Put the changes in "version 2".
                    db.version(2).stores({
                        visSettings: "++id,visEnum" // also "settings", but we don't need to index on that.
                    });
                    db.version(3).stores({
                        eventsMeta: "++id,type,subtype"
                    });
                    db.version(4).stores({
                        miscMeta: "type,data" // For example, type:mutationTypes data:[array of KVPs] 
                    });
                    db.version(5).stores({
                        savedPoints: "name"
                    });
                    // NOTE from https://dexie.org/docs/Dexie/Dexie.open() ...
                    // Even though open() is asynchronous,
                    // you can already now start interact with the database.
                    // The operations will be pending until open() completes.
                    // If open() succeeds, the operations below will resume.
                    // If open() fails, the below operations below will fail and
                    // their catch() blocks be called.            
                    db.open().catch(function (err) {
                        Khonsole.error('Failed to open db: ' + (err.stack || err));
                    });
                    // MJ Moved existence test _after_ the version bumps.
                    if (exists) {
                        Khonsole.log('exists ABOUT TO CREATE updatePromisesResult');
                        this.getDataset(dbNameToSave)
                            .then(ds => {
                            let dataset = ds;
                            let datasetUpdateVersion = 0;
                            if (dataset && dataset.updateVersion) {
                                datasetUpdateVersion = dataset.updateVersion;
                            }
                            WorkspaceComponent.instance.updateVersion = datasetUpdateVersion;
                            DataService.instance.getPatientData('notitia-' + dbNameToSave, 'patient')
                                .then(patientDataResult => {
                                let sampleMap = patientDataResult.sampleMap;
                                let updatePromises = this.generateUpdatePromises(dbNameToSave, sampleMap, datasetUpdateVersion);
                                Promise.all(updatePromises).then(updatePromisesResult => {
                                    Khonsole.log('exists COMPLETED updatePromisesResult');
                                    this.loader$.next(args);
                                    return;
                                });
                            });
                        });
                        Khonsole.log("==if exists true, dont fall out.");
                    }
                    else {
                        Khonsole.log("==if exists is false.");
                        this.loaderStatusUpdate.next('Loading Metadata');
                        if (response.hasOwnProperty('version')) {
                            Khonsole.log('NOTexists');
                            const patient = Object.keys(response.patient).map(v => ({
                                ctype: 4 /* PATIENT */,
                                key: v
                                    .toLowerCase()
                                    .trim()
                                    .replace(/ /gi, '_'),
                                label: v.toLowerCase().trim(),
                                tbl: 'patient',
                                type: Array.isArray(response.patient[v]) ? 'STRING' : 'NUMBER',
                                values: response.patient[v]
                            }));
                            const sample = Object.keys(response.sample).map(v => ({
                                ctype: 1 /* SAMPLE */,
                                key: v
                                    .toLowerCase()
                                    .trim()
                                    .replace(/ /gi, '_'),
                                label: v.toLowerCase().trim(),
                                tbl: 'sample',
                                type: Array.isArray(response.sample[v]) ? 'STRING' : 'NUMBER',
                                values: response.sample[v]
                            }));
                            let events = [];
                            if (response.events) {
                                events = Object.keys(response.events).map(key => ({
                                    type: response.events[key],
                                    subtype: key
                                }));
                            }
                            const tbls = response.files
                                .map(file => {
                                const name = file.name.toLowerCase().trim();
                                switch (file.dataType) {
                                    case 'patient':
                                        return {
                                            tbl: 'patient',
                                            map: 'patientMap',
                                            label: 'Patient',
                                            ctype: 4 /* PATIENT */
                                        };
                                    case 'sample':
                                        return {
                                            tbl: 'sample',
                                            map: 'sampleMap',
                                            label: 'Sample',
                                            ctype: 1 /* SAMPLE */
                                        };
                                    case 'psmap':
                                        return null;
                                    case 'events':
                                        return {
                                            tbl: name,
                                            map: name + 'Map',
                                            label: name,
                                            ctype: 16384 /* EVENT */
                                        };
                                    case 'mut':
                                        return {
                                            tbl: name,
                                            map: name + 'Map',
                                            label: name,
                                            ctype: 64 /* MUTATION */
                                        };
                                    case 'matrix':
                                        return {
                                            tbl: name,
                                            map: name + 'Map',
                                            label: name,
                                            ctype: name == 'cnaarm' ? 2097152 /* GISTIC_THRESHOLD_BY_ARM */ : (name == 'rna' ? 32768 /* RNA */ : (name == 'cna' ? 32 /* GISTIC_THRESHOLD */
                                                : ((name.includes('gsva') || name.includes == ('gsea') || name.includes == ('pathways')) ? 524288 /* GENESET_SCORE */
                                                    : name.includes('fusion') ? 1048576 /* FUSIONS */ : 262144 /* MATRIX */)))
                                        };
                                }
                            })
                                .filter(v => v);
                            const updateVersionToWrite = WorkspaceComponent.instance.updatesIncomingData ? WorkspaceComponent.instance.updatesIncomingData.version : 0;
                            Khonsole.log(`=== updateVersionToWrite = ${updateVersionToWrite}.`);
                            const dataset = {
                                decorators: [],
                                defaultVizConfig: null,
                                events: events,
                                fields: patient.concat(sample),
                                name: dbNameToSave,
                                table_ids: [],
                                tables: tbls,
                                title: dbNameToSave,
                                tooltips: [],
                                tour: null,
                                updateVersion: updateVersionToWrite,
                                version: response.version
                            };
                            Khonsole.log('==about to save dataset table.');
                            // Add Dataset + Meta Info
                            let metaPromises = [];
                            metaPromises.push(db.table('dataset').add(dataset));
                            metaPromises.push(db.table('patientMeta').bulkAdd(patient));
                            metaPromises.push(db.table('sampleMeta').bulkAdd(sample));
                            if (events.length > 0) {
                                metaPromises.push(db.table('eventsMeta').bulkAdd(events));
                            }
                            Promise.all(metaPromises).then(abcd => {
                                this.loaderStatusUpdate.next('Metadata Loaded');
                                // Send a worker thread message for each table, to load, wait for all to complete.
                                Promise.all(response.files
                                    .filter(file => {
                                    Khonsole.log(`TEMPNOTE: file we should save: ${JSON.stringify(file)}.`);
                                    return (file.name !== 'manifest.json');
                                })
                                    .map(file => {
                                    if (file.dataType === '') {
                                        file.dataType = 'matrix';
                                    }
                                    return new Promise((resolve, reject) => {
                                        const loader = new Worker('/assets/loader.js');
                                        const onMessage = msgEvent => {
                                            const msgFromWebWorker = JSON.parse(msgEvent.data);
                                            let rawMsg = JSON.stringify(msgFromWebWorker);
                                            Khonsole.log(`RAW msg in load: ${rawMsg} .`);
                                            if (msgFromWebWorker.cmd === 'msg' || msgFromWebWorker.cmd === 'log') {
                                                Khonsole.log(`${msgFromWebWorker.cmd} MSGfromLoader: ${msgFromWebWorker.msg}.`);
                                            }
                                            if (msgFromWebWorker.cmd && msgFromWebWorker.cmd.toLowerCase() === 'terminate') {
                                                loader.removeEventListener('message', onMessage);
                                                loader.terminate();
                                                resolve();
                                            }
                                            else {
                                                if (msgFromWebWorker.cmd == 'msg') {
                                                    // Feedback to pop up in loader status dialog/pane
                                                    this.loaderStatusUpdate.next(msgFromWebWorker.msg);
                                                }
                                            }
                                        };
                                        loader.addEventListener('message', onMessage);
                                        let loadArgs = {
                                            env: environment.envName,
                                            cmd: 'load',
                                            version: response.version,
                                            grantee: grantee,
                                            owner: args['owner'],
                                            disease: args.disease,
                                            uid: dbNameToSave,
                                            baseUrl: args.baseUrl,
                                            file: file,
                                            token: args.token ? args.token : '',
                                            fromPrivate: fromPrivate
                                        };
                                        let loggingArgs = {
                                            env: loadArgs.env,
                                            file: file,
                                            token: loadArgs.token.substring(0, 15)
                                        };
                                        Khonsole.log(`MJ Posting load msg for ${JSON.stringify(loggingArgs)}.`);
                                        loader.postMessage(loadArgs);
                                    });
                                })).then(v => {
                                    // Khonsole.log('MJ args = ' + JSON.stringify(args));
                                    this.getDataset(dbNameToSave) //args.uid)
                                        .then(dataset => {
                                        WorkspaceComponent.instance.updateVersion = dataset.updateVersion;
                                        DataService.instance.getPatientData('notitia-' + dbNameToSave, 'patient')
                                            .then(patientDataResult => {
                                            let sampleMap = patientDataResult.sampleMap;
                                            Khonsole.log('update ready to go: initial database created.');
                                            Khonsole.log('NOTexists ABOUT TO CREATE updatePromisesResult');
                                            let updatePromises = this.generateUpdatePromises(dbNameToSave, sampleMap, 0); // Cannot be datasetUpdateVersion, because we just created the database.
                                            Promise.all(updatePromises).then(updatePromisesResult => {
                                                Khonsole.log('NOTexists COMPLETED updatePromisesResult');
                                                this.loader$.next(args);
                                                this.loaderStatusUpdate.next('Performing Initial Analysis');
                                            });
                                        });
                                    });
                                });
                            });
                        }
                        else { // V1 Data Mode
                            Khonsole.log('NOTE: loading data in V1 mode. '); //MJ
                            // Patient Meta Data
                            const fields = Object.keys(response.fields).map(v => ({
                                ctype: 4 /* PATIENT */,
                                key: v.toLowerCase(),
                                label: v.replace(/_/gi, ' '),
                                tbl: 'patient',
                                type: Array.isArray(response.fields[v]) ? 'STRING' : 'NUMBER',
                                values: response.fields[v]
                            }));
                            const events = Object.keys(response.events).map(key => ({
                                type: response.events[key],
                                subtype: key
                            }));
                            const tbls = response.files
                                .map(v => {
                                const dt = v.dataType.toLowerCase();
                                console.warn("MJ processing v, datatype==" + dt + ".");
                                const name = v.name
                                    .replace('matrix ', '')
                                    .replace('matrix_', '')
                                    .toLowerCase()
                                    .replace(/_/gi, '');
                                return dt === 'clinical'
                                    ? {
                                        tbl: 'patient',
                                        map: 'patientMap',
                                        label: 'Patient',
                                        ctype: 4 /* PATIENT */
                                    }
                                    : dt === 'events'
                                        ? {
                                            tbl: name,
                                            map: name + 'Map',
                                            label: name,
                                            ctype: 16384 /* EVENT */
                                        }
                                        : (dt === 'matrix' && name == "cna") // TBD: Support this in import
                                            ? {
                                                tbl: name,
                                                map: name + 'Map',
                                                label: name,
                                                ctype: 32 /* GISTIC_THRESHOLD */
                                            }
                                            : dt === 'matrix'
                                                ? {
                                                    tbl: name,
                                                    map: name + 'Map',
                                                    label: name,
                                                    ctype: 262144 /* MATRIX */
                                                }
                                                : dt === 'gistic'
                                                    ? {
                                                        tbl: name,
                                                        map: name + 'Map',
                                                        label: name,
                                                        ctype: 16 /* GISTIC */
                                                    }
                                                    : dt === 'gistic_threshold'
                                                        ? {
                                                            tbl: name,
                                                            map: name + 'Map',
                                                            label: name,
                                                            ctype: 32 /* GISTIC_THRESHOLD */
                                                        }
                                                        : dt === 'mut'
                                                            ? {
                                                                tbl: name,
                                                                map: name + 'Map',
                                                                label: name,
                                                                ctype: 64 /* MUTATION */
                                                            }
                                                            : dt === 'rna'
                                                                ? {
                                                                    tbl: name,
                                                                    map: name + 'Map',
                                                                    label: name,
                                                                    ctype: 32768 /* RNA */
                                                                }
                                                                : null;
                            })
                                .filter(v => v);
                            const updateVersionToWrite = WorkspaceComponent.instance.updatesIncomingData ? WorkspaceComponent.instance.updatesIncomingData.version : 0;
                            Khonsole.log(`=== V1 updateVersionToWrite = ${updateVersionToWrite}.`);
                            const dataset = {
                                name: dbNameToSave,
                                title: dbNameToSave,
                                version: 1,
                                events: events,
                                fields: fields,
                                tables: tbls,
                                updateVersion: updateVersionToWrite,
                                defaultVizConfig: null,
                                decorators: [],
                                tooltips: [],
                                tour: null,
                                table_ids: []
                            };
                            // Add Dataset + Meta Info
                            db.table('dataset').add(dataset);
                            db.table('patientMeta').bulkAdd(fields).then(() => {
                                this.loaderStatusUpdate.next('Metadata Loaded');
                                // Khonsole.log('MJ metadata loaded');
                                Promise.all(response.files
                                    .filter(file => file.name !== 'manifest.json')
                                    .map(file => {
                                    if (file.dataType === '') {
                                        file.dataType = 'matrix';
                                    }
                                    return new Promise((resolve, reject) => {
                                        const loader = new Worker('/assets/loader.js');
                                        const onMessage = msgEvent => {
                                            const msgFromLoader = JSON.parse(msgEvent.data);
                                            if (msgFromLoader.cmd && msgFromLoader.cmd.toLowerCase() === 'terminate') {
                                                loader.removeEventListener('message', onMessage);
                                                loader.terminate();
                                                resolve();
                                            }
                                            else {
                                                if (msgFromLoader.cmd && msgFromLoader.cmd == 'log') { // Feedback to pop up in loader status dialog/pane
                                                    Khonsole.log(msgFromLoader.msg);
                                                }
                                                else {
                                                    this.loaderStatusUpdate.next(msgFromLoader.msg);
                                                }
                                            }
                                        };
                                        loader.addEventListener('message', onMessage);
                                        loader.postMessage({
                                            cmd: 'load',
                                            disease: args.disease,
                                            uid: dbNameToSave,
                                            baseUrl: args.baseUrl,
                                            file: file,
                                            token: args.token ? args.token : ''
                                        });
                                    });
                                })).then(v => {
                                    // Khonsole.log('MJ outside promiseall of response.files.');
                                    this.loader$.next(args);
                                    this.loaderStatusUpdate.next('Performing Initial Analysis');
                                });
                            });
                        }
                        // ===== End after all update promises resolve.
                    }
                });
                Khonsole.log('MJ: After Dexie.exists, in load().');
            })
                .catch(err => {
                alert('An error happened while trying to access private data.');
                Khonsole.log('Private data error is...');
                Khonsole.log(err);
            });
            //      return this.loader$;
        });
        return this.loader$;
    }
    processCustomColorChanges(changes, dbName) {
        let customColors = changes.customColors;
        if (customColors) {
            // Change is of form: "Sample group!gtex": "#9ACD32".
            let keysOrdered = false;
            const dictionary = {};
            for (const key in customColors) {
                if (key.includes('!')) {
                    keysOrdered = true;
                    const [mainKey, subKey] = key.split('!');
                    const value = customColors[key];
                    if (!dictionary[mainKey]) {
                        dictionary[mainKey] = [];
                    }
                    dictionary[mainKey].push({ key: subKey, val: value });
                }
            }
            if (keysOrdered) {
                ChartFactory.writeCustomValueToLocalStorage(dbName, 'legendColorMisc', 'legendKeyOrder', JSON.stringify(dictionary));
            }
            Khonsole.log(dictionary);
            Khonsole.warn("===== customColors dictionary is not being used. =====");
            let customColorItems = Object.getOwnPropertyNames(customColors);
            customColorItems.forEach(colorItemName => {
                let colorValue = customColors[colorItemName];
                // NOTE: dbName was originally "self._config.database" where we first called readCustomValueFromLocalStorage.
                let existingCustomColor = ChartFactory.readCustomValueFromLocalStorage(dbName, 'legendColors', colorItemName);
                if (existingCustomColor) {
                    Khonsole.warn(`Custom color already exists for "${colorItemName}". It is ${existingCustomColor}}.`);
                }
                Khonsole.log(`Writing custom color "${colorValue}" for "${colorItemName}".`);
                ChartFactory.writeCustomValueToLocalStorage(dbName, 'legendColors', colorItemName, colorValue);
            });
        }
    }
    // Any promises needed to apply data in the updates.txt file downloaded for the dataset.
    generateUpdatePromises(dbName, sampleMap, datasetUpdateVersion) {
        let dummyPromise = new Promise((resolve, reject) => {
            Khonsole.log('== processing update dummyPromise');
            resolve(true);
        });
        let updatePromises = [dummyPromise];
        let changes = WorkspaceComponent.instance.updatesIncomingData;
        if (changes == null) {
            changes = new DatasetUpdates();
            changes.version = 0;
        }
        let mustUpdate = changes.version > datasetUpdateVersion;
        if (mustUpdate && WorkspaceComponent.instance.updatesIncomingData) {
            WorkspaceComponent.instance.updateVersion = changes.version;
            // For each type of database change seen, create a promise.
            console.log('incoming updates...');
            console.dir(changes);
            this.processCustomColorChanges(changes, dbName);
            // updating dataset table
            updatePromises.push(this.updateDatasetTable(dbName, changes));
            // savedPoints
            if (changes.savedPoints) {
                changes.savedPoints.map(sp => {
                    Khonsole.log(`Should (over)write saved points "${sp.name}", ${sp.points.length} points.`);
                    let wrapper = new SavedPointsWrapper();
                    wrapper.name = sp.name;
                    wrapper.savedPoints = SavedPointsFormComponent.SavedPointsFromUpdatePoints(sp.points, sampleMap);
                    wrapper.created = 12345; // ???
                    let spPromise = DataService.instance.putSavedPointsWrapper(dbName, wrapper);
                    updatePromises.push(spPromise);
                });
            }
        }
        return updatePromises;
    }
    dummySleep(ms, tag) {
        return new Promise((resolve, reject) => {
            Khonsole.log("=== update dummySleep START timeout " + tag);
            setTimeout(() => {
                Khonsole.log("=== update dummySleep COMPLETED timeout " + tag);
                resolve(true);
            }, ms);
        });
    }
    loadDatasetUpdates(updatesUrl) {
        return __awaiter(this, void 0, void 0, function* () {
            let self = this;
            WorkspaceComponent.instance.updatesIncomingData = null;
            let tempWorkspace = WorkspaceComponent.instance;
            Khonsole.log(`WS projectName? '${tempWorkspace.projectName}'`);
            if (tempWorkspace == null) {
                Khonsole.log('tempworkspace is null');
            }
            else {
                if (tempWorkspace.projectName == null) {
                    Khonsole.log('tempWorkspace.projectName is null. workspace is...');
                    Khonsole.dir(tempWorkspace);
                }
                else {
                }
            }
            let headers = {};
            headers['Content-Type'] = 'text/plain'; // 'application/json';
            //headers['Accept-Encoding'] = 'gzip'; //
            headers['Access-Control-Allow-Origin'] = '*';
            headers['Access-Control-Allow-Methods'] = 'GET';
            headers['Access-Control-Allow-Headers'] = 'content-type, content-encoding';
            const requestInit = {
                method: 'GET',
                headers: headers,
                mode: 'cors',
                cache: 'reload'
            };
            updatesUrl = updatesUrl.replace("oncoscape.v3.sttrcancer.org", "oncoscape-data.s3-us-west-2.amazonaws.com");
            let res = yield fetch(updatesUrl, requestInit)
                .then(response => {
                if (response.status == 200) {
                    response.text().then(function (text) {
                        let updates = {};
                        try {
                            updates = JSON.parse(text);
                        }
                        catch (ex) {
                            Khonsole.warn("****** Need to explain what is broken in updates.txt JSON. ***");
                            Khonsole.error(ex);
                            alert("Error parsing updates.txt: " + ex.message);
                        }
                        Khonsole.log('UPDATES Version = ' + updates.version);
                        let currentVersion = WorkspaceComponent.instance.updateVersion;
                        if (updates.version > currentVersion) {
                            Khonsole.log(`Need to upgrade from version ${currentVersion} to ${updates.version}.`);
                            WorkspaceComponent.instance.updatesIncomingData = updates;
                        }
                        else {
                            Khonsole.log(`Still on dataset version ${currentVersion}.`);
                        }
                    });
                }
                else {
                    Khonsole.log(`Updates request gave status ${response.status}.`);
                }
            });
            return res;
        });
    }
}
// Can I make these private?
DatasetService.API_PATH = '/assets/tcga/';
