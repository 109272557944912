
<form [formGroup]='form' novalidate>
<div class='form-group'>
  <mat-form-field class='form-field'>
      <mat-select placeholder='Data' formControlName='table'>
          <mat-option *ngFor='let option of dataOptions' [value]='option.label'>
              {{ option.label }}
          </mat-option>
      </mat-select>
  </mat-form-field>
</div>
<!--
<div class='form-group'>
<label class='center-block'><span class='form-label'>Sort</span>
  <select materialize='material_select'
      [compareWith]='byKey'
      [materializeSelectOptions]='colorOptions'
      formControlName='pointColor'>
      <option *ngFor='let option of colorOptions'
        [ngValue]='option'>{{option.label}}</option>
  </select>
</label>
</div>
-->
<!--
<div class='form-group'>
  <div class='switch'>
    <label>
      <input type='checkbox' formControlName='scatter'>
        <span class='lever'></span>
          Scatter
    </label>
  </div>
</div>
<div class='form-group'>
  <div class='switch'>
    <label>
      <input type='checkbox' formControlName='outliers'>
        <span class='lever'></span>
          Outliers
    </label>
  </div>
</div>
<div class='form-group'>
  <div class='switch'>
    <label>
      <input type='checkbox' formControlName='average'>
        <span class='lever'></span>
          Average
    </label>
  </div>
</div>
<div class='form-group'>
  <div class='switch'>
    <label>
      <input type='checkbox' formControlName='standardDeviation'>
        <span class='lever'></span>
          Standard Deviation
    </label>
  </div>
</div>
-->
</form>
  