import { GraphConfig } from 'app/model/graph-config.model';
import { DataDecorator } from 'app/model/data-map.model';
import { TooltipConfig } from 'ngx-bootstrap';
import { TooltipOverride, DatasetTour, TableIdList } from './dataset-table-info.model';
import { NavItem } from 'app/component/workspace/common-side-panel/nav-item'

export class DatasetUpdates {
  public codeBuild: number = 0;
  public version: number = 0;
  public title: string;
  public savedPoints = [];
  public defaultVizConfig:GraphConfig = null;
  public decorators:Array<DataDecorator> = [];
  public tooltips:Array<TooltipOverride> = [];
  public customColors = {}; // dictionary-like object, entries of form: "Sample group!gtex": "\"#9ACD32\"".  Note the "!" between legend and item names.
  public tour?:DatasetTour = null;
  public table_ids?: Array<TableIdList> = [];
  public navItems?: NavItem[] = [];
  public ux?: {
    disabled?: String[];
    hidden?: String[];
  }
}
