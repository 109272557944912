
<form [formGroup]='form' novalidate>
<!--
  <div class='form-group'>
    <label class='center-block'>Point Data
      <select materialize='material_select'
          [compareWith]='byKey'
          [materializeSelectOptions]='dataOptions'
          formControlName='molecularTable'>
          <option *ngFor='let option of dataOptions'>{{option.label}}</option>
      </select>
    </label>
  </div>
  <div class='form-group'>
    <label class='center-block'><span class='form-label'>Point Color</span>
      <select materialize='material_select'
          [compareWith]='byKey'
          [materializeSelectOptions]='colorOptions'
          formControlName='pointColor'>
          <option *ngFor='let option of colorOptions'
            [ngValue]='option'>{{option.label}}</option>
      </select>
    </label>
  </div>
  <div class='form-group'>
    <label class='center-block'><span class='form-label'>Point Size</span>
      <select materialize='material_select'
          [compareWith]='byKey'
          [materializeSelectOptions]='sizeOptions'
          formControlName='pointSize'>
          <option *ngFor='let option of sizeOptions'
            [ngValue]='option'>{{option.label}}</option>
      </select>
    </label>
  </div>
  <div class='form-group'>
    <label class='center-block'><span class='form-label'>Point Shape</span>
      <select materialize='material_select'
          [compareWith]='byKey'
          [materializeSelectOptions]='colorOptions' formControlName='pointShape'>
          <option *ngFor='let option of shapeOptions'
            [ngValue]='option'>{{option.label}}</option>
      </select>
    </label>
  </div>
   <div class='form-group'>
    <label class='center-block'><span class='form-label'>Display Dimension</span>
      <select materialize='material_select'
        [materializeSelectOptions]='dimensionOptions'
        formControlName='dimension'>
          <option *ngFor='let options of dimensionOptions'>{{options}}</option>
      </select>
    </label>
  </div>
  <div class='form-group'>
    <label class='center-block'>
      <span class='form-label'>τ (tau)</span>
      <p class='range-field'>
        <input type='range' min='0.01' max='0.1' step='.01' formControlName='tau' />
      </p>
    </label>
  </div>
  <div class='form-group'>
    <label class='center-block'>
      <span class='form-label'>Iterations</span>
      <p class='range-field'>
        <input type='range' min='1' max='10' step='1' formControlName='iterations' />
      </p>
    </label>
  </div>
  <div class='form-group'>
    <label class='center-block'>
      <span class='form-label'>X Grid Cells</span>
      <p class='range-field'>
        <input type='range' min='1' max='10' step='1' formControlName='xCells' />
      </p>
    </label>
  </div>
  <div class='form-group'>
    <label class='center-block'>
      <span class='form-label'>Y Grid Cells</span>
      <p class='range-field'>
        <input type='range' min='1' max='10' step='1' formControlName='yCells' />
      </p>
    </label>
  </div>
  -->
</form>
  