/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./pathways.form.component";
var styles_PathwaysFormComponent = [];
var RenderType_PathwaysFormComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PathwaysFormComponent, data: {} });
export { RenderType_PathwaysFormComponent as RenderType_PathwaysFormComponent };
export function View_PathwaysFormComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_PathwaysFormComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-pathways-form", [], null, null, null, View_PathwaysFormComponent_0, RenderType_PathwaysFormComponent)), i0.ɵdid(1, 49152, null, 0, i1.PathwaysFormComponent, [], null, null)], null, null); }
var PathwaysFormComponentNgFactory = i0.ɵccf("app-pathways-form", i1.PathwaysFormComponent, View_PathwaysFormComponent_Host_0, { config: "config" }, { configChange: "configChange" }, []);
export { PathwaysFormComponentNgFactory as PathwaysFormComponentNgFactory };
