
<form [formGroup]='form' novalidate>
  <!--
  <div class='form-group'>
    <label class='center-block'><span class='form-label'>Gene Color</span>
      <select materialize='material_select'
          [compareWith]='byKey'
          [materializeSelectOptions]='colorOptions'
          formControlName='pointColor'>
          <option *ngFor='let option of colorOptions' [ngValue]='option'>{{option.label}}</option>
      </select>
    </label>
  </div>
  <div class='form-group'>
    <label class='center-block'><span class='form-label'>Gene Size</span>
       <select materialize='material_select'
          [compareWith]='byKey'
          [materializeSelectOptions]='sizeOptions'
          formControlName='pointSize'>
          <option *ngFor='let option of sizeOptions' [ngValue]='option'>{{option.label}}</option>
      </select>
    </label>
  </div>
  <div class='form-group'>
    <label class='center-block'><span class='form-label'>Gene Shape</span>
      <select materialize='material_select'
          [compareWith]='byKey'
          [materializeSelectOptions]='sizeOptions'
          formControlName='pointShape'>
          <option *ngFor='let option of shapeOptions' [ngValue]='option'>{{option.label}}</option>
      </select>
    </label>
  </div>
  <div class='form-group'>
    <label class='center-block'><span class='form-label'>Chromosomes</span>
       <select materialize='material_select'
          [compareWith]='byKey'
          [materializeSelectOptions]='chromosomeOptions'
          formControlName='chromosomeOption'>
          <option *ngFor='let option of chromosomeOptions' [value]='option'>{{option}}</option>
      </select>
    </label>
  </div>
  -->
</form>
  