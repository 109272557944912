<div class="common-widget" ><!-- widget -->
  <table border="0" width="100%"><tr>
    <td cdkDragHandle>
    <mat-icon (click)='toggleHidden()' class="widget-icon" *ngIf="getExpanded()">expand_less</mat-icon>
    <mat-icon (click)='toggleHidden()' class="widget-icon" *ngIf="!getExpanded()">expand_more</mat-icon>
    <!--</button>-->
    <b class="widget-title">{{ getName() }}</b>
    </td><td align="right"><mat-icon (click)='clickSettings()' class="widget-icon" >settings</mat-icon></td>
  </tr></table>
  <div [id]="getContainerName()"  #parentOfSvgDiv class="common-widget common-widget-svgdiv">


<!--
    <md-expansion-panel  class="custom-header" hideToggle="true" #example>
      <md-expansion-panel-header>
        <span>
          Personal data
        </span>
        <md-panel-description>
          Type your name and age

        </md-panel-description>
      </md-expansion-panel-header>
  
      <md-form-field>
        <input mdInput placeholder="First name">
      </md-form-field>
  
      <md-form-field>
        <input mdInput placeholder="Age">
      </md-form-field>
    </md-expansion-panel> -->

  </div>
</div>
