import { Khonsole } from 'app/khonsole';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavItem } from './nav-item';
import { MatSelectChange } from '@angular/material';
import { ConfigureLegendPanelComponent } from '../configure-legend-panel/configure-legend-panel.component';
import { ColorbyForm } from '../configure-legend-panel/colorby-form/colorby-form.component';
import { color } from 'd3-color';
@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
   //  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent {
    @Input() items: NavItem[];
    @ViewChild('childMenu', { static: true }) public childMenu;

    constructor(public router: Router) {
    }

    public selectMenuAction(event, colorBy: string) {
        Khonsole.log('Menu is setting Color By: ' + colorBy);
        setTimeout(function() {
            // ASSUME view 0. Ignore: let viewId = config.graph == e.GraphEnum.GRAPH_A ? 0 : 1;
            // ===== time to emit colordec from graphPanelviz
            let clp: ConfigureLegendPanelComponent = window["oncoscapeConfigureLegendPanelRefs"][0];
            const value = colorBy;
            const table = colorBy.includes("=") ? colorBy.split("=")[0].split(":")[1] : null;
            clp.colorbyForm.forceFormUpdateAndEmit(value, table);
        }, 50);
    }
}
