/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./common-side-panel.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/cdk/drag-drop";
import * as i5 from "@angular/common";
import * as i6 from "./survival-widget.component";
var styles_SurvivalWidgetComponent = [i0.styles];
var RenderType_SurvivalWidgetComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SurvivalWidgetComponent, data: {} });
export { RenderType_SurvivalWidgetComponent as RenderType_SurvivalWidgetComponent };
function View_SurvivalWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "widget-icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleHidden() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["expand_less"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SurvivalWidgetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "widget-icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleHidden() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["expand_more"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_SurvivalWidgetComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { myId: 0 }), i1.ɵqud(402653184, 2, { parentOfSvgDiv: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "common-widget"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 13, "table", [["border", "0"], ["width", "100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 12, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "td", [["cdkDragHandle", ""], ["class", "cdk-drag-handle"]], null, null, null, null, null)), i1.ɵdid(6, 147456, null, 0, i4.CdkDragHandle, [i1.ElementRef, [2, i4.ɵb]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SurvivalWidgetComponent_1)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SurvivalWidgetComponent_2)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "b", [["class", "widget-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 3, "td", [["align", "right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "mat-icon", [["class", "widget-icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickSettings() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(15, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["settings"])), (_l()(), i1.ɵeld(17, 0, [[2, 0], ["parentOfSvgDiv", 1]], null, 0, "div", [["class", "common-widget common-widget-svgdiv"]], [[8, "id", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getExpanded(); _ck(_v, 8, 0, currVal_0); var currVal_1 = !_co.getExpanded(); _ck(_v, 10, 0, currVal_1); _ck(_v, 15, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getName(); _ck(_v, 12, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 15).inline; var currVal_4 = (((i1.ɵnov(_v, 15).color !== "primary") && (i1.ɵnov(_v, 15).color !== "accent")) && (i1.ɵnov(_v, 15).color !== "warn")); _ck(_v, 14, 0, currVal_3, currVal_4); var currVal_5 = _co.getContainerName(); _ck(_v, 17, 0, currVal_5); }); }
export function View_SurvivalWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "survival-widget", [], null, null, null, View_SurvivalWidgetComponent_0, RenderType_SurvivalWidgetComponent)), i1.ɵdid(1, 12828672, null, 0, i6.SurvivalWidgetComponent, [i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SurvivalWidgetComponentNgFactory = i1.ɵccf("survival-widget", i6.SurvivalWidgetComponent, View_SurvivalWidgetComponent_Host_0, {}, {}, []);
export { SurvivalWidgetComponentNgFactory as SurvivalWidgetComponentNgFactory };
