import { Khonsole } from 'app/khonsole';
import * as TWEEN from '@tweenjs/tween.js';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  Renderer2,
  Input
} from '@angular/core';
import { ModalService } from 'app/service/modal-service';
import { DataService } from './../../../service/data.service';
import { GraphConfig } from '../../../model/graph-config.model';
import { WorkspaceConfigModel } from 'app/model/workspace.model';
import { WorkspaceComponent } from '../workspace.component';
import { ChartScene } from './../chart/chart.scene';
import { VisualizationEnum } from 'app/model/enum.model';

import { FormControl, FormGroup } from '@angular/forms';
import { OncoData } from 'app/oncoData';
import { SavedPoints, SavedPointsWrapper } from 'app/component/visualization/savedpoints/savedpoints.model';
import { DataDecoratorTypeEnum } from 'app/model/data-map.model';


@Component({
  selector: 'app-workspace-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class InfoPanelComponent implements AfterViewInit, OnDestroy {
  static showDefault: EventEmitter<null> = new EventEmitter();
  static showMessage: EventEmitter<Array<{ key: string; value: string }>> = new EventEmitter();

  private _workspaceConfig: WorkspaceConfigModel;
  private _graphAConfig: GraphConfig;
  private _graphBConfig: GraphConfig;
  private _workspaceMessage: Array<{ key: string; value: string }> = [];
  public graphAInfo: Array<{ key: string; value: string }> = [];
  public graphBInfo: Array<{ key: string; value: string }> = [];
  public message: Array<{ key: string; value: string }> = [];

  selectedValue: string;
  radioSelected: any;
  savedpoints_view_details = []

  ngOnInit() {
    this.selectedValue = 'button1';
  }


  handleToggleButtonChange(event: Event, newSelectedValue: string) {
    this.selectedValue = newSelectedValue;
  }

  @Input()
  set workspaceConfig(config: WorkspaceConfigModel) {
    this._workspaceConfig = config;
    if (OncoData.instance.dataLoadedAction && OncoData.instance.dataLoadedAction.savedPointsWrappers) {
      this.savedpoints_view_details = OncoData.instance.dataLoadedAction.savedPointsWrappers.map((x, i) => {
        return ({ id: i, name: x.name })
      });
    } else {
      this.savedpoints_view_details = [{ id: 0, name: 'Not Loaded Yet' }];
    }
  }

  currentSavedPointsName:String = "";

  @Input()
  set graphAConfig(config: GraphConfig) {
    if (config === null) {
      return;
    }
    if (config.table === null) {
      return;
    } // { key: 'Data Set', value: config.datasetName },
    this.message = this.graphAInfo = [
      { key: 'Data Set:', value: config.database },
      { key: 'Table:', value: config.table.label },
      { key: 'Analysis:', value: config.label },
      { key: 'Cohort:', value: config.cohortName },
      { key: 'Gene Set:', value: config.markerName }
    ];
    const configShallowCopy = Object.assign({}, config);
    this._graphAConfig = configShallowCopy;

    if (this.ConfigAIsSavedPointsVisualization()){
      this.currentSavedPointsName = config["savedPointsName"];
    } else {
      this.currentSavedPointsName = "";
    }
    this.cd.detectChanges();
  }

  @Input()
  set graphBConfig(config: GraphConfig) {
    if (config === null) {
      return;
    }
    const configShallowCopy = Object.assign({}, config);
    this._graphBConfig = configShallowCopy;
  }

  private onShowDefault(): void {
    this.message = this.graphAInfo;
    this.cd.detectChanges();
  }

  private onShowMessage(value: Array<{ key: string; value: string }>): void {
    this.message = value;
    this.cd.detectChanges();
  }

  ConfigAIsSavedPointsVisualization() {
    Khonsole.log("in configAissavedpointsvisualization.")
    let configA: any = null;
    if (ChartScene.instance &&
      ChartScene.instance.views &&
      ChartScene.instance.views[0] &&
      ChartScene.instance.views[0].chart) {
      configA = ChartScene.instance.views[0].chart.getConfig();
    }
    Khonsole.log("in ConfigAIsSavedPointsVisualization, force true.")
    if (configA && configA.isScatterVisualization && configA.visualization == VisualizationEnum.SAVED_POINTS) {
      return true;
    } else {
      return false;
    }
  }

  DataLoadedActionHasMultipleSavedPoints(){
    let dla = OncoData.instance.dataLoadedAction;
    if (dla && dla.savedPointsWrappers && dla.savedPointsWrappers.length ) {
      return OncoData.instance.dataLoadedAction.savedPointsWrappers.length > 1;
    } else {
      return false;
    }
  }

  isCurrentButton(x: any) {
    return (this.currentSavedPointsName === x.name); // ? true : false);
  }

  viewButtonsClick(x:any) {
    if (x){ // Only one of the view buttons will be non-null.
      if (this.ConfigAIsSavedPointsVisualization()) {
        Khonsole.log("saved points visualization.")
        let chart = ChartScene.instance.views[0].chart;
        let gc = chart.getConfig();
        Khonsole.log("==== always tru viewControlsClick.")
        Khonsole.dir(x);
        gc["savedPointsName"] = x.name;
        let colorDec = chart.decorators.find(x => x.type == DataDecoratorTypeEnum.COLOR);
        if (colorDec){
          window["carryoverColorDec"] = JSON.parse(JSON.stringify(colorDec));; // Make this carry over to the new chart results, after compute call.
          console.warn("colordec ===  set in info-panel.component.ts")
          console.dir(colorDec);
        }
        WorkspaceComponent.instance.graphPanelSetConfig(gc);
      } else {
        Khonsole.warn("viewButtonsClick: not saved points visualization, should not have been visible.")
      }
    }
  }


  ngOnDestroy(): void {}
  ngAfterViewInit(): void {}
  constructor(
    private cd: ChangeDetectorRef,
    private dataService: DataService,
    public ms: ModalService,
    public renderer: Renderer2
  ) {
    InfoPanelComponent.showDefault.subscribe(this.onShowDefault.bind(this));
    InfoPanelComponent.showMessage.subscribe(this.onShowMessage.bind(this));
  }

  checkDatasetisProject():Boolean {
    return WorkspaceComponent.instance.projectName != null;
  }

  getRedirectReloadUrl(){
    return WorkspaceComponent.instance.urlToRedirectToProject(WorkspaceComponent.instance.projectName)
  }

  // The name for a bookmark, if a user should drag this link into the bookmark bar.
  // Sniff if it ends in"_v[0-9]*" and if so, copy that to the front and truncate.
  getRedirectReloadName() {
    let projectName = WorkspaceComponent.instance.projectName;
    let bookmarkName = projectName

    // Sniff if it ends in "_v[0-9]*"
    let re = /_v[0-9]*$/;
    let match = projectName.match(re);
    if (match) {
      // Trim the "_", return version number and full name.
      bookmarkName = match[0].substring(1) + " " + projectName
    } else {
      // Sniff if it ends in "[0-9]*"
      let re = /[0-9]*$/;
      let match = projectName.match(re);
      if (match) {
        bookmarkName = match[0] + " " + projectName
      }
    }

    return bookmarkName;
  }
}
