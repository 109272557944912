import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

import { Khonsole } from 'app/khonsole';
import { CollectionTypeEnum } from 'app/model/enum.model';
import { ChartSelection } from '../../../model/chart-selection.model';
import { SelectionToolConfig } from 'app/model/selection-config.model';
import { ScatterConfigModel } from '../../visualization/scatter/scatter.model';
import { UmapConfigModel } from '../../visualization/umap/umap.model';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import {  OnChanges, SimpleChanges } from '@angular/core';// tslint:disable-next-line:max-line-length
import { LinearDiscriminantAnalysisConfigModel } from 'app/component/visualization/lineardiscriminantanalysis/lineardiscriminantanalysis.model';
import { PathwaysConfigModel } from 'app/component/visualization/pathways/pathways.model';
import { TimelinesConfigModel } from 'app/component/visualization/timelines/timelines.model';
import { DataField } from 'app/model/data-field.model';
import { DirtyEnum, GraphEnum, VisualizationEnum } from 'app/model/enum.model';
import { GraphData } from 'app/model/graph-data.model';
import { DataService } from 'app/service/data.service';
import { WorkspaceConfigModel } from '../../../model/workspace.model';
import { HistogramConfigModel } from '../../visualization/histogram/histogram.model';
import { DataTable } from '../../../model/data-field.model';
import { DataDecorator, DataDecoratorTypeEnum, DataDecoratorValue,
  LegendFilter } from '../../../model/data-map.model';
import { EntityTypeEnum, PanelEnum, WorkspaceLayoutEnum } from '../../../model/enum.model';
import { GraphConfig } from '../../../model/graph-config.model';
import { ModalService } from '../../../service/modal-service';
import { BoxWhiskersConfigModel } from '../../visualization/boxwhiskers/boxwhiskers.model';
import { ChromosomeConfigModel } from '../../visualization/chromosome/chromosome.model';
import { DaConfigModel } from '../../visualization/da/da.model';
import { DeConfigModel } from '../../visualization/de/de.model';
import { DendogramConfigModel } from '../../visualization/dendogram/dendogram.model';
import { DictionaryLearningConfigModel } from '../../visualization/dictionarylearning/dictionarylearning.model';
import { EdgeConfigModel } from '../../visualization/edges/edges.model';
import { FaConfigModel } from '../../visualization/fa/fa.model';
import { FastIcaConfigModel } from '../../visualization/fastica/fastica.model';
import { GenomeConfigModel } from '../../visualization/genome/genome.model';
import { HazardConfigModel } from '../../visualization/hazard/hazard.model';
import { HeatmapConfigModel } from '../../visualization/heatmap/heatmap.model';
import { HicConfigModel } from '../../visualization/hic/hic.model';
import { IsoMapConfigModel } from '../../visualization/isomap/isomap.model';
import { LdaConfigModel } from '../../visualization/lda/lda.model';
import { LinkedGeneConfigModel } from '../../visualization/linkedgenes/linkedgenes.model';
import { LocalLinearEmbeddingConfigModel } from '../../visualization/locallinearembedding/locallinearembedding.model';
import { MdsConfigModel } from '../../visualization/mds/mds.model';
import { SavedPointsConfigModel } from '../../visualization/savedpoints/savedpoints.model';
// tslint:disable-next-line:max-line-length
import { MiniBatchDictionaryLearningConfigModel } from '../../visualization/minibatchdictionarylearning/minibatchdictionarylearning.model';
import { MiniBatchSparsePcaConfigModel } from '../../visualization/minibatchsparsepca/minibatchsparsepca.model';
import { NmfConfigModel } from '../../visualization/nmf/nmf.model';
import { ParallelCoordsConfigModel } from '../../visualization/parallelcoords/parallelcoords.model';
import { PcaConfigModel } from '../../visualization/pca/pca.model';
import { PcaIncrementalConfigModel } from '../../visualization/pcaincremental/pcaincremental.model';
import { PcaKernalConfigModel } from '../../visualization/pcakernal/pcakernal.model';
import { PcaSparseConfigModel } from '../../visualization/pcasparse/pcasparse.model';
import { PlsConfigModel } from '../../visualization/pls/pls.model';
// tslint:disable-next-line:max-line-length
import { QuadradicDiscriminantAnalysisConfigModel } from '../../visualization/quadradicdiscriminantanalysis/quadradicdiscriminantanalysis.model';
import { SomConfigModel } from '../../visualization/som/som.model';
import { SpectralEmbeddingConfigModel } from '../../visualization/spectralembedding/spectralembedding.model';
import { SurvivalConfigModel } from '../../visualization/survival/survival.model';
import { TruncatedSvdConfigModel } from '../../visualization/truncatedsvd/truncatedsvd.model';
import { TsneConfigModel } from '../../visualization/tsne/tsne.model';
import { PlsSvdConfigModel } from '../../visualization/pls-svd/pls-svd.model';
import { PlsRegressionConfigModel } from '../../visualization/plsregression/plsregression.model';
import { PlsCanonicalConfigModel } from '../../visualization/plscanonical/plscanonical.model';
import { CCAConfigModel } from '../../visualization/cca/cca.model';
import { LinearSVCConfigModel } from '../../visualization/linearsvc/linearsvc.model';
import { LinearSVRConfigModel } from '../../visualization/linearsvr/linearsvr.model';
import { NuSVRConfigModel } from '../../visualization/nusvr/nusvr.model';
import { SVRConfigModel } from '../../visualization/svr/svr.model';
import { NuSVCConfigModel } from '../../visualization/nusvc/nusvc.model';
import { OneClassSVMConfigModel } from '../../visualization/oneclasssvm/oneclasssvm.model';
import { MatTabChangeEvent } from '@angular/material';
import { DatasetDescription } from 'app/model/dataset-description.model';
import { Preprocessing } from 'app/model/preprocessing.model';
import { ProteinConfigModel } from 'app/component/visualization/protein/protein.model';
import { ChartScene } from '../chart/chart.scene';
import { OncoData } from 'app/oncoData';

declare var $: any;

@Component({
  selector: 'graph-widgets-panel',
  templateUrl: './graph-widgets-panel.component.html',
  styleUrls: ['./graph-widgets-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class GraphWidgetsPanelComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input()
  config: GraphConfig;

  showConfig() {
    if (this.config == null) {
      return "config none";
    } else {
      return JSON.stringify(this.config).replace("/,/g", ",<br>");
    }
  }

  showLegends() {
    if (this.data == null || this.data.legends == null) {
      return "legends none";
    } else {
      return JSON.stringify(this.data.legends).replace("/,/g", ",<br>");
    }
  }

  showDataDescription() {
    if (this.datasetDescription == null) {
      return "data desc none";
    } else {
      return JSON.stringify(this.datasetDescription).replace("/,/g", ",<br>");
    }
  }

  readyForWidgets = false;

  //

  @Input()
  datasetDescription: DatasetDescription;

  @Input()
  data: GraphData;


  ngOnChanges(changes: SimpleChanges): void {
    console.log("===changes===");
    console.dir(changes);
    if(this.data && this.datasetDescription && this.config){
      this.readyForWidgets = true;
    }
    /*
    if (changes.myInput) {
      const prevValue: string = changes.myInput.previousValue;
      const currValue: string = changes.myInput.currentValue;

      console.log(`myInput changed from ${prevValue} to ${currValue}`);

      // Perform your check or operation here
      this.validateInput(currValue);
    }
    */
  }

  validateInput(value: string): void {
    // Your validation logic here
    
  }

  ngAfterViewInit(): void {
    console.log("===ngAfterViewInit===");  
  }

  ngOnInit(): void { 
    console.log ("===ngOnInit for GraphWidgetsPanelt===");
  }

  ngOnDestroy(): void { }

  constructor(private ms: ModalService, private cd: ChangeDetectorRef, private dataService: DataService) {
    console.log('GraphWidgetsPanelComponent constructor');
  }

}
