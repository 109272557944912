<div class="dashboard-modal-panel">
    <a href='#' class='modalClose' (click)='closeClick()'></a>
    <h1 class='dashboard-h1'> Cohort Dashboard </h1>
    <div #comparisonContainer >
                <span class='swatchCohortCompare' [style.background-color]='"blue"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Cohort A: <mat-form-field>
            <mat-select placeholder='Choose a cohort...' [(ngModel)]='cohortA' [compareWith]="compareCohortsById">
                <mat-option *ngFor="let option of cohorts" [value]="option"   >
                    {{option.n}}
                </mat-option>
            </mat-select>
        </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span class='swatchCohortCompare' [style.background-color]='"lightblue"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Cohort B: <mat-form-field>
            <mat-select placeholder='Choose a second cohort...' [(ngModel)]='cohortB' [compareWith]="compareCohortsById">
                <mat-option *ngFor="let option of cohortsAndNotA" [value]="option"   >
                    {{option.n}}
                </mat-option>
            </mat-select>
        </mat-form-field><div>-----</div>


        <app-cohort-comparison  [cohortA]="cohortA" [cohortB]="cohortB" [cohorts]="cohorts" >
        </app-cohort-comparison>

    </div>
    <div #chartContainer style='padding-bottom:200px;'><!--
        Cohort: 
        <mat-form-field>
        <mat-select placeholder='All Patients' [(ngModel)]='cohort' (selectionChange)="onCohortChange($event)">
            <mat-option *ngFor="let option of cohorts" [value]="option">
            {{option.n}}
            </mat-option>
        </mat-select>
        </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Measure:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Compare With:
        <mat-form-field>
        <mat-select placeholder='' [(ngModel)]='cohortSecondary' (selectionChange)="onCohortSecondaryChange($event)">
            <mat-option *ngFor="let option of cohortsAndNone" [value]="option">
            {{option.n}}
            </mat-option>
        </mat-select>
        </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <br /> -->
    </div>
</div>

