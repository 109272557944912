import { Khonsole } from 'app/khonsole';
import { LegendOptionEnum } from 'app/model/enum.model';

export class LegendOptionsProcessor {

  private registeredOptionsMapping: Map<LegendOptionEnum, (value: any) => void> = new Map();

  /**
   * @description update the option with the given value
   * @param option the option to update
   * @param value the new value for the option
   */
  updateOption(option: LegendOptionEnum, value: any) {
    if (this.registeredOptionsMapping.has(option)) {
      this.registeredOptionsMapping.get(option)(value);
    } else {
      Khonsole.warn(`LegendOptionsProcessor: ${option} is defined in the config but does not have a registered handler.`);
    }
  }

  /**
   * @description register a callback for a specific option
   * @param option the option to register
   * @param handler the callback to call when the option is set. Takes the new value for the option as parameter
   */
  registerHandler(option: LegendOptionEnum, handler: (value: any) => void) {
    this.registeredOptionsMapping.set(option, handler);
  }

  /**
   * @description The list of registered options
   */
  get registeredOptions(): LegendOptionEnum[] {
    return Array.from(this.registeredOptionsMapping.keys());
  }
}


export interface HasLegendOptions {
  legendOptionsProcessor: LegendOptionsProcessor;
}

// type gaurd for HasMoreLegendOptions
export function isHasLegendOptions(obj: any): obj is HasLegendOptions {
  return obj && obj.legendOptionsProcessor && obj.legendOptionsProcessor instanceof LegendOptionsProcessor;
}
