
  <form [formGroup]='form' novalidate>
    <mat-form-field class='form-field'>
      <mat-select placeholder='Data' formControlName='table' [compareWith]='byTbl'>
          <mat-option *ngFor='let option of dataOptions' [value]='option'>
              {{ option.label }}
          </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class='form-field'>
      <mat-select placeholder='Method' formControlName='method'>
          <mat-option *ngFor='let option of methodOptions' [value]='option.value'>
              {{ option.label }}
          </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class='form-field'>
      <mat-select placeholder='Distance' formControlName='dist'>
          <mat-option *ngFor='let option of distanceOptions' [value]='option.value'>
              {{ option.label }}
          </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-slide-toggle formControlName='order'>Sort Dendo</mat-slide-toggle>
    <mat-slide-toggle formControlName='removeWhitespace'>Remove Whitespace</mat-slide-toggle>
  </form>
  