<main class="noselect">
    <div *ngIf="showChromeMessage"  class="chrome-message" >
        <div>Sorry, but Oncoscape is only supported in the Chrome browser.</div>
        <div ><br /></div>
        <div class="chrome-message-subtle">For more info, contact <a href="mailto:contact-onc@fredhutch.org">contact-onc@fredhutch.org</a>.</div>
    </div>
    <!-- Rest of your component's template -->
    <div *ngIf="showChromeMessage == false">
        <router-outlet></router-outlet>
    </div>
</main>