
  <form [formGroup]='form' novalidate>
    <div class='form-group'>
      <label class='center-block'><span class='form-label'>Data</span>
        <select materialize='material_select'
            formControlName='table'>
            <option *ngFor='let option of dataOptions' [value]='option'>{{option.label}}</option>
        </select>
      </label>
    </div>
    <div class='form-group'>
    <label class='center-block'><span class='form-label'>Method</span>
      <select materialize='material_select'
          formControlName='method'>
          <option *ngFor='let option of methodOptions' [value]='option.value'>{{option.label}}</option>
      </select>
    </label>
  </div>
    <div class='form-group'>
    <label class='center-block'><span class='form-label'>Distance</span>
      <select materialize='material_select'
          formControlName='dist'>
          <option *ngFor='let option of distanceOptions' [value]='option.value'>{{option.label}}</option>
      </select>
    </label>
  </div>
  <div class='form-group'>
    <div class='switch'>
      <label class='center-block'><span class='form-label'>Sort Dendo</span>
        <input type='checkbox' formControlName='order'>
        <span class='lever'></span>
      </label>
    </div>
  </div>
  </form>
  