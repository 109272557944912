import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { OncoData } from 'app/oncoData';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ContentDialog } from './component/content-dialog/content-dialog';
import { Khonsole } from 'app/khonsole';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  showChromeMessage = false;
  private _oncoData:OncoData;
  static myApp;
  k:Khonsole;

  constructor(private store: Store<any>, public dialog: MatDialog) {
    this._oncoData = new OncoData();
  }

  ngOnInit() {
    AppComponent.myApp = this;
    this.showChromeMessage = !this.isChrome();
  }  

  isChrome(): boolean {
    const userAgent = window.navigator.userAgent;
    const isChromium = userAgent.indexOf('Chrome') > -1;
    const isEdge = userAgent.indexOf('Edg') > -1;

    return isChromium && !isEdge;
  }

  public showContentDialog(title:string, guts:string){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      title: title,
      guts: guts
    };

    const dialogRef = AppComponent.myApp.dialog.open(ContentDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      Khonsole.log(`Dialog result: ${result}`);
    });
  
  }
}
