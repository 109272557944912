<!-- Color By Blue Box -->
<mat-expansion-panel style="padding-top: 0px !important" [expanded]="true">
  <mat-expansion-panel-header>
    <mat-panel-title
      class="legendHeader"
      style="
        font-size: 9px !important;
        padding-top: 4px !important;
        padding-bottom: 4px !important;
      "
      >Color</mat-panel-title
    >
  </mat-expansion-panel-header>

  <!-- TODO: figure out how we can add *ngIf="shouldShowLegendOption(config, 'color')" without breaking the ViewChild -->
  <colorby-form
    #colorbyformid
    id="colorby-form-id"
    (onApplyColorbyMatSelectChange)="applyColorbyFormChoice($event)"
    [colorOptions]="colorOptions"
    [config]="config"
  >
  </colorby-form>
</mat-expansion-panel>


<!-- More Legend Options -->
<mat-expansion-panel [expanded]="false">
  <mat-expansion-panel-header>
    <mat-panel-title
      class="legendHeader"
      style="
        font-size: 9px !important;
        padding-top: 4px !important;
        padding-bottom: 4px !important;
      "
      >More Legend Options</mat-panel-title
    >
</mat-expansion-panel-header>

  <!-- Marker Base Size -->
  <div *ngIf="shouldShowLegendOption(config, 'markerBaseSize')">
    <mat-label>Marker Base Size</mat-label>
    <br />
    <mat-slider
      min="1"
      max="25"
      value="{{ defaultMarkerBaseSize() }}"
      (input)="setBaseSizeOption($event)"
      thumbLabel="true"
    ></mat-slider>
    <br />
  </div>

  <!-- Marker Opacity -->
  <div *ngIf="shouldShowLegendOption(config, 'markerOpacity')">
    <mat-label>Marker Opacity</mat-label>
    <br />
    <mat-slider
      min="0.5"
      max="1"
      step="0.01"
      value="{{ defaultMarkerOpacity() }}"
      (input)="setOpacityOption($event)"
      thumbLabel="true"
    ></mat-slider>
    <br />
  </div>

  <!-- Shape -->
  <mat-form-field
    class="form-field"
    *ngIf="shouldShowLegendOption(config, 'shape')"
  >
    <mat-select
      placeholder="Shape Options"
      (selectionChange)="setShapeOption($event)"
      [(value)]="shapeSelected"
      [compareWith]="byKey"
    >
      <mat-option *ngFor="let option of shapeOptions" [value]="option">
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Size -->
  <mat-form-field
    class="form-field"
    *ngIf="shouldShowLegendOption(config, 'size')"
  >
    <mat-select
      placeholder="Size Options"
      (selectionChange)="setSizeOption($event)"
      [(value)]="sizeSelected"
      [compareWith]="byKey"
    >
      <mat-option *ngFor="let option of sizeOptions" [value]="option">
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Geneset Overlay -->
  <div *ngIf="shouldShowLegendOption(config, 'genesetOverlay')">
    <mat-form-field class="form-field">
      <mat-select
        #firstGenesetOverlaySelectComponent
        placeholder="First Geneset Overlay"
        (selectionChange)="setGenesetOverlay(1, $event)"
        [(value)]="firstGenesetOverlaySelected"
        [compareWith]="byN"
      >
        <button
          mat-button
          style="color: #1e88e5; width: 100%"
          (click)="customizeGenesets(1)"
        >
          <mat-icon
            class="material-icons md-18"
            style="transform: translate(0px, 2px); margin-right: 0px"
            >settings</mat-icon
          >Modify List
        </button>
        <mat-option *ngFor="let option of genesetOptions" [value]="option">
          {{ formatGenesetOverlayForDropdown(option) }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      class="form-field"
      *ngIf="shouldShowLegendOption(config, 'genesetOverlay')"
    >
      <mat-select
        #secondGenesetOverlaySelectComponent
        placeholder="Second Geneset Overlay"
        (selectionChange)="setGenesetOverlay(2, $event)"
        [(value)]="secondGenesetOverlaySelected"
        [compareWith]="byN"
      >
        <button
          mat-button
          style="color: #1e88e5; width: 100%"
          (click)="customizeGenesets(2)"
        >
          <mat-icon
            class="material-icons md-18"
            style="transform: translate(0px, 2px); margin-right: 0px"
            >settings</mat-icon
          >Modify List
        </button>
        <mat-option *ngFor="let option of genesetOptions" [value]="option">
          {{ formatGenesetOverlayForDropdown(option) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-expansion-panel>
