import { Khonsole } from 'app/khonsole';
import { Preprocessing } from '../../../../model/preprocessing.model';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material';
import { GraphConfig } from 'app/model/graph-config.model';
import { DirtyEnum, PanelEnum } from '../../../../model/enum.model';
import { Cohort } from '../../../../model/cohort.model';
import { GeneSet } from '../../../../model/gene-set.model';
import { Pathway } from '../../../../model/pathway.model';

@Component({
  selector: 'app-graph-panel-data',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'graph-panel-data.component.html',
})
export class GraphPanelDataComponent {
  @ViewChild('cohortSelectComponent', { static: false })
  cohortSelectComponent: MatSelect;
  @ViewChild('genesetSelectComponent', { static: false })
  genesetSelectComponent: MatSelect;
  @ViewChild('preprocessingSelectComponent', { static: false })
  preprocessingSelectComponent: MatSelect;
  @ViewChild('pathwaySelectComponent', { static: false })
  pathwaySelectComponent: MatSelect;

  @Output()
  showPanel: EventEmitter<PanelEnum> = new EventEmitter();
  @Output()
  configChange: EventEmitter<GraphConfig> = new EventEmitter();
  public cohortOptions = [];
  public genesetOptions = [];
  public preprocessingOptions = [];
  public pathwayOptions = [];
  // public proteinOptions = [];
  public cohortSelected: Cohort;
  public genesetSelected: GeneSet;
  public preprocessingSelected: Preprocessing;
  public pathwaySelected: Pathway;
  public cohortOptionsVisible = true;
  public genesetOptionsVisible = true;
  public pathwayOptionsVisible = true;

  private _config: GraphConfig;
  public get config(): GraphConfig {
    return this._config;
  }
  @Input()
  public set config(config: GraphConfig) {
    this._config = config;
  }
  @Input()
  set pathways(v: Array<Pathway>) {
    this.pathwayOptions = v;
    this.pathwaySelected = this.pathwayOptions[1];
  }
  // @Input()
  // set proteins(v: Array<Protei>) {
  //   this.proteinOptions = v;
  //   this.proteinSelected = this.proteinOptions[0];
  // }
  @Input()
  set cohorts(v: Array<Cohort>) {
    this.cohortOptions = v;
    let self = this;
    if(v.length > 0 && this._config.cohortName) {
      this.cohortSelected = v.find(e => e.n == self._config.cohortName)
    } else {
    this.cohortSelected = this.cohortOptions[0];
    }
  }
  @Input()
  set genesets(v: Array<GeneSet>) {
    this.genesetOptions = [{ n: 'All Genes', g: [] }, ...v];
    let self = this;
    if(v.length > 0) {
      if(this._config.markerName) {
        this.genesetSelected = v.find(e => e.n == self._config.markerName)
        if(this.genesetSelected == null) {
          Khonsole.error(`ERROR: Could not find geneset ${self._config.markerName} in graph panel data.`);
          this.genesetSelected = this.genesetOptions[1];
        }
      } else {
        // No geneset (markerName) in our config, but there's
        // something available beyond All Genes, so let's take the first one.
        this.genesetSelected = this.genesetOptions[1];
      }
    } else {
      // No genesets passed, so we are forced to use All Genes.
      this.genesetSelected = this.genesetOptions[0];
    }
  }
  @Input()
  set preprocessings(v: Array<GeneSet>) {
    this.preprocessingOptions = [Preprocessing.getUndefined(), ...v];
    this.preprocessingSelected = this.preprocessingOptions[0];
  }

  public customizeCohorts(e: any): void {
    if (this.cohortSelectComponent.panelOpen) {
      this.cohortSelectComponent.toggle();
    }
    this.showPanel.emit(PanelEnum.COHORT);
  }
  public customizeGenesets(): void {
    if (this.genesetSelectComponent.panelOpen) {
      this.genesetSelectComponent.toggle();
    }
    this.showPanel.emit(PanelEnum.GENESET);
  }
  public customizePathways(): void {
    if (this.pathwaySelectComponent.panelOpen) {
      this.pathwaySelectComponent.toggle();
    }
    this.showPanel.emit(PanelEnum.PATHWAYS);
  }
  public customizePreprocessing(): void {
    if (this.preprocessingSelectComponent.panelOpen) {
      this.preprocessingSelectComponent.toggle();
    }
    this.showPanel.emit(PanelEnum.PREPROCESSING);
  }

  public formatGenesetForDropdown(gs:any) {
      if(gs.n == 'All Genes') {
        return 'All Genes (Extremely Long)'
      } else {
        return `${gs.n} (${gs.g.length} genes)`;
      }
  }

  byName(p1: any, p2: any) {
    if (p2 == null) {
      return false;
    }
    return p1.n === p2.n;
  }

  public setPathwayOption(e: MatSelectChange): void {
    if (this.config.pathwayName === e.value.n) {
      return;
    }
    this.config.pathwayUri = e.value.uri;
    this.config.pathwayName = e.value.n;
    this.config.dirtyFlag = DirtyEnum.LAYOUT;
    this.configChange.emit(this.config);
  }
  public setGenesetOption(e: MatSelectChange): void {
    if (this.config.markerName === e.value.n) {
      return;
    }
    if  (e.value.g.length == 0 && e.value.n['startsWith']('All Genes')) {
      if (confirm('Are you sure? The "All Genes" option will use an enormous amount of memory and might crash the page. Continue?') == false) {
        return;
      }
    }
    this.config.markerName = e.value.n;
    this.config.markerFilter = e.value.g;
    this.config.dirtyFlag = DirtyEnum.LAYOUT;
    this.configChange.emit(this.config);
  }
  public setCohortOption(e: MatSelectChange): void {
    if (this.config.cohortName === e.value.n) {
      return;
    }
    this.config.cohortName = e.value.n;
    this.config.patientFilter = e.value.pids;
    this.config.sampleFilter = e.value.sids;
    this.config.dirtyFlag = DirtyEnum.LAYOUT;
    this.configChange.emit(this.config);
  }

  public setPreprocessingOption(e: MatSelectChange): void {
    if (this.config.preprocessing.n === e.value.n) {
      return;
    }
    this.config.preprocessing = e.value;
    this.config.dirtyFlag = DirtyEnum.LAYOUT;
    this.configChange.emit(this.config);
  }
  constructor(private cd: ChangeDetectorRef) {}
}
