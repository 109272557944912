<div class='panel'>
  <div>
    <a href='#' class='modalClose' (click)='closeClick()'>
    </a>
    <h1 class='panel-header'>Cohorts</h1>
    <h2 class='panel-header-desc'>Create, manage and apply custom cohorts to your visualizations </h2>
  </div>
  <!-- My Cohorts -->
  <div fxLayout.xs='column'>
    <div fxFlex.sm='1 1 30%' fxFlex.md='1 1 20%' fxFlex.lg='1 1 25%' fxFlex.xl='1 1 20%'>
      <h2 class='panel-column-title'>My Cohorts</h2>
      <div *ngFor='let option of cohorts' (click)='deleteClick(option)' class='panel-config-row-del'>
        <i class='material-icons md-18'>remove_circle_outline</i>
        <div>{{option.n}}
          <span *ngIf='option.pids.length>0'>({{option.pids.length}} patients)</span>
        </div>
      </div>
    </div>
    <!-- Create Cohorts -->
    <div fxFlex.sm='1 1 70%' fxFlex.md='1 1 80%' fxFlex.lg='1 1 75%' fxFlex.xl='1 1 80%'>
      <h2 class='panel-column-title' style='margin-bottom:10px;'>Build A Cohort</h2>

      <label class='panel-builder-label'>create</label>
      <mat-form-field>
        <input placeholder='Enter Cohort Name' matInput type='text' autocomplete='off' [(ngModel)]='activeCohort.n'>
      </mat-form-field>
      <div class='panel-builder-criteria-row' [class.cohort-field-or]='condition.condition==="or"' *ngFor='let condition of activeCohort.conditions'>
        <label class='panel-builder-label'>{{condition.condition}}</label>
        <mat-form-field>
          <mat-select placeholder='Field' [(value)]='condition.field'>
            <mat-option *ngFor="let option of fields" [value]="option">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span *ngIf='condition.field.type==="category"'>
          <mat-form-field style='width:170px;margin-left:5px;'>
            <mat-select [(value)]='condition.value' placeholder='Equals'>
              <mat-option *ngFor="let option of condition.field.options" [value]="option.value">
                {{option.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
        <span *ngIf='condition.field.type==="number"'>
          <mat-form-field style='width:75px;margin-left:5px;'>
            <input matInput type='number' [(ngModel)]='condition.min' autocomplete='off' placeholder='Min'>
          </mat-form-field>
          <mat-form-field style='width:75px;margin-left:5px;'>
            <input matInput type='number' [(ngModel)]='condition.max' autocomplete='off' placeholder='Max'>
          </mat-form-field>
        </span>
        <span class='panel-builder-buttons'>
          <a class='os-link' href='#' (click)='fieldAnd(condition)'>And</a> |
          <a class='os-link' href='#' (click)='fieldOr(condition)'>Or</a>
          <span *ngIf='condition.condition !== "where"'> |
            <a class='os-link' href='#' (click)='fieldDel(condition)'>Remove</a>
          </span>
        </span>
      </div>
      <button class='button' (click)='saveClick()' mat-raised-button>Save</button>
 

      <div id='compareCohorts'>
        &nbsp;<br >
        <hr><hr>
        <h1 class='panel-column-title' style='margin-bottom:10px;'>Compare Two Cohorts</h1>


          Cohort A: <mat-form-field>
          <mat-select placeholder='Choose a cohort... ' [(ngModel)]='cohortA'>
            <mat-option *ngFor="let option of cohorts" [value]="option">
              {{option.n}}
            </mat-option>
          </mat-select>
        </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

          Cohort B: <mat-form-field>
          <mat-select placeholder='Choose a second cohort...' [(ngModel)]='cohortB'>
            <mat-option *ngFor="let option of cohorts" [value]="option">
              {{option.n}}
            </mat-option>
          </mat-select>
        </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Use Table:<mat-form-field>
          <mat-select placeholder='choose a table' [(ngModel)]='selectedCompareTable'>
            <mat-option *ngFor="let option of tables" [value]="option">
              {{option.tbl}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <section class="compare-progress-section" *ngIf="progressValue > 0 || progressMode == 'buffer' ">
          <b>Progress: </b>
          <mat-progress-bar
              id="compareProgressBar"
              [mode]="progressMode"
              [value]="progressValue"
              [bufferValue]="bufferValue">
          </mat-progress-bar>
        </section>        
        <hr>

        
        <h3 class='panel-column-title' style='margin-bottom:10px;'>Naive Differential Expression</h3>
        <div>This naive differential expression analysis does not provide tests of statistical signficance. It merely lists the genes that have the highest difference in median expression between two cohorts.</div>
        <div>&nbsp;<br>&nbsp;<br></div>
        <button class='button' (click)='naiveDiffExpClick()' mat-raised-button>Run Naive Comparison</button>
        
        <hr>
      </div>


    </div>


  </div>
</div>