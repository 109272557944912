<div class="panel">
  <div>
    <a href="#" class="modalClose" (click)="closeClick()"> </a>
    <h1 class="panel-header">Citations</h1>
    <h2 class="panel-header-desc">A complete list of all Oncoscape's citations</h2>
  </div>
  <!--
    <div class="citations-container">
      <mat-form-field>
        <input matInput placeholder="Search" id="icon_prefix" type="text" autocomplete='off' #filterInput class="validate">
      </mat-form-field>
    </div>
  -->
  <div fxLayout.xs="column">
    <div fxFlex.sm="1 1 30%" fxFlex.md="1 1 20%" fxFlex.lg="1 1 25%" fxFlex.xl="1 1 10%">
      <h2
        style="cursor: pointer;
    margin-top: 25px;
    word-wrap: break-word;
    text-decoration: none;
    font-size: 1.5rem;
"
      >
        Index
      </h2>
      <div *ngFor="let result of results" class="citation-button">
        <span> {{ result.methods[0].method }} </span>
      </div>
    </div>

    <div fxFlex.sm="1 1 70%" fxFlex.md="1 1 70%" fxFlex.lg="1 1 70%" fxFlex.xl="1 1 70%">
      <!-- <div fxFlex.sm='1 1 100%'> -->
      <div *ngFor="let result of results">
        <span *ngIf="result.visible">
          <div *ngFor="let method of result.methods">
            <h2 class="citations-h2">
              <a class="citations-main-link" href="{{method.url}}" target="_blank">{{ method.method }}</a>
            </h2>
            <!-- <p >{{method.url}}</p> -->
          </div>
          <div *ngFor="let citation of result.citations">
            <p class="citations-name">{{ citation.name }}</p>
            <p class="citations-desc">{{ citation.desc }}</p>
            <p>
              <a class="citations-link" href="{{citation.url}}" target="_blank">{{ citation.url }}</a>
            </p>
            <p>
              <a class="citations-link" href="{{citation.url}}" target="_blank">{{ citation.url2 }}</a>
            </p>
            <p>
              <a class="citations-link" href="{{citation.url}}" target="_blank">{{ citation.url3 }}</a>
            </p>
          </div>
        </span>
      </div>
    </div>
  </div>
</div>
