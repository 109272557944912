/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./graph-widgets-panel.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../common-side-panel/common-side-panel.component.ngfactory";
import * as i3 from "../common-side-panel/common-side-panel.component";
import * as i4 from "../../../service/data.service";
import * as i5 from "@angular/common";
import * as i6 from "./graph-widgets-panel.component";
import * as i7 from "../../../service/modal-service";
var styles_GraphWidgetsPanelComponent = [i0.styles];
var RenderType_GraphWidgetsPanelComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_GraphWidgetsPanelComponent, data: {} });
export { RenderType_GraphWidgetsPanelComponent as RenderType_GraphWidgetsPanelComponent };
function View_GraphWidgetsPanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workspace-common-side-panel", [], null, null, null, i2.View_CommonSidePanelComponent_0, i2.RenderType_CommonSidePanelComponent)), i1.ɵdid(1, 4898816, null, 0, i3.CommonSidePanelComponent, [i1.ChangeDetectorRef, i4.DataService], { datasetDescription: [0, "datasetDescription"], config: [1, "config"], legends: [2, "legends"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datasetDescription; var currVal_1 = _co.config; var currVal_2 = _co.data.legends; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_GraphWidgetsPanelComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GraphWidgetsPanelComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.readyForWidgets; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GraphWidgetsPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "graph-widgets-panel", [], null, null, null, View_GraphWidgetsPanelComponent_0, RenderType_GraphWidgetsPanelComponent)), i1.ɵdid(1, 4964352, null, 0, i6.GraphWidgetsPanelComponent, [i7.ModalService, i1.ChangeDetectorRef, i4.DataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GraphWidgetsPanelComponentNgFactory = i1.ɵccf("graph-widgets-panel", i6.GraphWidgetsPanelComponent, View_GraphWidgetsPanelComponent_Host_0, { config: "config", datasetDescription: "datasetDescription", data: "data" }, {}, []);
export { GraphWidgetsPanelComponentNgFactory as GraphWidgetsPanelComponentNgFactory };
