import { VisualizationEnum, EntityTypeEnum, DimensionEnum, LegendOptionEnum } from 'app/model/enum.model';
import { GraphData } from './../../model/graph-data.model';
import { GraphConfig } from './../../model/graph-config.model';


export class VisualizationAbstractScatterConfigModel extends GraphConfig {

  constructor() {
      super();
      this.isScatterVisualization = true;
      this.legendOptions = [
        {
          type: LegendOptionEnum.MARKER_BASE_SIZE,
          enabled: true,
        },
        {
          type: LegendOptionEnum.MARKER_OPACITY,
          enabled: true,
        },
        {
          type: LegendOptionEnum.SIZE,
          enabled: true
        }
      ]
    }
}
