/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loader.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./loader.component";
import * as i4 from "../../../service/dataset.service";
var styles_LoaderComponent = [i0.styles];
var RenderType_LoaderComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LoaderComponent, data: {} });
export { RenderType_LoaderComponent as RenderType_LoaderComponent };
function View_LoaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "lds-ellipsis"], ["style", "height:15px;top:-20px;left:10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_LoaderComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "loader-background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "loader-copy-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "loader-copy"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "loader-quote"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["\u2018", "\u2019 "])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "loader-author"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoaderComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["style", "text-align: center;  font-size:9px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "a", [["href", "javascript:setTimeout(function(){document.querySelector('.loader').style.visibility = 'hidden';}, 200);"], ["id", "loader-cancel"], ["style", "color:gray;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.showEllipsis; _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.quote.q; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.quote.a; _ck(_v, 7, 0, currVal_1); }); }
export function View_LoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workspace-loader", [], null, null, null, View_LoaderComponent_0, RenderType_LoaderComponent)), i1.ɵdid(1, 4374528, null, 0, i3.LoaderComponent, [i4.DatasetService, i1.ChangeDetectorRef], null, null)], null, null); }
var LoaderComponentNgFactory = i1.ɵccf("app-workspace-loader", i3.LoaderComponent, View_LoaderComponent_Host_0, { visibility: "visibility" }, {}, []);
export { LoaderComponentNgFactory as LoaderComponentNgFactory };
