<div>

  <div class="selection-actions">
    <span class="legendHeader">Actions</span>
    <button mat-stroked-button  (click)="onClearSelection()">Clear Selection</button>
    <button mat-stroked-button  (click)="onSelectByIds()">Select By ID</button>
  </div>

  <button mat-icon-button style="color:rgb(30, 136, 229)"><mat-icon>directions_run</mat-icon></button>
  <!--
    <mat-form-field class="form-field " style="width:195px">
      <input matInput type="text" placeholder="Search" [(ngModel)]="cohortSearch" />
    </mat-form-field>
    <button mat-icon-button style="color:rgb(30, 136, 229)" (click)="onSearch()"><mat-icon>search</mat-icon></button>
  -->
  <mat-form-field class="form-field " style="width:195px">
    <input matInput type="text" placeholder="Save As Cohort" [(ngModel)]="cohortName" />
  </mat-form-field>
  <button mat-icon-button style="color:rgb(30, 136, 229)" (click)="onSaveSelection()"><mat-icon>save</mat-icon></button>
</div>
