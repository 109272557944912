
    <h2 class="panel-column-title" style="margin-bottom:10px;">
      Cohort Clinical Feature Comparison
    </h2>
    <p *ngIf="hasData()==false"><b>Please select TWO different cohorts from the dropdown lists above.</b></p>

    <div *ngIf="hasData()" class="cohort-compare-table-container mat-elevation-z8">

          <div class="container">
            <div class="left">

      <table mat-table #cohortComparisonTable class="oncoscape-scrolling-mat-table" [dataSource]="data" matSort (matSortChange)="sortData($event)" matSortActive="p_value" matSortDirection="asc" matSortDisableClear>
        <ng-container matColumnDef="metric">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Metric</th>
          <td mat-cell *matCellDef="let row"><span class="cohort-compare-tooltip">
            <span class="cohort-compare-tooltiptext"><b>{{row.metric}}:</b><br>p-Value: {{cleanNumber(row.p_value)}}<br>
              Statistic: {{cleanNumber(row.score)}}
            </span>{{row.metric}}</span></td>        
        </ng-container>

        <ng-container matColumnDef="overview">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Overview</th>
          <td mat-cell *matCellDef="let row"><span class="cohort-compare-tooltip">
            <span class="cohort-compare-tooltiptext"><b>{{row.metric}}:</b><br>p-Value: {{cleanNumber(row.p_value)}}<br>
              Statistic: {{cleanNumber(row.score)}}
            </span>{{row.overview}}</span></td>        
        </ng-container>

        <ng-container matColumnDef="p_value">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>p_value</th>
          <td mat-cell *matCellDef="let row"><span class="cohort-compare-tooltip">
            <span class="cohort-compare-tooltiptext"><b>{{row.metric}}:</b><br>p-Value: {{cleanNumber(row.p_value)}}<br>
              Statistic: {{cleanNumber(row.score)}}
            </span>{{cleanNumber(row.p_value)}}</span></td>
        </ng-container>

        <tr mat-header-row  *matHeaderRowDef="['metric', 'overview', 'p_value' ]; sticky: true"></tr>
        <tr mat-row   *matRowDef="let row; columns: ['metric', 'overview', 'p_value']"
          (click)="onRowClicked(row)" [ngClass]="{'highlightTableColor': selectedRowIndex == row.pos}"
        ></tr>
      </table><!-- actual mat-table for metric items -->
        
      </div>
      <div id="compare-graph-div" class="right">
        <!--<google-chart #comparisonChart [title]="gctitle" [type]="gctype" [data]="gcdata" [columnNames]="gccolumnNames" [options]="gcoptions"
        [width]="gcwidth" [height]="gcheight">
        </google-chart>-->
      </div>
    </div>


    </div>
    <section class="compare-progress-section" *ngIf="progressValue > 0 || progressMode == 'buffer' ">
      <b>Progress: </b>
      <mat-progress-bar id="compareClinicalProgressBar" [mode]="progressMode" [value]="progressValue"
        [bufferValue]="bufferValue">
      </mat-progress-bar>
          
    </section>
