import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  Renderer2
} from '@angular/core';
import * as d3 from 'd3';
 
//import { WidgetComponent } from "./widget.component";
import { HtmlWidgetComponent } from "./html-widget.component";
import * as _ from 'lodash';
import { GraphConfig } from '../../../model/graph-config.model';
import { DataDecorator, DataDecoratorTypeEnum } from '../../../model/data-map.model';
import { DataService } from 'app/service/data.service';
import { ComputeWorkerUtil } from 'app/service/compute.worker.util';
import { WorkspaceComponent } from '../workspace.component';
import { SelectionModifiers } from 'app/component/visualization/visualization.abstract.scatter.component';
import { CollectionTypeEnum, EntityTypeEnum } from 'app/model/enum.model';
import { ChartScene } from 'app/component/workspace/chart/chart.scene';
import { LoadedTable, OncoData } from 'app/oncoData';
import { TooltipContextObject } from './tooltipContextObject';
import { Cohort } from '../../../model/cohort.model';
import {
  ViewChild,
  ElementRef
} from '@angular/core';

@Component({
  selector: 'cohorts-widget',
  templateUrl: './cohorts-widget.html',
  styleUrls: ['./common-side-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class CohortsWidgetComponent extends HtmlWidgetComponent {
   // @ViewChild('cohortChosen') cohortChosen: ElementRef;
   @ViewChild('selectedCohortTextField', { static: true }) selectedCohortText: ElementRef;

  constructor(
    renderer: Renderer2
  ) 
  { 
    super(renderer);
    this.model.name = "Cohorts";
    this.model.preferredHeight = null; // Autogrow, unlike forced height like "130px";
  }
  
  parentAddCohortClicked(e):void {
    OncoData.instance.currentCommonSidePanel.addCohortClicked(e);
  }

  parentApplySelectedCohortNameFilter(e):void {
    OncoData.instance.currentCommonSidePanel.applySelectedCohortNameFilter(e);
  }
  
  ngAfterViewInit(): void {}

  ngOnDestroy() {}

}