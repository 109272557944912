import { Khonsole } from '../../../../app/khonsole';
import * as scale from 'd3-scale';
import * as THREE from 'three';
import { Vector3 } from 'three';
import { MeshLine, MeshLineMaterial } from 'three.meshline';
import { EntityTypeEnum, SpriteMaterialEnum } from './../../../model/enum.model';
import { DataFieldFactory } from './../../../../app/model/data-field.model';
import { OncoData } from 'app/oncoData';
export class ChartFactory {
    static cleanForLocalStorage(s) {
        //TBD: add tolowercase?
        return s.replace(/\./g, '_')
            .replace(/\!/g, '_')
            .replace(/\,/g, '_')
            .replace(/\ /g, '_');
    }
    static writeCustomValueToLocalStorage(database, category, key, val) {
        Khonsole.warn(`== write CVFLS,  ${database}, ${category}, ${key}, ${val}`);
        let db = this.cleanForLocalStorage(database); // Turn periods and bangs to underscores.
        let storagePath = 'oncoscape' + category + '_' + db;
        let categoryStruct = {};
        if (!localStorage[storagePath]) {
            localStorage[storagePath] = {};
        }
        else {
            categoryStruct = JSON.parse(localStorage[storagePath]);
        }
        categoryStruct[key] = JSON.stringify(val);
        localStorage[storagePath] = JSON.stringify(categoryStruct);
    }
    static readCustomValueFromLocalStorage(database, category, key) {
        let db = database.replace(/\./g, '_').replace(/\!/g, '_'); // Turn periods and bangs to underscores.
        let storagePath = 'oncoscape' + category + '_' + db;
        let categoryStruct = {};
        if (!localStorage[storagePath]) {
            return null;
        }
        else {
            let categoryStructWithPeriods = JSON.parse(localStorage[storagePath]);
            // Turn periods into underscores.
            for (const key in categoryStructWithPeriods) {
                if (categoryStructWithPeriods.hasOwnProperty(key)) {
                    const newKey = key.replace(/\./g, "_");
                    categoryStruct[newKey] = categoryStructWithPeriods[key];
                }
            }
        }
        let key_to_use = key.replace(/\./g, '_'); // Turn periods to underscores.;
        let result = categoryStruct[key_to_use];
        if (result == null) {
            // Older customcolors in users' LocalStorage may still have "Sample " prefix, so retry.
            Khonsole.log("== read CVFLS,  try 'Sample ' prefix.");
            result = categoryStruct["Sample " + key_to_use];
            Khonsole.log(result ? "== success" : "still null");
        }
        if (result == null) {
            return null;
        }
        else {
            result = result.replace(/"([^"]+(?="))"/g, '$1'); // trim quotes from start and end
            return result;
        }
    }
    // public static colorsContinuous = [
    //   '#e53935',
    //   '#d81b60',
    //   '#8e24aa',
    //   '#5e35b1',
    //   '#3949ab',
    //   '#1e88e5',
    //   '#039be5',
    //   '#00acc1',
    //   '#00897b',
    //   '#43a047'
    // ];
    static colorsContinuous(scale_type, customPalette) {
        // Original (pre-2023) "ZEROBASED"...
        //   return ['#fff5f5', '#ffe6e6', '#ff9999', '#ff6666', '#ff3333', '#ff0000', '#cc0000', '#990000'];
        // Original (pre-2023) "DEFAULT", divergent...
        //   return ['#3288bd', '#66c2a5', '#abdda4', '#e6f598', '#fee08b', '#fdae61', '#f46d43', '#d53e4f'];
        if (customPalette) {
            // Brewer Spectral is fallback if custom palette is not found.
            let palette = ['#3288bd', '#66c2a5', '#abdda4', '#e6f598', '#fee08b', '#fdae61', '#f46d43', '#d53e4f'];
            if (customPalette.type == "brewer" || customPalette.type == "onco") {
                switch (customPalette.name) {
                    case "RdYlBu": { // a brewer palette
                        palette = ["#d73027", "#f46d43", "#fdae61", "#fee090", "#e0f3f8", "#abd9e9", "#74add1", "#4575b4"];
                        break;
                    }
                    case "RdYlBu_reverse": { // a brewer palette, reversed.
                        palette = ["#4575b4", "#74add1", "#abd9e9", "#e0f3f8", "#fee090", "#fdae61", "#f46d43", "#d73027"];
                        break;
                    }
                    case "RdBu": { // a brewer palette
                        palette = ["#2166ac", "#4393c3", "#92c5de", "#d1e5f0", "#fddbc7", "#f4a582", "#d6604d", "#b2182b"];
                        break;
                    }
                    case "RdBu_reverse": { // a brewer palette, reversed.
                        palette = ["#b2182b", "#d6604d", "#f4a582", "#fddbc7", "#d1e5f0", "#92c5de", "#4393c3", "#2166ac"];
                        break;
                    }
                    case "OncoYlBu": { // equal steps yellow to blue, supports meningioma paper
                        palette = ["#ece48b", "#cdc687", "#b4ad84", "#999380", "#7e787d", "#615c7a", "#464277", "#292674"];
                        break;
                    }
                }
            }
            return palette;
        }
        else {
            // No custom palette, so use the default zerobased or divergent.
            if (scale_type == "ZEROBASED") {
                return ['#ffe8e8', '#ffbbbb', '#ff8080', '#ff4d4d', '#ff2222', '#ee0000', '#cc0000', '#800000'];
            }
            else {
                // "DEFAULT", divergent. default, blue to white to red
                return ['#3288bd', '#66c2a5', '#abdda4', '#e6f598', '#fee08b', '#fdae61', '#f46d43', '#d53e4f'];
            }
        }
    }
    static getScaleSizeOrdinal(values) {
        const len = values.length;
        return scale
            .scaleOrdinal()
            .domain(values)
            .range(ChartFactory.sizes.slice(0, values.length));
    }
    static getScaleSizeLinear(min, max) {
        return scale
            .scaleLog()
            .domain([min, max])
            .range([1, 3])
            .clamp(true);
    }
    static getScaleShapeOrdinal(values) {
        const len = values.length;
        const cols = ChartFactory.sprites.slice(0, values.length);
        return scale
            .scaleOrdinal()
            .domain(values)
            .range(cols);
    }
    static getScaleShapeLinear(min, max, bins = 0) {
        bins = Math.min(bins, 8);
        const range = ChartFactory.sprites.slice(0, bins);
        return scale
            .scaleQuantize()
            .domain([min, max])
            .range(range);
    }
    static getScaleGroupOrdinal(values) {
        const range = Array.from({ length: values.length }, (v, k) => (k + 1).toString());
        return scale
            .scaleOrdinal()
            .domain(values)
            .range(range);
    }
    static getScaleGroupLinear(min, max, bins = 0) {
        bins = Math.min(bins, 8);
        const range = Array.from({ length: bins }, (v, k) => (k + 1).toString());
        return scale
            .scaleQuantize()
            .domain([min, max])
            .range(range);
    }
    static getScaleColorOrdinal(values, field) {
        const len = values.length;
        Khonsole.log(`getScaleColorOrdinal for ${len} items.`);
        const cols = len > 4
            ? DataFieldFactory.dataFieldColors.slice(0, values.length)
            : DataFieldFactory.dataFieldColors.filter((c, i) => i % 2).slice(0, values.length);
        let valuesCopy = JSON.parse(JSON.stringify(values));
        let colsCopy = JSON.parse(JSON.stringify(cols));
        if (field) {
            let database = OncoData.instance.dataLoadedAction.dataset;
            let legendKeyOrder_escaped = ChartFactory.readCustomValueFromLocalStorage(database, 'legendColorMisc', 'legendKeyOrder');
            if (legendKeyOrder_escaped) {
                let legendKeyOrder = JSON.parse(legendKeyOrder_escaped.replace(/\\/g, '"'));
                Khonsole.log("legendKeyOrder found in local storage");
                Khonsole.dir(legendKeyOrder);
                let dict = legendKeyOrder[field.key];
                if (dict == null) {
                    dict = legendKeyOrder["Sample " + field.key];
                }
                if (dict) {
                    Khonsole.log("legendKeyOrder match in local storage");
                    Khonsole.dir(dict);
                    // // Make a copy of values swapping spaces for underscores
                    // const valuesCopyUnderscore = valuesCopy.map(v => v.replace(/\ /g, '_'));
                    // // Get the values from the dictionary in the desired order
                    // const orderedValues = Object.keys(dict).map(v => dict[v].key).filter(key => valuesCopyUnderscore.includes(key));
                    // // Create a set of values from the dictionary for efficient lookup
                    // const dictValuesSet = new Set(orderedValues);
                    // // Filter out values that are already in orderedValues
                    // const remainingValues = valuesCopy.filter(value => !dictValuesSet.has(value.replace(/\ /g, '_')));
                    // // Concatenate the remaining values to the end of orderedValues
                    // const finalOrderedValues = orderedValues.concat(remainingValues);
                    // // Create new copies of colsCopy following the order in finalOrderedValues
                    // const newColsCopy = finalOrderedValues.map(value => colsCopy[valuesCopyUnderscore.indexOf(value)]);
                    // Get the values from the dictionary in the desired order
                    const orderedValues = Object.keys(dict).map(v => dict[v].key).filter(key => valuesCopy.includes(key));
                    // Create a set of values from the dictionary for efficient lookup
                    const dictValuesSet = new Set(orderedValues);
                    // Filter out values that are already in orderedValues
                    const remainingValues = valuesCopy.filter(value => !dictValuesSet.has(value));
                    // Concatenate the remaining values to the end of orderedValues
                    const finalOrderedValues = orderedValues.concat(remainingValues);
                    // Create new copies of colsCopy following the order in finalOrderedValues
                    const newColsCopy = finalOrderedValues.map(value => colsCopy[valuesCopy.indexOf(value)]);
                    console.log(finalOrderedValues);
                    console.log(newColsCopy);
                    // console.log(newValuesCopy);
                    // console.log(newColsCopy);
                    valuesCopy = finalOrderedValues;
                    colsCopy = newColsCopy;
                }
            }
        }
        return scale
            .scaleOrdinal()
            .domain(valuesCopy)
            .range(colsCopy);
    }
    static getScaleColorLinear(field, min, max, bins = 8, customPalette = null) {
        Khonsole.warn("getScaleColorLinear  ==========");
        let minToUse = min;
        let maxToUse = max;
        if (customPalette) {
            if (customPalette.min != null) {
                minToUse = customPalette.min;
            }
            if (customPalette.max != null) {
                maxToUse = customPalette.max;
            }
        }
        let table_legend_colorscale_type = "DEFAULT"; // divergent
        let tbl_info = OncoData.instance.dataLoadedAction.tables.find(x => x.tbl == field.tbl);
        if (tbl_info) {
            if (tbl_info.ctype == 32768 /* RNA */ ||
                tbl_info.ctype == 256 /* MRNA */ ||
                tbl_info.ctype == 128 /* MIRNA */) {
                table_legend_colorscale_type = "ZEROBASED";
            }
        }
        bins = Math.min(bins, 8);
        const range = bins > 4
            // TODO: Our scales have 8 colors. If someone comes in with 10 bins (is that posssible?), we trim off the darkest 2 colors; we should rescale to 10 bins.
            ? ChartFactory.colorsContinuous(table_legend_colorscale_type, customPalette).slice(0, bins)
            : ChartFactory.colorsContinuous(table_legend_colorscale_type, customPalette).filter((c, i) => i % 2).slice(0, bins);
        return scale
            .scaleQuantize()
            .domain([minToUse, maxToUse])
            .range(range);
    }
    // Pools
    static createDataGroup(id, idType, position) {
        const group = new THREE.Group();
        group.position.set(position.x, position.y, position.z);
        group.userData.id = id;
        group.userData.idType = idType;
        group.userData.tooltip = id;
        return group;
    }
    static decorateDataGroups(groups, decorators, renderer = null, scaleFactor = 3) {
        // groups: Array<THREE.Group>,
        // Retrieve Id
        if (groups.length === 0) {
            return;
        }
        Khonsole.log('decorateDataGroups');
        const idType = groups[0].userData.idType;
        const idProperty = idType === EntityTypeEnum.PATIENT
            ? 'pid'
            : idType === EntityTypeEnum.SAMPLE
                ? 'sid'
                : 'mid';
        // Decorators
        const shapeDecorator = decorators.filter(decorator => decorator.type === 2 /* SHAPE */);
        const colorDecorator = decorators.filter(decorator => decorator.type === 0 /* COLOR */);
        const sizeDecorator = decorators.filter(decorator => decorator.type === 1 /* SIZE */);
        const selectDecorator = decorators.filter(decorator => decorator.type === 8 /* SELECT */);
        const labelDecorator = decorators.filter(decorator => decorator.type === 16 /* LABEL */);
        const groupDecorator = decorators.filter(decorator => decorator.type === 32 /* GROUP */);
        // const tooltipDecorator = decorators.filter(decorator => decorator.type === DataDecoratorTypeEnum.TOOLTIP);
        // Maps
        const shapeMap = !shapeDecorator.length
            ? null
            : shapeDecorator[0].values.reduce((p, c) => {
                p[c[idProperty]] = c.value;
                return p;
            }, {});
        const colorMap = !colorDecorator.length
            ? null
            : colorDecorator[0].values.reduce((p, c) => {
                p[c[idProperty]] = c.value;
                return p;
            }, {});
        Khonsole.log("===colorMap=");
        Khonsole.dir(colorMap);
        const labelMap = !labelDecorator.length
            ? null
            : labelDecorator[0].values.reduce((p, c) => {
                p[c[idProperty]] = c.label;
                return p;
            }, {});
        const groupMap = !groupDecorator.length
            ? null
            : groupDecorator[0].values.reduce((p, c) => {
                p[c[idProperty]] = c.value;
                return p;
            }, {});
        const sizeMap = !sizeDecorator.length
            ? null
            : sizeDecorator[0].values.reduce((p, c) => {
                Khonsole.log('find size options');
                p[c[idProperty]] = c.value;
                return p;
            }, {});
        const count = groups.length;
        groups.forEach((item, i) => {
            while (item.children.length) {
                item.remove(item.children[0]);
            }
            const id = item.userData.id;
            let color = colorMap
                ? colorMap[id]
                    ? colorMap[id]
                    : '#DDDDDD'
                : '#039be5';
            if (item.userData.genesetOverlayColor) {
                color = item.userData.genesetOverlayColor;
            }
            const label = labelMap ? (labelMap[id] ? labelMap[id] : 'NA') : '';
            const shape = shapeMap
                ? shapeMap[id]
                    ? shapeMap[id]
                    : SpriteMaterialEnum.NA
                : SpriteMaterialEnum.CIRCLE;
            const size = sizeMap
                ? sizeMap[id]
                    ? sizeMap[id]
                    : scaleFactor
                : scaleFactor;
            const group = groupMap ? (groupMap[id] ? groupMap[id] : 0) : 0;
            // size = 1;
            const spriteMaterial = ChartFactory.getSpriteMaterial(shape, color);
            spriteMaterial.opacity = ChartFactory.spriteOpacity;
            const mesh = new THREE.Sprite(spriteMaterial);
            const tooltipExistsAlready = item.userData.tooltip && item.userData.tooltip != '';
            item.userData.tooltip = tooltipExistsAlready ? item.userData.tooltip : label;
            item.userData.color = isNaN(color)
                ? parseInt(color.replace(/^#/, ''), 16)
                : color;
            mesh.scale.set(size, size, size);
            mesh.userData.tooltip = label;
            mesh.userData.color = item.userData.color;
            mesh.userData.selectionLocked = false;
            if (renderer) {
                renderer(item, mesh, decorators, i, count);
            }
            item.add(mesh);
        });
    }
    static getOutlineMaterial() {
        const uniforms = { offset: { type: 'f', value: 1 } };
        const outShader = ChartFactory.shader.outline;
        return new THREE.ShaderMaterial({
            uniforms: uniforms,
            vertexShader: outShader.vertex_shader,
            fragmentShader: outShader.fragment_shader
        });
    }
    // --------------------- Old ------------------------ //
    // Mesh Pool
    static meshRelease(mesh) {
        mesh.userData = null;
        this.meshPool.push(mesh);
    }
    static meshAllocate(color, shape, size, position, data) {
        const mesh = this.meshPool.length > 0 ? this.meshPool.shift() : new THREE.Mesh();
        mesh.geometry = this.getShape(shape);
        mesh.scale.set(size, size, size);
        mesh.position.set(position.x, position.y, position.z);
        mesh.material = this.getColorPhong(color);
        mesh.userData = data;
        return mesh;
    }
    static meshDrain() {
        this.meshPool.length = 0;
    }
    static planeAllocate(color, width, height, data) {
        const geo = new THREE.PlaneGeometry(width, height);
        const material = this.getColorPhong(color);
        return new THREE.Mesh(geo, material);
    }
    static lineRelease(line) { }
    static lineAllocateCurve(color, pt1, pt2, pt3, data, z) {
        const line = new THREE.Line();
        line.material = this.getLineColor(color);
        const curve = new THREE.SplineCurve([pt1, pt3, pt2]);
        const path = new THREE.Path(curve.getPoints(50));
        const pts = path.getPoints().map(v => new Vector3(v.x, v.y, z ? z : 0));
        const geometry = new THREE.BufferGeometry().setFromPoints(pts);
        line.geometry = geometry;
        line.userData = data;
        return line;
    }
    static meshLineAllocate(color, pt1, pt2, camera, data) {
        const geometry = new THREE.Geometry();
        geometry.vertices.push(new THREE.Vector3(pt1.x, pt1.y, 0));
        geometry.vertices.push(new THREE.Vector3(pt2.x, pt2.y, 0));
        const line = new MeshLine();
        line.setGeometry(geometry);
        const material = new MeshLineMaterial({
            useMap: false,
            color: new THREE.Color(color),
            opacity: 1,
            // resolution: resolution,
            // sizeAttenuation: !false,
            lineWidth: 2,
            near: camera.near,
            far: camera.far
        });
        return new THREE.Mesh(line.geometry, material);
    }
    static linesAllocate(color, pts, data) {
        const line = new THREE.Line();
        line.material = this.getLineColor(color);
        line.userData = data;
        const geometry = new THREE.Geometry();
        geometry.vertices.push(...pts.map(v => new THREE.Vector3(v.x, v.y, 0)));
        line.geometry = geometry;
        return line;
    }
    static lineAllocate(color, pt1, pt2, data, z) {
        const line = new THREE.Line();
        line.material = this.getLineColor(color);
        line.userData = data;
        const geometry = new THREE.Geometry();
        geometry.vertices.push(new THREE.Vector3(pt1.x, pt1.y, z ? z : 0));
        geometry.vertices.push(new THREE.Vector3(pt2.x, pt2.y, z ? z : 0));
        line.geometry = geometry;
        return line;
    }
    static lineDrain() {
        this.linePool.length = 0;
    }
    // @memoize
    static getLineColor(color) {
        return new THREE.LineBasicMaterial({ color: color });
    }
    // @memoize
    static getMeshLine(color, lineWidth = 2) {
        return new MeshLineMaterial({
            color: new THREE.Color(color),
            lineWidth: lineWidth
        });
    }
    // @memoize
    static getColorMetal(color) {
        return new THREE.MeshStandardMaterial({
            color: color,
            emissive: new THREE.Color(0x000000),
            metalness: 0.2,
            roughness: 0.5
        });
    }
    static getOutlineShader(cameraPosition, color = 0xff0000) {
        const shader = {
            glow: {
                vertex_shader: [
                    'uniform vec3 viewVector;',
                    'uniform float c;',
                    'uniform float p;',
                    'varying float intensity;',
                    'void main() ',
                    '{',
                    'vec3 vNormal = normalize( normalMatrix * normal );',
                    'vec3 vNormel = normalize( normalMatrix * viewVector );',
                    'intensity = pow( c - dot(vNormal, vNormel), p );',
                    'gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',
                    '}'
                ].join('\n'),
                fragment_shader: [
                    'uniform vec3 glowColor;',
                    'varying float intensity;',
                    'void main() ',
                    '{',
                    'vec3 glow = glowColor * intensity;',
                    'gl_FragColor = vec4( glow, 1.0 );',
                    '}'
                ].join('\n')
            }
        };
        const uniforms = {
            c: { type: 'f', value: 1.0 },
            p: { type: 'f', value: 1.4 },
            glowColor: { type: 'c', value: new THREE.Color(color) },
            viewVector: { type: 'v3', value: cameraPosition }
        };
        return new THREE.ShaderMaterial({
            uniforms: uniforms,
            vertexShader: shader.glow.vertex_shader,
            fragmentShader: shader.glow.fragment_shader,
            side: THREE.BackSide,
            blending: THREE.NormalBlending,
            transparent: true
        });
    }
    // @memoize
    static getColor(color) {
        return new THREE.Color(color);
    }
    // @memoize
    static getColorBasic(color) {
        return new THREE.MeshBasicMaterial({
            color: color,
            side: THREE.DoubleSide
        });
    }
    // @memoize
    static getColorPhong(color) {
        return new THREE.MeshBasicMaterial({ color: color });
        // return new THREE.MeshStandardMaterial(
        //     {
        //         color: color,
        //         roughness: 0.0,
        //         metalness: 0.02,
        //         emissive: new THREE.Color(0x000000)
        //         // color: color, emissive: new THREE.Color(0x000000),
        //         // metalness: 0.2, roughness: .5, shading: THREE.SmoothShading
        //     });
    }
    // @memoize
    static getSpriteMaterial(shape, color) {
        let result = null;
        switch (shape) {
            case SpriteMaterialEnum.BLAST:
                result = new THREE.SpriteMaterial({
                    map: ChartFactory.textures.blast,
                    color: color
                });
                break;
            case SpriteMaterialEnum.BLOB:
                result = new THREE.SpriteMaterial({
                    map: ChartFactory.textures.blob,
                    color: color
                });
                break;
            case SpriteMaterialEnum.CIRCLE:
                result = new THREE.SpriteMaterial({
                    map: ChartFactory.textures.circle,
                    alphaMap: ChartFactory.alphas.circle,
                    color: color
                });
                result.transparent = true;
                break;
            case SpriteMaterialEnum.DIAMOND:
                result = new THREE.SpriteMaterial({
                    map: ChartFactory.textures.diamond,
                    color: color
                });
                break;
            case SpriteMaterialEnum.POLYGON:
                result = new THREE.SpriteMaterial({
                    map: ChartFactory.textures.polygon,
                    color: color
                });
                break;
            case SpriteMaterialEnum.SQUARE:
                result = new THREE.SpriteMaterial({
                    map: ChartFactory.textures.square,
                    color: color
                });
                break;
            case SpriteMaterialEnum.STAR:
                result = new THREE.SpriteMaterial({
                    map: ChartFactory.textures.star,
                    color: color
                });
                break;
            case SpriteMaterialEnum.TRIANGLE:
                result = new THREE.SpriteMaterial({
                    map: ChartFactory.textures.triangle,
                    color: color
                });
                break;
            case SpriteMaterialEnum.NA:
                result = new THREE.SpriteMaterial({
                    map: ChartFactory.textures.na,
                    color: color
                });
                break;
        }
        if (!result) {
            return new THREE.SpriteMaterial({
                map: ChartFactory.textures.na,
                color: color
            });
        }
        else {
            result.transparent = true;
            return result;
        }
    }
    // @memoize
    static getShape(shape) {
        switch (shape) {
            case 32 /* BOX */:
                return new THREE.BoxGeometry(3, 3, 3);
            case 2 /* CIRCLE */:
                return new THREE.SphereGeometry(3, 15, 15);
            case 4 /* SQUARE */:
                return new THREE.BoxGeometry(3, 3, 3); // was CubeGeometry, which is a now-deprecated alias for BoxGeometry.
            case 8 /* TRIANGLE */:
                return new THREE.TetrahedronGeometry(3);
            case 16 /* CONE */:
                return new THREE.ConeGeometry(3, 3, 10, 10);
            default:
                return new THREE.TorusGeometry(2);
        }
    }
    static configPerspectiveOrbit(view, box) {
        const perspective = view.camera;
        const orbit = view.controls;
        const fovyR = (perspective.fov * Math.PI) / 180;
        const sphere = box.getBoundingSphere(new THREE.Sphere());
        const modelHeight = sphere.radius;
        const zPosition = modelHeight / 2 / Math.tan(fovyR / 2);
        const distance = zPosition;
        orbit.minDistance = -distance * 0.5;
        orbit.maxDistance = distance * 2;
        perspective.position.z = distance * 2;
        perspective.lookAt(0, 0, 0);
        perspective.updateProjectionMatrix();
    }
}
ChartFactory.meshPool = [];
ChartFactory.linePool = [];
ChartFactory.shader = {
    outline: {
        vertex_shader: [
            'uniform float offset;',
            'void main() {',
            'vec4 pos = modelViewMatrix * vec4( position + normal * offset, 1.0 );',
            'gl_Position = projectionMatrix * pos;',
            '}'
        ].join('\n'),
        fragment_shader: [
            'void main(){',
            'gl_FragColor = vec4( 1.0, 0.0, 0.0, 1.0 );',
            '}'
        ].join('\n')
    }
};
ChartFactory.spriteOpacity = 0.7;
ChartFactory.sizes = [1 /* S */, 2 /* M */, 3 /* L */, 4 /* XL */];
ChartFactory.shapes = [
    2 /* CIRCLE */,
    32 /* BOX */,
    4 /* SQUARE */,
    16 /* CONE */
];
// public static shapes = ['blast', 'blob', 'circle', 'diamond', 'polygon', 'square', 'star', 'triangle'];
ChartFactory.sprites = [
    SpriteMaterialEnum.CIRCLE,
    SpriteMaterialEnum.TRIANGLE,
    SpriteMaterialEnum.DIAMOND,
    SpriteMaterialEnum.POLYGON,
    SpriteMaterialEnum.SQUARE,
    SpriteMaterialEnum.STAR,
    SpriteMaterialEnum.BLAST,
    SpriteMaterialEnum.BLOB
];
ChartFactory.alphas = {
    circle: new THREE.TextureLoader().load('assets/shapes/shape-circle-solid-alpha.png'),
};
ChartFactory.textures = {
    blast: new THREE.TextureLoader().load('assets/shapes/shape-blast-solid.png'),
    blob: new THREE.TextureLoader().load('assets/shapes/shape-blob-solid.png'),
    circle: new THREE.TextureLoader().load('assets/shapes/shape-circle-solid-border.png' // added border
    ),
    diamond: new THREE.TextureLoader().load('assets/shapes/shape-diamond-solid.png'),
    polygon: new THREE.TextureLoader().load('assets/shapes/shape-polygon-solid.png'),
    square: new THREE.TextureLoader().load('assets/shapes/shape-square-solid.png'),
    star: new THREE.TextureLoader().load('assets/shapes/shape-star-solid.png'),
    triangle: new THREE.TextureLoader().load('assets/shapes/shape-triangle-solid.png'),
    na: new THREE.TextureLoader().load('assets/shapes/shape-na-solid.png')
};
