
<form [formGroup]="form" novalidate> 

<button style="color:rgb(30, 136, 229)" (click)="onUpload3DPoints()">Paste in 3D Points</button>

<mat-form-field class='form-field'>
<mat-select placeholder='Saved Points Name' formControlName='savedPointsName'>
  <mat-option *ngFor='let option of savedPointsNameList' [value]='option'>
      {{ option }}
  </mat-option>
</mat-select>
</mat-form-field>


</form>
  