/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i2 from "@angular/material/menu";
import * as i3 from "@angular/common";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/cdk/overlay";
import * as i6 from "@angular/cdk/bidi";
import * as i7 from "./menu-item.component";
import * as i8 from "@angular/router";
var styles_MenuItemComponent = [];
var RenderType_MenuItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MenuItemComponent, data: {} });
export { RenderType_MenuItemComponent as RenderType_MenuItemComponent };
function View_MenuItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 4, "button", [["aria-haspopup", "true"], ["class", "mat-menu-item mat-menu-trigger"], ["color", "primary"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"], [null, "mouseenter"], [null, "mousedown"], [null, "keydown"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("mousedown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 3)._handleMousedown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown" === en)) {
        var pd_3 = (i0.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (i0.ɵnov(_v, 3)._handleClick($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i1.View_MatMenuItem_0, i1.RenderType_MatMenuItem)), i0.ɵdid(2, 180224, [[2, 4], [3, 4]], 0, i2.MatMenuItem, [i0.ElementRef, i3.DOCUMENT, i4.FocusMonitor, [2, i2.MAT_MENU_PANEL]], null, null), i0.ɵdid(3, 1196032, null, 0, i2.MatMenuTrigger, [i5.Overlay, i0.ElementRef, i0.ViewContainerRef, i2.MAT_MENU_SCROLL_STRATEGY, [2, i2.MatMenu], [6, i2.MatMenuItem], [2, i6.Directionality], i4.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i0.ɵeld(4, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "app-menu-item", [], null, null, null, View_MenuItemComponent_0, RenderType_MenuItemComponent)), i0.ɵdid(7, 49152, [["menu", 4]], 0, i7.MenuItemComponent, [i8.Router], { items: [0, "items"] }, null)], function (_ck, _v) { var currVal_7 = i0.ɵnov(_v, 7).childMenu; _ck(_v, 3, 0, currVal_7); var currVal_9 = _v.parent.context.$implicit.children; _ck(_v, 7, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).role; var currVal_1 = i0.ɵnov(_v, 2)._highlighted; var currVal_2 = i0.ɵnov(_v, 2)._triggersSubmenu; var currVal_3 = i0.ɵnov(_v, 2)._getTabIndex(); var currVal_4 = i0.ɵnov(_v, 2).disabled.toString(); var currVal_5 = (i0.ɵnov(_v, 2).disabled || null); var currVal_6 = (i0.ɵnov(_v, 3).menuOpen || null); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _v.parent.context.$implicit.displayName; _ck(_v, 5, 0, currVal_8); }); }
function View_MenuItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.selectMenuAction($event, _v.parent.context.$implicit.action) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_MatMenuItem_0, i1.RenderType_MatMenuItem)), i0.ɵdid(2, 180224, [[2, 4], [3, 4]], 0, i2.MatMenuItem, [i0.ElementRef, i3.DOCUMENT, i4.FocusMonitor, [2, i2.MAT_MENU_PANEL]], null, null), (_l()(), i0.ɵeld(3, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).role; var currVal_1 = i0.ɵnov(_v, 2)._highlighted; var currVal_2 = i0.ɵnov(_v, 2)._triggersSubmenu; var currVal_3 = i0.ɵnov(_v, 2)._getTabIndex(); var currVal_4 = i0.ɵnov(_v, 2).disabled.toString(); var currVal_5 = (i0.ɵnov(_v, 2).disabled || null); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _v.parent.context.$implicit.displayName; _ck(_v, 4, 0, currVal_6); }); }
function View_MenuItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuItemComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuItemComponent_3)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.children && (_v.context.$implicit.children.length > 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_v.context.$implicit.children || (_v.context.$implicit.children.length === 0)); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_MenuItemComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { childMenu: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 8, "mat-menu", [], null, null, null, i1.View__MatMenu_0, i1.RenderType__MatMenu)), i0.ɵprd(6144, null, i2.MatMenu, null, [i2._MatMenu]), i0.ɵprd(6144, null, i2.MAT_MENU_PANEL, null, [i2.MatMenu]), i0.ɵdid(4, 1294336, [[1, 4], ["childMenu", 4]], 3, i2._MatMenu, [i0.ElementRef, i0.NgZone, i2.MAT_MENU_DEFAULT_OPTIONS], { overlapTrigger: [0, "overlapTrigger"] }, null), i0.ɵqud(603979776, 2, { _allItems: 1 }), i0.ɵqud(603979776, 3, { items: 1 }), i0.ɵqud(603979776, 4, { lazyContent: 0 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_MenuItemComponent_1)), i0.ɵdid(9, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.items; _ck(_v, 9, 0, currVal_1); }, null); }
export function View_MenuItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-menu-item", [], null, null, null, View_MenuItemComponent_0, RenderType_MenuItemComponent)), i0.ɵdid(1, 49152, null, 0, i7.MenuItemComponent, [i8.Router], null, null)], null, null); }
var MenuItemComponentNgFactory = i0.ɵccf("app-menu-item", i7.MenuItemComponent, View_MenuItemComponent_Host_0, { items: "items" }, {}, []);
export { MenuItemComponentNgFactory as MenuItemComponentNgFactory };
