import { Injectable } from '@angular/core';
import { HttpClient as NgHttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class HttpClient {
  constructor(private http: NgHttpClient) {}

  createAuthorizationHeader(headers: HttpHeaders) {
    headers.append('api-key', 'password');
  }

  get(url) {
    const headers = new HttpHeaders();
    this.createAuthorizationHeader(headers);
    return this.http.get(url, {
      headers: headers
    });
  }

  post(url, data) {
    const headers = new HttpHeaders();
    this.createAuthorizationHeader(headers);
    return this.http.post(url, data, {
      headers: headers
    });
  }
}
