var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Khonsole } from 'app/khonsole';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of as observableOf, from as observableFrom } from 'rxjs';
import { mergeMap, withLatestFrom, switchMap, map } from 'rxjs/operators';
import { DataUpdateCohortsAction, DataUpdateGenesetsAction } from './../action/data.action';
import { WorkspaceComponent } from 'app/component/workspace/workspace.component';
import * as compute from 'app/action/compute.action';
import * as graph from 'app/action/graph.action';
import { EntityTypeEnum } from 'app/model/enum.model';
import { LoadedTable } from 'app/oncoData';
import { Observable } from 'rxjs/Rx';
// tslint:disable-next-line:max-line-length
import { BoxWhiskersCompleteAction, ChromosomeCompleteAction, DendogramCompleteAction, DictionaryLearningCompleteAction, EdgesCompleteAction, FaCompleteAction, FastIcaCompleteAction, GenomeCompleteAction, HazardCompleteAction, HeatmapCompleteAction, HicCompleteAction, HistogramCompleteAction, IsoMapCompleteAction, LdaCompleteAction, LinearDiscriminantAnalysisCompleteAction, LinkedGeneCompleteAction, LocalLinearEmbeddingCompleteAction, MdsCompleteAction, SavedPointsCompleteAction, TableLoaderCompleteAction, MiniBatchDictionaryLearningCompleteAction, MiniBatchSparsePcaCompleteAction, NmfCompleteAction, NoneCompleteAction, NullDataAction, ParallelCoordsCompleteAction, PathwaysCompleteAction, PcaCompleteAction, PcaIncrementalCompleteAction, PcaKernalCompleteAction, PcaSparseCompleteAction, QuadraticDiscriminantAnalysisCompleteAction, SomCompleteAction, SpectralEmbeddingCompleteAction, SurvivalCompleteAction, TimelinesCompleteAction, TruncatedSvdCompleteAction, TsneCompleteAction, PlsSvdCompleteAction, PlsRegressionCompleteAction, PlsCanonicalCompleteAction, CCACompleteAction, LinearSVCCompleteAction, LinearSVRCompleteAction, NuSVRCompleteAction, NuSVCCompleteAction, OneClassSVMCompleteAction, SVRCompleteAction } from './../action/compute.action';
import { DataDecoratorAddAction, LegendFilterAddAction } from './../action/graph.action';
import { LoaderHideAction } from './../action/layout.action';
import { ComputeService } from './../service/compute.service';
import { DataService } from './../service/data.service';
import { Store } from '@ngrx/store';
export class ComputeEffect {
    constructor(actions$, store$, computeService, dataService) {
        this.actions$ = actions$;
        this.store$ = store$;
        this.computeService = computeService;
        this.dataService = dataService;
        this.loadEdges = this.actions$.pipe(ofType(compute.COMPUTE_EDGES)).pipe(map((action) => action.payload), switchMap((payload) => {
            return this.computeService.edges(payload['config']).pipe(switchMap(result => {
                return observableOf(result === null
                    ? new NullDataAction()
                    : new EdgesCompleteAction({
                        config: result.config,
                        data: result.data
                    }));
            }));
        }));
        // @Effect() selectMarkers: Observable<any> = this.actions$
        //   .ofType(compute.COMPUTE_SELECT_MARKERS)
        //   .map((action: UnsafeAction) => action.payload)
        //   .withLatestFrom(this.store$)
        //   .switchMap((value: [any, State], index: number) => {
        //     const markerIds = value[0].markers;
        //     const database = value[1].graphA.config.database;
        //     return Observable.fromPromise(this.dataService.getMarkerStatsText(database, markerIds))
        //       .mergeMap(data => {
        //         return [
        //           new compute.SelectMarkersCompleteAction({ selection: { ids: value[0].markers, type: EntityTypeEnum.GENE }, stats: data })
        //         ];
        //       });
        //   });
        this.selectSamples = this.actions$.pipe(ofType(compute.COMPUTE_SELECT_SAMPLES)).pipe(map((action) => action.payload), withLatestFrom(this.store$), switchMap((value, index) => {
            const sampleIds = value[0].samples;
            const database = value[1].graphA.config.database;
            return observableFrom(this.dataService.getPatientIdsWithSampleIds(database, sampleIds)).pipe(switchMap(patientIds => {
                return observableFrom(this.dataService.getPatientStatsText(database, patientIds, sampleIds)).pipe(mergeMap(data => {
                    return [
                        new compute.SelectSamplesCompleteAction({
                            selection: {
                                ids: value[0].samples,
                                type: EntityTypeEnum.SAMPLE
                            },
                            stats: data
                        })
                    ];
                }));
            }));
        }));
        this.saveSampleSelection = this.actions$.pipe(ofType(compute.COMPUTE_SELECT_SAMPLES_SAVE)).pipe(map((action) => action.payload), withLatestFrom(this.store$), switchMap((value, index) => {
            const payload = {
                database: value[1].graphA.config.database,
                cohort: {
                    n: value[0].name,
                    pids: [],
                    sids: value[0].selection.ids,
                    conditions: {}
                }
            };
            return observableFrom(this.dataService.getPatientIdsWithSampleIds(payload.database, payload.cohort.sids)).pipe(switchMap(data => {
                payload.cohort.pids = data;
                return observableFrom(this.dataService
                    .createCustomCohortFromSelect(payload.database, payload.cohort)
                    .then(v => this.dataService.getCustomCohorts(payload.database))).pipe(mergeMap((args) => {
                    return observableOf(new DataUpdateCohortsAction(args));
                }));
            }));
        }));
        this.saveMarkerSelection = this.actions$.pipe(ofType(compute.COMPUTE_SELECT_MARKERS_SAVE)).pipe(map((action) => action.payload), withLatestFrom(this.store$), switchMap((value, index) => {
            const payload = {
                database: value[1].graphA.config.database,
                geneset: {
                    n: value[0].name,
                    g: value[0].selection.ids
                }
            };
            return observableFrom(this.dataService
                .createCustomGenesetFromSelect(payload.database, payload.geneset)
                .then(v => this.dataService.getCustomGenesets(payload.database))).pipe(mergeMap((args) => {
                return observableOf(new DataUpdateGenesetsAction(args));
            }));
        }));
        this.loadNone = this.actions$.pipe(ofType(compute.COMPUTE_NONE)).pipe(map((action) => action.payload), switchMap(payload => {
            const graphData = {
                result: null,
                resultScaled: null,
                sid: [],
                mid: [],
                pid: [],
                legends: []
            };
            return observableOf(new NoneCompleteAction({ config: payload['config'], data: graphData }));
        }));
        this.loadFa = this.actions$.pipe(ofType(compute.COMPUTE_FA)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.fa(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new FaCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadMds = this.actions$.pipe(ofType(compute.COMPUTE_MDS)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.mds(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new MdsCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadSavedPoints = this.actions$.pipe(ofType(compute.COMPUTE_SAVED_POINTS)).pipe(map((action) => action.payload), switchMap(payload => {
            let saved_points_config_no_reuse = JSON.parse(JSON.stringify(payload['config']));
            saved_points_config_no_reuse.reuseLastComputation = false;
            return this.computeService.savedPoints(saved_points_config_no_reuse).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new SavedPointsCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadTableLoader = this.actions$.pipe(ofType(compute.COMPUTE_TABLE_LOADER)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.tableLoader(payload['config']).pipe(mergeMap(result => {
                Khonsole.warn("*** loadTableLoader @Effect hit.");
                let storedMapData = result.config.mapData;
                let tableData = result.data.matrix;
                let tableName = result.config.tableName;
                let thisLoadedTable = {
                    map: storedMapData,
                    data: tableData
                };
                WorkspaceComponent.instance.setLoadedTable(tableName, thisLoadedTable);
                return [
                    result === null
                        ? new NullDataAction()
                        : new TableLoaderCompleteAction({
                            config: result.config,
                            data: '<<BLOB>>' //result.data
                        })
                ];
            }));
        }));
        this.loadMiniBatchDictionaryLearning = this.actions$
            .pipe(ofType(compute.COMPUTE_MINI_BATCH_DICTIONARY_LEARNING))
            .pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.miniBatchDictionaryLearning(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new MiniBatchDictionaryLearningCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadMiniBatchSparsePca = this.actions$.pipe(ofType(compute.COMPUTE_MINI_BATCH_SPARSE_PCA)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.miniBatchSparsePca(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new MiniBatchSparsePcaCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadLinearDiscriminantAnalysis = this.actions$
            .pipe(ofType(compute.COMPUTE_LINEAR_DISCRIMINANT_ANALYSIS))
            .pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.linearDiscriminantAnalysis(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new LinearDiscriminantAnalysisCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadQuadraticDiscriminantAnalysis = this.actions$
            .pipe(ofType(compute.COMPUTE_QUADRATIC_DISCRIMINANT_ANALYSIS))
            .pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.linearDiscriminantAnalysis(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new QuadraticDiscriminantAnalysisCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadTsne = this.actions$.pipe(ofType(compute.COMPUTE_TSNE)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.tsne(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new TsneCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadUmap = this.actions$.pipe(ofType(compute.COMPUTE_UMAP)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.umap(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new compute.UmapCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadScatter = this.actions$.pipe(ofType(compute.COMPUTE_SCATTER)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.scatter(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new compute.ScatterCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadPca = this.actions$.pipe(ofType(compute.COMPUTE_PCA)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.pca(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new PcaCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadPathways = this.actions$.pipe(ofType(compute.COMPUTE_PATHWAYS)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.pathways(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new PathwaysCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadProteins = this.actions$.pipe(ofType(compute.COMPUTE_PROTEIN)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.proteins(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new compute.ProteinCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadSurvival = this.actions$.pipe(ofType(compute.COMPUTE_SURVIVAL)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.survival(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new SurvivalCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadHazard = this.actions$.pipe(ofType(compute.COMPUTE_HAZARD)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.hazard(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new HazardCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadHistogram = this.actions$.pipe(ofType(compute.COMPUTE_HISTOGRAM)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.histogram(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new HistogramCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadSom = this.actions$.pipe(ofType(compute.COMPUTE_SOM)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.pca(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new SomCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadChromosome = this.actions$.pipe(ofType(compute.COMPUTE_CHROMOSOME)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.chromosome(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new ChromosomeCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadGenome = this.actions$.pipe(ofType(compute.COMPUTE_GENOME)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.genome(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new GenomeCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadTimelines = this.actions$.pipe(ofType(compute.COMPUTE_TIMELINES)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.timelines(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new TimelinesCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadHeatmap = this.actions$.pipe(ofType(compute.COMPUTE_HEATMAP)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.heatmap(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new HeatmapCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadDendogram = this.actions$.pipe(ofType(compute.COMPUTE_DENDOGRAM)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.dendogram(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new DendogramCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadBoxWhiskers = this.actions$.pipe(ofType(compute.COMPUTE_BOX_WHISKERS)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.boxWhiskers(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new BoxWhiskersCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadParallelCoords = this.actions$.pipe(ofType(compute.COMPUTE_PARALLEL_COORDS)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.boxWhiskers(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new ParallelCoordsCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadLinkedGene = this.actions$.pipe(ofType(compute.COMPUTE_LINKED_GENE)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.linkedGene(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new LinkedGeneCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadHic = this.actions$.pipe(ofType(compute.COMPUTE_HIC)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.hic(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new HicCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadDictionaryLearning = this.actions$.pipe(ofType(compute.COMPUTE_DICTIONARY_LEARNING)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.dictionaryLearning(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new DictionaryLearningCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadTruncatedSvd = this.actions$.pipe(ofType(compute.COMPUTE_TRUNCATED_SVD)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.truncatedSvd(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new TruncatedSvdCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadFastIca = this.actions$.pipe(ofType(compute.COMPUTE_FAST_ICA)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.fastIca(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new FastIcaCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadLda = this.actions$.pipe(ofType(compute.COMPUTE_LDA)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.lda(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new LdaCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadNmf = this.actions$.pipe(ofType(compute.COMPUTE_NMF)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.nmf(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new NmfCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadLocalLinearEmbedding = this.actions$.pipe(ofType(compute.COMPUTE_LOCAL_LINEAR_EMBEDDING)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.nmf(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new LocalLinearEmbeddingCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadIsoMap = this.actions$.pipe(ofType(compute.COMPUTE_ISO_MAP)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.isoMap(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new IsoMapCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadSpectralEmbedding = this.actions$.pipe(ofType(compute.COMPUTE_SPECTRAL_EMBEDDING)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.spectralEmbedding(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new SpectralEmbeddingCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadPcaIncremental = this.actions$.pipe(ofType(compute.COMPUTE_PCA_INCREMENTAL)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.pcaIncremental(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new PcaIncrementalCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadPcaKernal = this.actions$.pipe(ofType(compute.COMPUTE_PCA_KERNAL)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.pcaKernal(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new PcaKernalCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadPcaSparse = this.actions$.pipe(ofType(compute.COMPUTE_PCA_SPARSE)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.pcaSparse(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new PcaSparseCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadPlsSvd = this.actions$.pipe(ofType(compute.COMPUTE_PLS_SVD)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.plsSvd(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new PlsSvdCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadPlsRegression = this.actions$.pipe(ofType(compute.COMPUTE_PLS_REGRESSION)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.plsRegression(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new PlsRegressionCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadPlsCanonical = this.actions$.pipe(ofType(compute.COMPUTE_PLS_CANONICAL)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.plsCanonical(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new PlsCanonicalCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadCCA = this.actions$.pipe(ofType(compute.COMPUTE_CCA)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.CCA(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new CCACompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadLinearSVC = this.actions$.pipe(ofType(compute.COMPUTE_LINEAR_SVC)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.LinearSVC(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new LinearSVCCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadLinearSVR = this.actions$.pipe(ofType(compute.COMPUTE_LINEAR_SVR)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.LinearSVR(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new LinearSVRCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadNuSVR = this.actions$.pipe(ofType(compute.COMPUTE_NU_SVR)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.NuSVR(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new NuSVRCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadNuSVC = this.actions$.pipe(ofType(compute.COMPUTE_NU_SVC)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.NuSVC(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new NuSVCCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadOneClassSVM = this.actions$.pipe(ofType(compute.COMPUTE_ONE_CLASS_SVM)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.OneClassSVM(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new OneClassSVMCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.loadSVR = this.actions$.pipe(ofType(compute.COMPUTE_SVR)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.computeService.SVR(payload['config']).pipe(mergeMap(result => {
                return [
                    result === null
                        ? new NullDataAction()
                        : new SVRCompleteAction({
                            config: result.config,
                            data: result.data
                        }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.addDataDecorator = this.actions$.pipe(ofType(graph.DATA_DECORATOR_CREATE)).pipe(map((action) => action.payload), switchMap(payload => {
            return this.dataService.createDataDecorator(payload.config, payload.decorator).pipe(mergeMap(result => {
                return [
                    new DataDecoratorAddAction({
                        config: payload.config,
                        decorator: result
                    }),
                    new LoaderHideAction()
                ];
            }));
        }));
        this.addLegendFilter = this.actions$.pipe(ofType(graph.LEGEND_FILTER_CREATE)).pipe(map((action) => action.payload), switchMap(payload => {
            Khonsole.warn("*** TBD: addLegendFilter switchMap ***");
            return this.dataService.createLegendFilter(payload.config, payload.legendFilter).pipe(mergeMap(result => {
                Khonsole.log('mergemap in addLegendFilter');
                return [
                    new LegendFilterAddAction({
                        config: payload.config,
                        legendFilter: result
                    }),
                    new LoaderHideAction()
                ];
            }));
        }));
    }
}
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadEdges", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "selectSamples", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "saveSampleSelection", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "saveMarkerSelection", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadNone", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadFa", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadMds", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadSavedPoints", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadTableLoader", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadMiniBatchDictionaryLearning", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadMiniBatchSparsePca", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadLinearDiscriminantAnalysis", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadQuadraticDiscriminantAnalysis", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadTsne", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadUmap", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadScatter", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadPca", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadPathways", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadProteins", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadSurvival", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadHazard", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadHistogram", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadSom", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadChromosome", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadGenome", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadTimelines", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadHeatmap", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadDendogram", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadBoxWhiskers", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadParallelCoords", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadLinkedGene", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadHic", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadDictionaryLearning", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadTruncatedSvd", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadFastIca", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadLda", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadNmf", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadLocalLinearEmbedding", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadIsoMap", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadSpectralEmbedding", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadPcaIncremental", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadPcaKernal", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadPcaSparse", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadPlsSvd", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadPlsRegression", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadPlsCanonical", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadCCA", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadLinearSVC", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadLinearSVR", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadNuSVR", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadNuSVC", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadOneClassSVM", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "loadSVR", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "addDataDecorator", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], ComputeEffect.prototype, "addLegendFilter", void 0);
