/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-panel.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/extended";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "./info-panel.component";
import * as i6 from "../../../service/data.service";
import * as i7 from "../../../service/modal-service";
var styles_InfoPanelComponent = [i0.styles];
var RenderType_InfoPanelComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_InfoPanelComponent, data: {} });
export { RenderType_InfoPanelComponent as RenderType_InfoPanelComponent };
function View_InfoPanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "infoPanelKey"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Reload:"])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["style", "display: none;"], ["title", "Click to reload this project, or right-click to save it as a bookmark."]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "i", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(9, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(-1, null, ["\u00A0\u00A0\u00A0"]))], function (_ck, _v) { var currVal_2 = "fa fa-link"; _ck(_v, 8, 0, currVal_2); var currVal_3 = "fa fa-link"; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getRedirectReloadUrl(); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.getRedirectReloadName(); _ck(_v, 5, 0, currVal_1); }); }
function View_InfoPanelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "infoPanelKey"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "infoPanelValue"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.key; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.value; _ck(_v, 4, 0, currVal_1); }); }
function View_InfoPanelComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["aria-label", "Basic radio toggle button group"], ["class", "btn-group"], ["role", "group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["autocomplete", "off"], ["class", "btn-check btn-sm"], ["name", "btnradio"], ["type", "radio"]], [[8, "id", 0], [8, "checked", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewButtonsClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [["class", "btn btn-outline-primary btn-sm"]], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "btnradio_", _v.context.$implicit.id, ""); var currVal_1 = _co.isCurrentButton(_v.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "btnradio_", _v.context.$implicit.id, ""); _ck(_v, 2, 0, currVal_2); var currVal_3 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_3); }); }
function View_InfoPanelComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "chart-view-controls-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "chart-view-controls"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["View:\u00A0\u00A0 "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoPanelComponent_4)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.savedpoints_view_details; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_InfoPanelComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "infoPanel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoPanelComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoPanelComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoPanelComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkDatasetisProject(); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.ConfigAIsSavedPointsVisualization() && _co.DataLoadedActionHasMultipleSavedPoints()); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_InfoPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workspace-info-panel", [], null, null, null, View_InfoPanelComponent_0, RenderType_InfoPanelComponent)), i1.ɵdid(1, 4440064, null, 0, i5.InfoPanelComponent, [i1.ChangeDetectorRef, i6.DataService, i7.ModalService, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InfoPanelComponentNgFactory = i1.ɵccf("app-workspace-info-panel", i5.InfoPanelComponent, View_InfoPanelComponent_Host_0, { workspaceConfig: "workspaceConfig", graphAConfig: "graphAConfig", graphBConfig: "graphBConfig" }, {}, []);
export { InfoPanelComponentNgFactory as InfoPanelComponentNgFactory };
