/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/slide-toggle/typings/index.ngfactory";
import * as i2 from "@angular/material/slide-toggle";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/cdk/bidi";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/common";
import * as i8 from "./survival.form.component";
var styles_SurvivalFormComponent = [];
var RenderType_SurvivalFormComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SurvivalFormComponent, data: {} });
export { RenderType_SurvivalFormComponent as RenderType_SurvivalFormComponent };
function View_SurvivalFormComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["style", "padding-bottom:3px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "mat-slide-toggle", [["class", "mat-slide-toggle"]], [[8, "id", 0], [1, "tabindex", 0], [1, "aria-label", 0], [1, "aria-labelledby", 0], [2, "mat-checked", null], [2, "mat-disabled", null], [2, "mat-slide-toggle-label-before", null], [2, "_mat-animation-noopable", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "ngModelChange"], [null, "checkedChange"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2)._inputElement.nativeElement.focus() !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.compareChange($event) !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_v.context.$implicit.sel = $event) !== false);
        ad = (pd_2 && ad);
    } if (("checkedChange" === en)) {
        var pd_3 = ((_v.context.$implicit.sel = $event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_MatSlideToggle_0, i1.RenderType_MatSlideToggle)), i0.ɵdid(2, 1228800, null, 0, i2.MatSlideToggle, [i0.ElementRef, i3.FocusMonitor, i0.ChangeDetectorRef, [8, null], i0.NgZone, i2.MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, [2, i4.ANIMATION_MODULE_TYPE], [2, i5.Directionality]], { checked: [0, "checked"] }, { change: "change" }), i0.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.MatSlideToggle]), i0.ɵdid(4, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i0.ɵdid(6, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), (_l()(), i0.ɵted(7, 0, ["", ""]))], function (_ck, _v) { var currVal_15 = _v.context.$implicit.sel; _ck(_v, 2, 0, currVal_15); var currVal_16 = _v.context.$implicit.sel; _ck(_v, 4, 0, currVal_16); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).id; var currVal_1 = (i0.ɵnov(_v, 2).disabled ? null : (0 - 1)); var currVal_2 = null; var currVal_3 = null; var currVal_4 = i0.ɵnov(_v, 2).checked; var currVal_5 = i0.ɵnov(_v, 2).disabled; var currVal_6 = (i0.ɵnov(_v, 2).labelPosition == "before"); var currVal_7 = (i0.ɵnov(_v, 2)._animationMode === "NoopAnimations"); var currVal_8 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_9 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_10 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_11 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_12 = i0.ɵnov(_v, 6).ngClassValid; var currVal_13 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_14 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14]); var currVal_17 = _v.context.$implicit.n; _ck(_v, 7, 0, currVal_17); }); }
export function View_SurvivalFormComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["style", "color:rgba(0, 0, 0, 0.87);font-size:10px;padding-bottom:5px;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Compare To"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SurvivalFormComponent_1)), i0.ɵdid(3, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._cohortOptions; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_SurvivalFormComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-survival-form", [], null, null, null, View_SurvivalFormComponent_0, RenderType_SurvivalFormComponent)), i0.ɵdid(1, 180224, null, 0, i8.SurvivalFormComponent, [i0.ChangeDetectorRef], null, null)], null, null); }
var SurvivalFormComponentNgFactory = i0.ɵccf("app-survival-form", i8.SurvivalFormComponent, View_SurvivalFormComponent_Host_0, { config: "config", cohorts: "cohorts" }, { configChange: "configChange" }, []);
export { SurvivalFormComponentNgFactory as SurvivalFormComponentNgFactory };
