/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./chart.scene";
import * as i3 from "./chart.component";
import * as i4 from "@ngrx/store";
import * as i5 from "./chart.factory";
var styles_ChartComponent = [i0.styles];
var RenderType_ChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChartComponent, data: {} });
export { RenderType_ChartComponent as RenderType_ChartComponent };
export function View_ChartComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { container: 0 }), i1.ɵqud(402653184, 2, { labelsA: 0 }), i1.ɵqud(402653184, 3, { labelsB: 0 }), i1.ɵqud(402653184, 4, { labelsE: 0 }), i1.ɵqud(402653184, 5, { labelsAContainer: 0 }), i1.ɵqud(402653184, 6, { labelsBContainer: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["style", "position:absolute;left:0px;top:0px;right:0px;bottom:0px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, [[1, 0], ["container", 1]], null, 0, "div", [["oncoid", "AandBContainer"], ["style", "position:absolute; width:100%; height:100vh;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, [[5, 0], ["labelAContainer", 1]], null, 1, "div", [["oncoid", "labelAContainer"], ["style", "width:50%; height:100vh; pointer-events:none;position:absolute;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, [[2, 0], ["labelsA", 1]], null, 0, "div", [["style", "position:relative;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, [[6, 0], ["labelBContainer", 1]], null, 1, "div", [["oncoid", "labelAContainer"], ["style", "width:50%; left: 50%; height:100vh; pointer-events:none;position:absolute;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, [[3, 0], ["labelsB", 1]], null, 0, "div", [["style", "position:relative;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, [[4, 0], ["labelsE", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_ChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-workspace-chart", [], null, null, null, View_ChartComponent_0, RenderType_ChartComponent)), i1.ɵprd(4608, null, i2.ChartScene, i2.ChartScene, []), i1.ɵdid(2, 4243456, null, 0, i3.ChartComponent, [i1.NgZone, i4.Store, i1.ChangeDetectorRef, i5.ChartFactory], null, null)], null, null); }
var ChartComponentNgFactory = i1.ɵccf("app-workspace-chart", i3.ChartComponent, View_ChartComponent_Host_0, {}, { onSelect: "onSelect", configChange: "configChange" }, []);
export { ChartComponentNgFactory as ChartComponentNgFactory };
