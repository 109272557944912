import { Khonsole } from 'app/khonsole';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewChild,
  Input,
  OnChanges,
  OnDestroy,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
  EventEmitter
} from '@angular/core';

import * as _ from 'lodash';
import { GraphConfig } from '../../../model/graph-config.model';
import { DataDecorator, LegendFilter } from './../../../model/data-map.model';
import { Legend } from './../../../model/legend.model';
import { ChartFactory } from '../chart/chart.factory';
import { count } from 'rxjs/operators';
import * as THREE from 'three';
import { CommonSidePanelComponent } from '../common-side-panel/common-side-panel.component';
import { SelectionModifiers } from 'app/component/visualization/visualization.abstract.scatter.component';
import { OncoData } from 'app/oncoData';
import { debug } from 'console';
import { AnalyticsProvider } from 'aws-amplify';
import { ChartScene } from '../chart/chart.scene';
import { VisualizationView } from '../../../model/chart-view.model';
import { AbstractScatterVisualization } from '../../visualization/visualization.abstract.scatter.component';
import { LegendItemComponent } from '../legend-item/legend-item.component';
import { VisualizationEnum } from 'app/model/enum.model';
import { MatTooltip } from '@angular/material/tooltip';
import { of } from 'rxjs';
import { text } from 'd3';
// import Plotly from 'plotly.js-dist';

@Component({
  selector: 'app-workspace-legend-panel',
  templateUrl: './legend-panel.component.html',
  styleUrls: ['./legend-panel.component.v002.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None
})
export class LegendPanelComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChildren(LegendItemComponent) legendItems: QueryList<LegendItemComponent>
  // Using ViewChildren to query for MatTooltip instances
  @ViewChildren(MatTooltip) legendTooltipsQuery: QueryList<MatTooltip>;

  // Array to hold MatTooltip instances
  legendTooltips: MatTooltip[] = [];

  public static setLegends = new EventEmitter<{
    legend: Array<Legend>;
    graph: number;
  }>();
  public autoUpdate = true;

  public allLegends: Array<Legend> = [];
  public updateLegend = _.debounce(this.update, 600);

  public _config: GraphConfig;
  get config(): GraphConfig {
    return this._config;
  }
  @Input()
  set config(value: GraphConfig) {
    if (value === null) {
      return;
    }

    this._config = value;
    this.updateLegend();
  }

  private _decorators: Array<DataDecorator> = [];
  @Input()
  public set decorators(value: Array<DataDecorator>) {
    if (value === null) {
      return;
    }
    if(this._decorators != value){
      this._decorators = value;
      this.updateLegend();
    }
  }

  public _legends: Array<Legend> = [];
  @Input()
  public set legends(value: Array<Legend>) {
    if (value === null) {
      Khonsole.log(`TEMPNOTE: Input for legend-panel was null.`);
      return;
    }
    this._legends = value;
    this.updateLegend();
  }

  public _legendFilters: Array<LegendFilter> = [];
  @Input()
  public set legendFilters(value: Array<LegendFilter>) {
    if (value === null) {
      Khonsole.log(`TEMPNOTE: Input legendFilters for legend-panel was null.`);
      return;
    }
    this._legendFilters = value;
    this.updateLegendFilters();
  }

  updateLegendFilters(){
    Khonsole.warn('## updateLegendFilters NYI ##');
  }

  public select(): void {}

  public deselect(): void {}

//  @ViewChild('plotlyPlot', { }) plotlyPlot;  // static: true
  ngAfterViewInit(): void {
    // Convert QueryList to an array for easier indexing
    this.legendTooltips = this.legendTooltipsQuery.toArray();

  //   var graphDiv = null;
  //   try {
  //     graphDiv = this.plotlyPlot.nativeElement;
  //   } catch (ex) {
  //     Khonsole.error("ERROR could not access plotlyPlot.nativeElement")
  //   }
  //   var N = 30;
  //   var color1 = '#7b3294';
  //   var color1Light = '#c2a5cf';
  //   var colorX = '#ffa7b5';
  //   var colorY = '#fdae61';

  //   var os_data = {
  //     "resultScaled": [
  //       [
  //         -172.83812373103726,
  //         -145.0974257581311,
  //       ],
  //       [
  //         49.515933482305684,
  //         -2.1165747028978785,
  //       ],
  //       [
  //         300,
  //         177.15640449472272,
  //       ],
  //       [
  //         -300,
  //         116.9263782886535,
  //       ],
  //       [
  //         -230.02463747782,
  //         100.16111612163553,
  //       ],
  //       [
  //         117.58992915907379,
  //         -167.81028864666476,
  //       ],
  //       [
  //         102.94060857035788,
  //         -32.26831425492662,
  //       ],
  //       [
  //         -30.361567269154364,
  //         -210.12915280866557,
  //       ]
  //     ],
  //     "sid": [
  //       "or-a5j1-01a",
  //       "or-a5j3-01a",
  //       "or-a5j4-01a",
  //       "or-a5j5-01a",
  //       "or-a5j6-01a",
  //       "or-a5j7-01a",
  //       "or-a5j8-01a",
  //       "or-a5ja-01a"
  //     ],
  //     "mid": [
  //       "AKT2",
  //       "BRAF",
  //       "BRCA2",
  //       "FAS"
  //     ],
  //     "pid": [
  //       "or-a5j1-01a",
  //       "or-a5j3-01a",
  //       "or-a5j4-01a",
  //       "or-a5j5-01a",
  //       "or-a5j6-01a",
  //       "or-a5j7-01a",
  //       "or-a5j8-01a",
  //       "or-a5ja-01a"
  //     ],
  //     "legends": [
  //       {
  //         "result": null,
  //         "display": "DISCRETE",
  //         "name": "Data Points",
  //         "type": "SHAPE",
  //         "labels": [
  //           "Samples"
  //         ],
  //         "values": [
  //           "./assets/shapes/shape-circle-solid-legend.png"
  //         ],
  //         "counts": [],
  //         "visibility": [
  //           1
  //         ]
  //       }
  //     ]
  //   }

  //   function randomArray() {
  //     var out = new Array(N);
  //     for (var i = 0; i < N; i++) {
  //       out[i] = Math.random();
  //     }
  //     return out;
  //   }
  //   var x = randomArray();
  //   var y = randomArray();

  //   var labels = [
  //     "alpha",
  //     "beta",
  //     "gamma",
  //     "delta"
  //   ]

  //   function modArray() {
  //     var out = new Array(N);
  //     for (var i = 0; i < N; i++) {
  //       out[i] = labels[i % 4]
  //     }
  //     return out;
  //   }
  //   var m = modArray();

  //   if(graphDiv){
  //     Plotly.newPlot(graphDiv, [{
  //       type: 'scatter',
  //       mode: 'markers',
  //       x: x,
  //       y: y,
  //       customdata: m,
  //       // xaxis: 'x',
  //       // yaxis: 'y',
  //       name: '',
  //       opacity: 0.7,
  //       marker: { color: color1, size: 12, line: { width: 2, color: "Black" } },
  //       hovertemplate: 'text: %{customdata}'
  //     }], {
  //       title: 'Scatter',
  //       dragmode: 'lasso',

  //     });

  //     graphDiv.on('plotly_selected', function (eventData) {
  //       Khonsole.log("plotly on selection.")
  //       if (eventData && eventData.points) {
  //         Khonsole.log(eventData.points)
  //       } else {
  //         Khonsole.log("... but no points.")
  //       }
  //       /*
  //       var x = [];
  //       var y = [];

  //       var colors = [];
  //       for (var i = 0; i < N; i++) colors.push(color1Light);

  //       if (eventData && eventData.points) {
  //         Khonsole.log(eventData.points)
  //         eventData.points.forEach(function (pt) {
  //           x.push(pt.x);
  //           y.push(pt.y);
  //           colors[pt.pointNumber] = color1;
  //         });
  //       } */

  // /*
  //       Plotly.restyle(graphDiv, {
  //         x: [x, y],
  //         xbins: {}
  //       }, [1, 2]);

  //       if(window["barf"] == null){
  //         Plotly.restyle(graphDiv, 'marker.color', [colors], [0]);
  //       }*/
  //     });

  //   }
  //     function sel() {
  //       // alert(os_data.resultScaled.length)
  //       var newX = os_data.resultScaled.map(x => x[0])
  //       var newY = os_data.resultScaled.map(x => x[1])

  //       Plotly.update('plotlyPlot', { x: [newX], y: [newY] }, [0]);

  //       const selectedPoints = [0, 2, 4];
  //       Plotly.restyle('plotlyPlot', { selectedpoints: [selectedPoints] }, [0]);
  //     }
    }



  ngOnDestroy() {}

  ngOnChanges(params){
    // Khonsole.log("onChanges in legendpanel")
    // Khonsole.dir(params);
  }

  customizeColor(legend: Legend, i:number): void {
    Khonsole.log(`MJ click on legend item [${i}] color box`);
    let color = prompt('Type a color name like red or green, or a web color code like #440080.', '#440080')
    if(color) {
      ChartFactory.writeCustomValueToLocalStorage(this._config.database, 'legendColors', legend.name + '!' + ChartFactory.cleanForLocalStorage(legend.labels[i]), color);
      window.setTimeout(this.update, 100);
    }
  }

  private setLegendItemVisibility(li:LegendItemComponent, i:number, beVisible:boolean){
      li.visibleEyeLevel = beVisible ? 1 : 0
      li.legend.visibility[i] = beVisible ? 1 : 0;
  }

  isntDataPointsPlaceholder(legend){
    let result = true;
    try {
      if(legend.type === 'SHAPE'){
        result = legend.name != "Data Points" && legend.values.length > 1;
      }
    } catch (e){
      Khonsole.error(`Error in isntDataPointsPlaceholder: ${e}`);
    }
    return result;
  }

  allLegendsExceptDataPointsPlaceholder(){
    let self = this;
    let result = this.allLegends.filter(l => self.isntDataPointsPlaceholder(l) );
    return result;
  }

  eyeClickItem(activity: any) { // i, legend, event
    Khonsole.warn('In eyeClickItem, in legend panel.');
    Khonsole.dir(activity)

    if(this.config.isScatterVisualization == false) {
      Khonsole.warn('In eyeClickItem, clicking non-scatter vis. Not yet supported..');
      return;
    }

    // Get current vis of clicked item.
    let currentEyeVis = true;
    this.legendItems.forEach( (li, i) => {
      if(i == activity.i){
        currentEyeVis = li.visibleEyeLevel == 1
      }
    });

    this.legendItems.forEach( (li, i) => {
      if(i == activity.i){
        this.setLegendItemVisibility(li, i, currentEyeVis == false)
      } else {
        if((activity.event as MouseEvent).altKey){
          this.setLegendItemVisibility(li, i, currentEyeVis) // e.g., if item was true, all others now become true.
        }
      }
    });

    Khonsole.warn('== Assuming view 0 in legendItemEyeClick ==');
    let view:VisualizationView = ChartScene.instance.views[0];
    let thisScatterGraph  = view.chart as AbstractScatterVisualization;
    if(thisScatterGraph && thisScatterGraph.isBasedOnAbstractScatter){
      thisScatterGraph.removeInvisiblesFromSelection(view.config, view.chart.decorators);
    } else {
      Khonsole.warn('This vis does not support removeInvisiblesFromSelection.');
    }
    ChartScene.instance.render();
    OncoData.instance.currentCommonSidePanel.drawWidgets();
  }

  countAttempt(lf:LegendFilter){
    Khonsole.warn('in countAttempt');
    Khonsole.dir(lf)
    return 0;
  }

  formattedLegendItemText(legend:Legend, i:number){
//    {{label}}{{legend.counts && legend.counts[i]?'&nbsp;&nbsp;&nbsp;('+ selectionOf(legend,i)+ legend.counts[i]+')':''
    const itemCountsExist = legend.counts && legend.counts[i];
    let txt = `${legend.labels[i]}${itemCountsExist?' ('+ this.selectionOf(legend,i)+ legend.counts[i]+')':''}`;
    return txt;
  }

  legendItemHasSelectedPoints(legend:Legend, i:number){
    if (legend.selectionCounts && legend.selectionCounts[i] && legend.selectionCounts[i]>0) {
      return true;
    } else {
      return false;
    }
  }

  selectionOf(legend:Legend, i:number){  // Returns "3 of " part of "3 of 5".
    if (legend.selectionCounts) {
      let count = legend.selectionCounts[i];
      if (count == 0) { // none selected
        return ""
      } else {
        return `${count} of `
      }
    } else {
      return ""
    }
  }

  smarterName(name:string){
    if(name){
      if (name.startsWith("Color By ColorBy:")){
        name = name.replace("ColorBy:", "");
        let equalStart = name.indexOf("=")
        name = "Color By " + name.substring("color By ".length, equalStart).toUpperCase()
          + name.substring(equalStart);
        return name;
      } else {
        return name;
      }
    } else {
      // name is null
      Khonsole.warn("Unnamed Legend in smarterName()");
      return "Unnamed Legend"
    }
  }

  clickedPidsFromLegendItem(legend: Legend, i: number, visEnum: VisualizationEnum): any {
    let dec:DataDecorator = ChartScene.instance.views[0].chart.decorators.find(d => d.field && (d.field.label == legend.name));
    if (dec == null) {
      dec = ChartScene.instance.views[0].chart.decorators.find(d => d.field && ( "Color By " + d.field.label == legend.name));
    }
    if (dec == null) {
      Khonsole.warn('Null decorator in clickedPidsFromLegendItem.');
      return;
    }

    if (dec.pidsByLabel != null) {
      let clickedColorValue: string = legend.values[i];
      let clickedPids = dec.pidsByLabel.find(v => v.label == clickedColorValue);
      if (!clickedPids) {
        let a: THREE.Color = new THREE.Color(clickedColorValue);
        let colorInt = a.getHex();
        clickedPids = dec.pidsByLabel.find(v => v.label == colorInt.toString());
      }
      if (clickedPids) {
        if (clickedPids.pids) {
          Khonsole.log(`clickedPids.length = ${clickedPids.pids.length}.`)
        } else {
          Khonsole.warn('clickedPids is undefined in clickedPidsFromLegendItem.')
        }
        // Khonsole.dir(clickedPids);
        return clickedPids;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  legendItemClick(legend: Legend, i:number): void {
    Khonsole.log(`MJ click on legend item [${i}] text itself`);
    let patientIds = null;
    // build list of matching patient IDs, then pass it off to
    // commonSidePanel.setSelectionPatientIds.
    // List length should match legend.counts[i].
    let clickedLabel = this.clickedPidsFromLegendItem(legend, i, ChartScene.instance.views[0].config.visualization);

    if(clickedLabel==null && ChartScene.instance.views[0].config.visualization==VisualizationEnum.TIMELINES && window['clickedPidsPerEvent'] ) {
      let basekey= legend.name; // legend.name.startsWith("ROW // ") ? legend.name.substring("ROW // ".length) : legend.name;
      Khonsole.log(`basekey=${basekey}.`)
      let subtype = legend.labels[i];
      let typeSubtype = (basekey+":"+subtype).toLowerCase()
      let typeSubtypeArray = window['clickedPidsPerEvent'][typeSubtype]
      if (typeSubtypeArray == null) {
        typeSubtypeArray = window['clickedPidsPerEvent'][typeSubtype+":"]
      }
      if (typeSubtypeArray == null) {
        typeSubtypeArray = window['clickedPidsPerEvent']["row // " + typeSubtype]
      }
      if (typeSubtypeArray == null) {
        typeSubtypeArray = window['clickedPidsPerEvent']["row // " + typeSubtype + ":"]
      }
      if (typeSubtypeArray == null) {
        // Try removing "row // " from front.
        if (typeSubtype.startsWith("row // ")){
          let typeSubtypeWithoutRow = typeSubtype.substring(7);
          typeSubtypeArray = window['clickedPidsPerEvent'][typeSubtypeWithoutRow]
          if (typeSubtypeArray == null) {
            typeSubtypeArray = window['clickedPidsPerEvent'][typeSubtypeWithoutRow + ":"]
          }
        }
      }
      if(typeSubtypeArray){
        let pids:Array<string> = Array.from(typeSubtypeArray)
        patientIds = pids
      }
    }

    if(clickedLabel) {
      patientIds = clickedLabel.pids;
    }
    if(patientIds != null){
      let mouseEvent:any = event;
      let selectionModifiers:SelectionModifiers = new SelectionModifiers();
      selectionModifiers.extend = mouseEvent.shiftKey;
      selectionModifiers.inverse = mouseEvent.altKey;

      window.setTimeout(() => {
        OncoData.instance.currentCommonSidePanel.setSelectionPatientIds(patientIds,
          "Legend", selectionModifiers, null);
      }, 20);
      OncoData.instance.currentCommonSidePanel.drawWidgets();

    } else {
      Khonsole.log('Click on label did not resolve by color.');
    }

    // // from commonsidepanel...
    // if(index <= this.definedCohorts.length) {
    //   // this.svg._groups[0][0].getElementsByClassName('km-curve')[0]
    //   let mouseEvent:any = event;
    //   let c = this.definedCohorts[index];
    //   let selectionModifiers:SelectionModifiers = new SelectionModifiers();
    //   selectionModifiers.extend = mouseEvent.shiftKey;
    //   selectionModifiers.inverse = mouseEvent.altKey;

    //   window.setTimeout(() => {
    //     this.setSelectionPatientIds(c.pids, c, selectionModifiers);
    //   }, 20);
    // } else {
    //   alert(`Could not find defined cohort with index of ${index}.`);
    // }

    window.setTimeout(this.update, 100);
  }


  legendFormatter(legend: Legend): Legend {
    if(legend == null){
      Khonsole.error("Expected legend in legendFormatter().")
      return null;
    } else {
      if (legend.values == null) {
        Khonsole.error("Expected legend.values in legendFormatter().")
        return legend
      }

      const rv:Legend = Object.assign({}, legend);
      if(rv.values == null){
        Khonsole.error("NOT valid legend in legendFormatter. 363732");
        alert("Not valid legend in legendFormatter")
        return legend;
      }
      if (rv.type === 'COLOR') {
        for (let i = 0; i < rv.values.length; i++) {
          if (!isNaN(rv.values[i])) {
            legend.values[i] =
              '#' + (0xffffff + legend.values[i] + 1).toString(16).substr(1);
          }
        }
      } else if (legend.type === 'SHAPE') {
        for (let i = 0; i < rv.values.length; i++) {
          if (!isNaN(rv.values[i])) {
            legend.values[i] =
              'https://oncoscape.v3.sttrcancer.org/assets/shapes/shape-' +
              legend.values[i] +
              '-solid-legend.png';
          }
        }
      }
      return rv;
    }
  }

  public isColorLegend(legend: Legend): boolean {
    return legend.type === 'COLOR';
  }

  public update(): void {
    let self = this;
    if (!self.autoUpdate) {
      return;
    }
    const legendsFromDecorators = self._decorators.map(decorator =>
      self.legendFormatter(decorator.legend)
    );

    try {
      const coreLegends = self._legends.map(legend => self.legendFormatter(legend));
    this.allLegends = [].concat(...legendsFromDecorators, ...coreLegends);

    Khonsole.warn(`UPDATE LEGENDS.....`);
    Khonsole.dir(this.allLegends);
    let colorLegend = this.allLegends.find(l => l.type == 'COLOR');
    if(colorLegend){
      let smartName = self.smarterName(colorLegend.name)
      Khonsole.warn(`smartName = ${smartName}.`)
      let header = document.querySelector('.color-legend-header') as HTMLElement;
      if(header){
        let cleanHeader    = header.innerText.trim().toLowerCase().replace(/ /g, "_");
        let cleanSmartName = smartName.trim().toLowerCase().replace(/ /g, "_");
        if (cleanHeader != cleanSmartName) {
          header.classList.remove('fade-text-animation');
          // Trigger a reflow in between removing and adding the class
          const forceReflow =  header.offsetWidth;
          header.scrollTop = header.scrollTop;
          header.classList.add('fade-text-animation');
        }
      } else {
        Khonsole.error("Could not find color-legend-header.")
      }
    }

    // // // // Here is where we want to substitute custom colors in.
    // // // this.allLegends.map ( legend => {
    // // //   if (legend.type =='COLOR' && legend.labels) {
    // // //     for (let label in legend.labels) {
    // // //       let cleanLabel:string = this.cleanForLocalStorage(legend.labels[label]);
    // // //       let customColor = this.readCustomValueFromLocalStorage('legendColors', legend.name + '!' + cleanLabel);
    // // //       if(customColor) {
    // // //         Khonsole.log(`customcolor = ${customColor}.`);
    // // //         legend.values[label] = customColor;
    // // //       }
    // // //     }
    // // //   }
    // // // });

    this.cd.detectChanges();
    } catch (err) {
      Khonsole.error(`TEMPNOTE: error in legend update, probably bad _legends. ${err}`);
    }
  }
  /**
   * @description Display the tooltip for the legend item.
   * @param i The index of the legend item.
   */
  displayTooltip(tooltip: MatTooltip){
    tooltip.disabled = false;
    tooltip.show()
  }

  /**
   * @description Hide the tooltip for the legend item.
   * @param i The index of the legend item.
   */
  hideTooltip(tooltip: MatTooltip) {
    tooltip.disabled = true;
    tooltip.hide()
  }

  trackLegendByFn(index, legend:Legend) {
    return legend.type;  // color, shape, etc., because we only have one of each type max.
  }

  onSetLegends(e: { legends: Array<Legend>; graph: number }): void {
    if (this.config.graph !== e.graph) {
      return;
    }
    this.autoUpdate = false;
    this.allLegends = [].concat(...e.legends); //e.legends;
    this.cd.detectChanges();
  }
  constructor(public cd: ChangeDetectorRef) {
    LegendPanelComponent.setLegends.subscribe(this.onSetLegends.bind(this));
  }


}
