import { DimensionEnum } from './../../../model/enum.model';
import { GraphConfig } from './../../../model/graph-config.model';
export class DeConfigModel extends GraphConfig {
    constructor() {
        super(...arguments);
        this.dimension = DimensionEnum.THREE_D;
        this.domain = [-500, 500];
        this.showVectors = false;
        this.latientVectors = 10;
        this.tolerance = 1e-4;
    }
}
