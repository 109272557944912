import { DimensionEnum, DistanceEnum } from 'app/model/enum.model';
import { VisualizationAbstractScatterConfigModel } from "../visualization.abstract.scatter.model";
export class SomConfigModel extends VisualizationAbstractScatterConfigModel {
    constructor() {
        super(...arguments);
        this.dimension = DimensionEnum.THREE_D;
        this.tau = 0.02;
        this.iterations = 1;
        this.dist = DistanceEnum.EUCLIDEAN;
        this.xCells = 2;
        this.yCells = 1;
        this.weights = [];
    }
}
