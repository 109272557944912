import { Khonsole } from 'app/khonsole';
import { Preprocessing } from 'app/model/preprocessing.model';
import { DataField } from 'app/model/data-field.model';
import { GraphEnum, VisualizationEnum, LegendOptionEnum } from 'app/model/enum.model';
import { DataFieldFactory, DataTable } from './data-field.model';
import { EntityTypeEnum } from './enum.model';
import { GraphData } from './graph-data.model';
import { LegendOption } from './legend-option.model';
import { GeneSet } from 'app/model/gene-set.model';

export interface Graph {
  config: GraphConfig;
  data: GraphData;
}

/**
 * Represents the configuration for a graph.
 */
export class GraphConfig {
  // TODO:   Need to fix this? MJ  TODO TBD
  public static database = 'gbm';
  public static datasetName = '';

  hasAppliedMetafileOverrides:boolean = false;
  public isScatterVisualization:boolean = false;

  dirtyFlag = 1;
  visualization: VisualizationEnum;
  entity: EntityTypeEnum;
  graph: GraphEnum;
  table: DataTable;
  label = '';
  enableCohorts = true;
  enableGenesets = true;
  enablePathways = false;
  enablePreprocessing = true;
  enableBehaviors = false;
  enableSupplemental = true;

  // Default Legend Options values.
  private _legendOptions: LegendOption<any>[] = [
    {
      type: LegendOptionEnum.MARKER_BASE_SIZE,
      enabled: false,
      defaultValue: 9,
    },
    {
      type: LegendOptionEnum.MARKER_OPACITY,
      enabled: false,
      defaultValue: 0.75,
    },
    {
      type: LegendOptionEnum.SHAPE,
      enabled: true,
      // Represents "None"
      defaultValue: DataFieldFactory.getUndefined()
    },
    {
      type: LegendOptionEnum.SIZE,
      enabled: false,
      // Represents "None"
      defaultValue: DataFieldFactory.getUndefined()
    },
    {
      type: LegendOptionEnum.COLOR,
      enabled: true,
      // Represents "None"
      defaultValue: DataFieldFactory.getUndefined()
    },
    {
      type: LegendOptionEnum.GENESET_OVERLAY,
      enabled: false,
      // No default value here. This is just a flag to indicate that the geneset overlay is enabled.
      // The values are in config.firstGenesetOverlay and config.secondGenesetOverlay
    },
    {
      type: LegendOptionEnum.LABEL,
      enabled: false
    }
  ];
  public get legendOptions(): LegendOption<any>[] {
    return this._legendOptions;
  }

  /**
   * Sets the legend options for this graph config. Any options not specified will use the default values.
   * If any option is disabled by default and you enable it without specifying a value, it will use the default value.
   */
  public set legendOptions(value: LegendOption<any>[]) {
    // combine the default options with the new options, matching each type field
    const newOptions = [];
    for (const defaultOption of this._legendOptions) {
      const newOption = value.find(option => option.type === defaultOption.type);
      if (newOption) {
        newOptions.push({...defaultOption, ...newOption});
      } else {
        newOptions.push(defaultOption);
      }
    }

    // set the new options
    this._legendOptions = newOptions;
  }

  uiOptions:any = {};
  reuseLastComputation:boolean = false;

  patientSelect: Array<string> = [];
  patientFilter: Array<string> = [];
  sampleSelect: Array<string> = [];
  markerSelect: Array<string> = [];
  sampleFilter: Array<string> = [];

  firstGenesetOverlay:GeneSet = {n: 'None', g: []};
  secondGenesetOverlay:GeneSet = {n: 'None', g: []};
  proteinName = '2POR';
  cohortName = 'All Patients';
  markerName = 'Pathways in Cancer';
  pathwayName = 'integrated cancer pathway';
  pathwayUri =
    'https://oncoscape.v3.sttrcancer.org/data/pathways/http___identifiers.org_panther.pathway_P02742.json.gz';

  // Default Genes Listed Are 'KEGG Pathways In Cancer'
  markerFilter: Array<string> = [
    'ABCG2',
    'AHNAK2',
    'AKT1',
    'ARHGAP26',
    'ASNS',
    'ATP2B3',
    'BCL11A',
    'BCL2',
    'BCL6',
    'BCOR',
    'BMI1',
    'BRAF',
    'BRCA1',
    'BRD4',
    'CACNA1D',
    'CAMTA1',
    'CARD11',
    'CBLB',
    'CCND3',
    'CD44',
    'CDKN2C',
    'CIITA',
    'COL1A1',
    'CREBBP',
    'CUL1',
    'DAXX',
    'DDX5',
    'DMRTA1',
    'DNAH5',
    'DOCK5',
    'ECT2L',
    'EGFR',
    'ELN',
    'ERBB2',
    'ERCC5',
    'EZH2',
    'EZR',
    'FAM46C',
    'FBXW7',
    'FCRL4',
    'FGFR3',
    'FLG',
    'FLI1',
    'FLT3',
    'FOXA1',
    'GLI1',
    'HGF',
    'HMCN1',
    'HNRNPA2B1',
    'HOOK3',
    'HOXA9',
    'IDH1',
    'IGF1R',
    'IRF4',
    'IRS1',
    'KCNJ5',
    'KDM5A',
    'KDR',
    'KEL',
    'KIT',
    'KLF4',
    'KMT2C',
    'KMT2D',
    'LDLR',
    'LPA',
    'LPP',
    'LRP2',
    'MALT1',
    'MAX',
    'MDM2',
    'MECOM',
    'MED12',
    'MLIP',
    'MLLT4',
    'MLLT6',
    'MSH6',
    'MTOR',
    'MUC16',
    'MUC17',
    'MYB',
    'MYH11',
    'MYH9',
    'NF1',
    'NIN',
    'NLRP2',
    'NOTCH2',
    'NTRK1',
    'OBSCN',
    'OLIG2',
    'OR4K5',
    'OR4Q3',
    'PAX3',
    'PAX5',
    'PCLO',
    'PCM1',
    'PDE4DIP',
    'PDGFRA',
    'PDGFRB',
    'PDPR',
    'PICALM',
    'PIK3C2G',
    'PIK3CA',
    'PIK3CB',
    'PIK3CG',
    'PIK3R1',
    'PIK3R2',
    'PIM1',
    'PKHD1',
    'PMS1',
    'PRKCD',
    'PRKCQ',
    'PTCH1',
    'PTEN',
    'PTPN11',
    'PTPRC',
    'RABEP1',
    'RANBP17',
    'RB1',
    'RPL10',
    'RPL22',
    'RPL5',
    'RYR2',
    'RYR3',
    'SDC4',
    'SETBP1',
    'SETD2',
    'SF3B1',
    'SHH',
    'SIRPB1',
    'SLC34A2',
    'SLC45A3',
    'SPTA1',
    'SREBF2',
    'SRSF2',
    'SS18',
    'STAG2',
    'TCF12',
    'TCHH',
    'TET2',
    'TMPRSS2',
    'TP53',
    'TPM3',
    'TRIM24',
    'TRRAP',
    'TTN',
    'UBR5',
    'USH2A',
    'USP6',
    'VOPP1',
    'VSTM2A',
    'WIF1',
    'ZNF521',
    'ZRSR2',
    'ABCA1',
    'ABI1',
    'ABL1',
    'AFF3',
    'AGAP2',
    'AKAP9',
    'AKT1S1',
    'AKT2',
    'AKT3',
    'ALK',
    'ARHGEF12',
    'ARPC1A',
    'ASPSCR1',
    'ATF1',
    'ATM',
    'ATRX',
    'BAGE',
    'BAGE2',
    'BAGE3',
    'BAGE4',
    'BAGE5',
    'BCL11B',
    'BCL3',
    'BCL7A',
    'BCR',
    'BIRC3',
    'BMPR1A',
    'BRCA2',
    'BRD3',
    'BRIP1',
    'CALR',
    'CANT1',
    'CBL',
    'CBLC',
    'CBX4',
    'CCDC6',
    'CCND1',
    'CCND2',
    'CCNE1',
    'CD274',
    'CD79B',
    'CDK2',
    'CDK4',
    'CDK6',
    'CDKN1A',
    'CDKN1B',
    'CDKN2A',
    'CDKN2B',
    'CEBPA',
    'CHI3L1',
    'CHIC2',
    'CLP1',
    'CLTC',
    'CLTCL1',
    'CNBP',
    'CNOT3',
    'CNTRL',
    'CREB3L2',
    'DDIT3',
    'DDX10',
    'DDX6',
    'DEPTOR',
    'DICER1',
    'DNM2',
    'DNMT3A',
    'E2F1',
    'EED',
    'EIF4A2',
    'ELAVL2',
    'ELF4',
    'ELK4',
    'ERBB3',
    'ERC1',
    'ETV1',
    'ETV5',
    'ETV6',
    'EXT1',
    'FANCC',
    'FANCD2',
    'FANCG',
    'FAS',
    'FCGR2B',
    'FGFR2',
    'FH',
    'FIP1L1',
    'FNBP1',
    'FOXL2',
    'FOXO3',
    'FOXO4',
    'FSTL3',
    'FZD1',
    'GATA1',
    'GATA2',
    'GATA3',
    'GIMAP8',
    'GMPS',
    'GNA11',
    'GNAQ',
    'GPC3',
    'GRB2',
    'GUSB',
    'H3F3A',
    'H3F3B',
    'HIP1',
    'HMGA2',
    'HNF1A',
    'HOXA11',
    'HOXA13',
    'HOXA4',
    'HOXA5',
    'HOXA7',
    'HOXC11',
    'HOXC13',
    'HRAS',
    'HSP90AA1',
    'HSP90AB1',
    'IFNA1',
    'IFNA14',
    'IFNA2',
    'IFNA4',
    'IFNA6',
    'IFNA7',
    'IFNA8',
    'IFNB1',
    'IFNW1',
    'IKZF1',
    'JAK1',
    'JAK2',
    'JAZF1',
    'KDM5C',
    'KIAA1549',
    'KLF6',
    'KMT2A',
    'KRAS',
    'LANCL2',
    'LMO2',
    'LRIG3',
    'LYL1',
    'MALAT1',
    'MAML2',
    'MAP2K2',
    'MAPK1',
    'MDM4',
    'MEN1',
    'MET',
    'MGAM',
    'MIR200C',
    'MLF1',
    'MLLT1',
    'MLLT10',
    'MLLT3',
    'MNX1',
    'MSI2',
    'MTAP',
    'MTCP1',
    'MUC1',
    'MUC20',
    'MYC',
    'MYCN',
    'NACA',
    'NCOA1',
    'NDRG1',
    'NFIB',
    'NFKB2',
    'NONO',
    'NOTCH1',
    'NR1H2',
    'NR2E1',
    'NR4A3',
    'NRAS',
    'NRCAM',
    'NT5C2',
    'NUMA1',
    'NUP214',
    'NUTM2A',
    'OR2Z1',
    'PAFAH1B2',
    'PAX7',
    'PBX1',
    'PCSK7',
    'PDCD1LG2',
    'PDGFA',
    'PDPK1',
    'PHF6',
    'PIK3C2B',
    'PKM',
    'PMEL',
    'PMS2',
    'POT1',
    'POTED',
    'POU2AF1',
    'PPARG',
    'PPP2R1A',
    'PRCC',
    'PRDM16',
    'PRH1',
    'PRKAR1A',
    'PRKCA',
    'PRKCG',
    'PRKCI',
    'PRKCZ',
    'PRR4',
    'PRRX1',
    'PSIP1',
    'RAC1',
    'RAD21',
    'RAF1',
    'RALGDS',
    'RASA3',
    'RECQL4',
    'RELA',
    'RHOH',
    'RNF213',
    'RNF43',
    'RPN1',
    'RPS6KA4',
    'RPS6KB1',
    'SBDS',
    'SDHC',
    'SDHD',
    'SEC61G',
    'SEPT14',
    'SEPT5',
    'SEPT9',
    'SERPINE1',
    'SET',
    'SF3B6',
    'SH3GL1',
    'SMARCA4',
    'SMO',
    'SOX2',
    'SPRY2',
    'SRGAP3',
    'SRSF3',
    'SSX2',
    'STIL',
    'STK11',
    'SUFU',
    'SYK',
    'TAL2',
    'TARP',
    'TBL1XR1',
    'TCF3',
    'TCL1A',
    'TCL6',
    'TFEB',
    'TFG',
    'TFPT',
    'TFRC',
    'TLX1',
    'TNFRSF14',
    'TPM4',
    'TSC1',
    'VHL',
    'VTI1A',
    'WHSC1',
    'WT1',
    'WWTR1',
    'XPA',
    'XPC',
    'ZBTB16',
    'ZEB1',
    'ZNF331',
    'ZNF384',
    'ZNF713',
    'ACKR3',
    'ACSL3',
    'ACSL6',
    'AFF4',
    'ALDH2',
    'ARAF',
    'ARID2',
    'ATIC',
    'ATP1A1',
    'AXIN1',
    'BCL10',
    'BCL9',
    'BTG1',
    'BUB1B',
    'CARS',
    'CASC5',
    'CASP8',
    'CBFA2T3',
    'CCNB1IP1',
    'CD74',
    'CDH11',
    'CDX2',
    'CHN1',
    'CREB1',
    'CREB3L1',
    'CRLF2',
    'DUSP22',
    'EBF1',
    'EP300',
    'ERCC2',
    'ERRFI1',
    'EXT2',
    'FANCA',
    'FANK1',
    'FEV',
    'FGFR1OP',
    'FN1',
    'FOXO1',
    'FRG1',
    'FUBP1',
    'FUS',
    'GAS7',
    'GDF2',
    'GOLGA5',
    'GOPC',
    'GPHN',
    'GYS1',
    'HEATR4',
    'HERPUD1',
    'HIF1A',
    'HIST1H3B',
    'HMGN2P46',
    'HOXD11',
    'HOXD13',
    'IL21R',
    'ITK',
    'JUN',
    'KDM6A',
    'KLK2',
    'KTN1',
    'LCP1',
    'LHFP',
    'LMO1',
    'MAFB',
    'MAP2K4',
    'MAPK3',
    'MGMT',
    'MKL1',
    'MLST8',
    'MYCL',
    'NCKIPSD',
    'NCOA4',
    'NFE2L2',
    'NFKB1',
    'NOTCH2NL',
    'NPM1',
    'NRXN3',
    'NSD1',
    'NTRK3',
    'NUP98',
    'NUTM1',
    'OR4K1',
    'OR4K2',
    'OR4M1',
    'OR52N5',
    'P2RY8',
    'PALB2',
    'PATZ1',
    'PER1',
    'PHF21A',
    'PHOX2B',
    'PIK3CD',
    'POTEB',
    'PRF1',
    'PRKCB',
    'PRKCH',
    'RAD51B',
    'RBM15',
    'ROS1',
    'RPS6KA5',
    'SEC22B',
    'SH2B3',
    'SPECC1',
    'SSX1',
    'SSX4',
    'TAF15',
    'TAL1',
    'TCF7L2',
    'TERT',
    'TET1',
    'TFE3',
    'TLX3',
    'TNFAIP3',
    'TRAF7',
    'TRIM33',
    'TRIP11',
    'TSC2',
    'TSHR',
    'TTL',
    'WAS',
    'WWOX',
    'YWHAE',
    'ZMYM2'
  ];

  // pointColor: DataField = DataFieldFactory.getUndefined();
  // pointShape: DataField = DataFieldFactory.getUndefined();
  // pointSize: DataField = DataFieldFactory.getUndefined();
  pointIntersect: DataField = DataFieldFactory.getUndefined();
  database: string;
  preprocessing: Preprocessing = Preprocessing.getUndefined();
  datasetName: string;

  public getLegendOptionValue(type: LegendOptionEnum): any {
    const option = this._legendOptions.find(option => option.type === type);
    return option ? option.defaultValue : undefined;
  }

  public isLegendOptionEnabled(type: LegendOptionEnum): boolean {
    const option = this._legendOptions.find(option => option.type === type);
    return option ? option.enabled : false;
  }

  // make a copy of the config object
  public static cloneFromAny(obj: any): GraphConfig {
    if (obj === null) {
      return null;
    }

    Khonsole.log('========== cloneFromAny GraphConfig', obj);
    let gc = new GraphConfig();
    for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            gc[key] = obj[key];
        }
    }
    // copy table details
    gc['table'] = {...obj['table']};

    return gc;
  }


  constructor() {
    this.database = GraphConfig.database;
    this.datasetName = GraphConfig.datasetName;
  }
}
