import * as data from 'app/action/data.action';
import * as spreadsheet from 'app/action/spreadsheet.action';
const ɵ0 = [];
const initialState = {
    dataTable: null,
    dataTables: [],
    data: ɵ0
};
export function reducer(state = initialState, action) {
    switch (action.type) {
        case data.DATA_LOADED:
            break;
        case spreadsheet.VIEW_DATA_TABLE:
            return Object.assign({}, state, { dataTable: action.payload });
        default:
            return state;
    }
}
export const getData = (state) => state.data;
export const getDataTable = (state) => state.dataTable;
export const getDataTables = (state) => state.dataTables;
export { ɵ0 };
